/* eslint-disable @typescript-eslint/no-explicit-any */
import {ethers} from "ethers";
import { useContext } from "react";
import { MobileView } from 'react-device-detect';
import { MobileMenu } from "../components/MobileMenu";
import { BottomMenu } from "../components/BottomMenu";

import { WalletContext } from "../hooks/WalletContext";
import { Footer } from "../components/static/Footer";

export function Token() {
  const { leftSideClose, connectMetaMask, account, chainId, setNotificationType, handleError } = useContext(WalletContext);
  const closeStyle = () => {
    if (leftSideClose) return "50px";
    else return "";
  };

  const faucetAbi = [{
    "inputs": [],
    "name": "facetMe",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }];

  const freeToken = async() => {
    try {
      setNotificationType("normal");
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(
      "0xa1b7023AA022836549ee0da87bcA588E1Cb6Fe95",
      faucetAbi,
      signer
    );
    const transaction = await contract.facetMe();
    await transaction.wait();
    setNotificationType("");
    } catch (error) {
      handleError(error);
    }
  }
  return (
    <div
      className="Layout_middle_container__rQzvK"
      style={{ "--left_margin": closeStyle() }}
    >
      <main className="Layout_content__3KYZT">
        <>
          <div className="ProfileBanner_container___VtRb">
            <div className="ProfileBanner_overlay__GkWn1"></div>
            <div className="ProfileBanner_content__W4XC9">
              <div className="ProfileBanner_left__1Mlkv">
                <div className="ProfileBanner_profile_img__Rsw_4">
                  <div
                    className="paper"
                    style={{
                      borderRadius: "50px",
                      display: "inline-block",
                      margin: "0px",
                      overflow: "hidden",
                      padding: "0px",
                      backgroundColor: "rgb(3, 61, 93)",
                      height: "100px",
                      width: "100px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0"
                      y="0"
                      height="100"
                      width="100"
                    >
                      <rect
                        x="0"
                        y="0"
                        rx="0"
                        ry="0"
                        height="100"
                        width="100"
                        transform="translate(-10.664895867991694 -13.009940361968908) rotate(336.4 50 50)"
                        fill="#fc1932"
                      ></rect>
                      <rect
                        x="0"
                        y="0"
                        rx="0"
                        ry="0"
                        height="100"
                        width="100"
                        transform="translate(37.44052829249325 8.80073201359511) rotate(177.3 50 50)"
                        fill="#f77101"
                      ></rect>
                      <rect
                        x="0"
                        y="0"
                        rx="0"
                        ry="0"
                        height="100"
                        width="100"
                        transform="translate(-75.92297726482232 30.029806873602208) rotate(274.5 50 50)"
                        fill="#243fe1"
                      ></rect>
                      <rect
                        x="0"
                        y="0"
                        rx="0"
                        ry="0"
                        height="100"
                        width="100"
                        transform="translate(22.023246323868772 -105.07049079255252) rotate(339.0 50 50)"
                        fill="#156cf2"
                      ></rect>
                    </svg>
                  </div>
                </div>
              </div>
              <div>
                <div className="ProfileBanner_username__6GO9A"></div>
                <div className="ProfileBanner_address__26cYx">
                  {account || (
                    <button
                      className="ConnectButton_container__7wq8q"
                      onClick={connectMetaMask}
                    >
                      Connect Wallet
                    </button>
                  )}
                </div>
                <div className="ProfileBanner_join_date__f6De9">
                  Mỗi 30 phút nhận miễn phí 10.000 TOKEN của UYTIN.IO tại mạng Arbitrum Sepolia để tham gia trải nghiệm miễn phí các trò chơi của chúng tôi.
                </div>
                {
                  account ? ( chainId === 421614 ?
                            <button
                              className="ConnectButton_container__7wq8q"
                              onClick={freeToken}
                              style={{'marginTop': "20px"}}
                            >
                              Nhận TOKEN miễn phí
                            </button>
                            : <p>Vui lòng chuyển qua mạng Arbitrum Sepolia để nhận miễn phí TOKEN</p>
                            )
                          : ""
                }
              </div>
            </div>
          </div>
        </>
        <MobileView>
          <MobileMenu/>
          <BottomMenu/>
        </MobileView>
        <Footer />
      </main>
    </div>
  );
}
