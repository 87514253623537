// eslint-disable-next-line
import { useEffect, useState, useCallback } from "react";
import { createContext } from "react";
import { ethers } from "ethers";

import {bankrollAddress, erc20Address} from '../components/static/contractAddress';
import { rpcProviderUrl } from "../components/static/rpcProviderUrl";
import erc20 from "../abi/erc20.json";
import bankroll from "../abi/bankroll.json";


export const WalletContext = createContext();

export const WalletContextProvider = (props) => {
  
  const erc20Abi = erc20.abi;
  const bankrollAbi = bankroll.abi;

  const [account, setAccount] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [erc20Balance, setErc20] = useState(0);
  const [bankrollPool, setBankrollPool] = useState(0);

  const [notificationType, setNotificationType] = useState(""); //{normal, error, success}
  const [notificationTitle, setNotificationTitle] = useState(
    "Transaction Information"
  );
  const [notificationMessage, setNotificationMessage] = useState(""); //Wallet rejected transaction //Wallet confirmation successful
  const clearMessage = () => setNotificationMessage("");

  const [leftSideClose, setLeftSideClose] = useState(false);
  const [round, setRound] = useState(0); // used round for waiting VRF. round > histories.length => waiting.
  const [isWaitingVrf, setWaitingVrf] = useState(false);
  const [isMobileMenu, setMobileMenu] = useState(false);
  
  const toggleMobileMenu = () => {
    if (isMobileMenu) setMobileMenu(false);
    else setMobileMenu(true);
  }

  const handleError = (error) => {
    console.log(error);
    if (error.error) if(error.error.code === -32603) {
      setNotificationType("error");
      setNotificationMessage("The transaction went out of gas.");
      // console.log("out of gas");
    }
    if (error.operation === 'getEnsAddress') {
      setNotificationType("error");
      setNotificationMessage("Invalid wallet address");
    }
    if (error.action === "estimateGas") {
      setNotificationType("error");
      setNotificationMessage("Kiểm tra kết nối mạng. Nhấn F5");
    }
    if (error.reason) {
      setNotificationType("error");
      setNotificationMessage(error.reason);
    }
    if (error.info) if (error.info.error) if (error.info.error.code === 4001) {
      setNotificationType("error");
      setNotificationMessage("User denied transaction signature.");
    }
  } 

  const connectMetaMask = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        clearMessage();
        updateWallet(accounts);
      } catch (error) {
        setNotificationType("error");
        setNotificationMessage(error.message);
      }
    } else {
      setNotificationType("error");
      setNotificationMessage("Need to install Metamask");
    }
  };

  const updateBalance = async () => {
    if (!rpcProviderUrl[chainId]) return;
    if (!erc20Address[chainId]) return;
    if (!account) return;

    const provider = new ethers.JsonRpcProvider(rpcProviderUrl[chainId][9]);
    const contract = new ethers.Contract(erc20Address[chainId], erc20Abi,provider);

    await contract.balanceOf(account)
      .then((balance) => {
        setErc20(Number(balance).toString());
      })
      .catch((error) => {
        setErc20(0);
      });
  }

  const updateBankroll = async () => {
    if (!rpcProviderUrl[chainId]) return; 
    if (!bankrollAddress[chainId]) return;
    if (!erc20Address[chainId]) return;
    
    const provider = new ethers.JsonRpcProvider(rpcProviderUrl[chainId][9]);
    const BankrollContract = new ethers.Contract(bankrollAddress[chainId], bankrollAbi,provider);

    await BankrollContract
      .poolLiquidityOf(erc20Address[chainId])
      .then((balance) => {
        setBankrollPool(Number(balance).toString());
      })
      .catch((error) => {
        setBankrollPool(0);
      });
  }

  // useCallback ensures that we don't uselessly re-create the _updateWallet function on every render
  const _updateWallet = useCallback(
    async (providedAccounts) => {
      const accounts =
        providedAccounts ||
        (await window.ethereum.request({ method: "eth_accounts" }));
      if (accounts.length === 0) {
        // If there are no accounts, then the user is disconnected
        return;
      }
      setAccount(accounts[0]);

      window.ethereum
        .request({
          method: "eth_chainId",
        })
        .then((_chainId) => {
          setChainId(Number(_chainId));
          if (Number(_chainId) === 42161 || Number(_chainId) === 137 || Number(_chainId) === 421614) {
            setNotificationType("");
            setNotificationMessage("");
          } else {
            setNotificationType("error");
            setNotificationMessage("Wrong network selected. Please choose Arbitrum One or Polygon Mainnet");
          }  
        })
        .catch((error) => {
          handleError(error);
        });

      if (erc20Address[chainId] && rpcProviderUrl[chainId]) {
        const provider = new ethers.JsonRpcProvider(rpcProviderUrl[chainId][9]);
        const contract = new ethers.Contract(
          erc20Address[chainId],
          erc20Abi,
          provider
        );

        await contract
          .balanceOf(accounts[0])
          .then((balance) => {
            setErc20(Number(balance).toString());
          })
          .catch((error) => {
            setErc20(0);
          });
      }
      
      if (bankrollAddress[chainId]) {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const bankrollReader = new ethers.Contract(
          bankrollAddress[chainId],
          bankrollAbi,
          provider
        );

        bankrollReader
        .poolLiquidityOf(erc20Address[chainId])
        .then((result) => setBankrollPool(Number(result).toString()))
        .catch((e) => handleError(e));
      }
      
    },
    [chainId]
  );

  const updateWalletAndAccounts = useCallback(
    () => _updateWallet(),
    [_updateWallet]
  );
  const updateWallet = useCallback(
    (accounts) => _updateWallet(accounts),
    [_updateWallet]
  );

  useEffect(() => {
    const init = async () => {
      if (window.ethereum) {
        updateWalletAndAccounts();
        window.ethereum.on("accountsChanged", updateWallet);
        window.ethereum.on("chainChanged", updateWalletAndAccounts);
      } else {
        setNotificationType("error");
        setNotificationMessage("Need to install Metamask");
      }
    };
    init();

    return () => {
      window.ethereum?.removeListener("accountsChanged", updateWallet);
      window.ethereum?.removeListener("chainChanged", updateWalletAndAccounts);
    };
  }, [updateWallet, updateWalletAndAccounts]);

  return (
    <WalletContext.Provider
      value={{
        account,
        chainId,
        erc20Balance,
        bankrollPool,
        isMobileMenu, 
        toggleMobileMenu,
        updateBankroll,
        updateBalance,
        connectMetaMask,
        notificationType,
        setNotificationType,
        notificationTitle,
        setNotificationTitle,
        notificationMessage,
        setNotificationMessage,
        leftSideClose,
        setLeftSideClose,
        isWaitingVrf, 
        setWaitingVrf,
        round, 
        setRound,
        handleError,
      }}
    >
      {props.children}
    </WalletContext.Provider>
  );
};
