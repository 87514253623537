import { useContext } from "react";
import { WalletContext } from "../hooks/WalletContext";

export function NotifierContext() {
  const {
    notificationType,
    setNotificationType,
    notificationMessage,
    setNotificationMessage,
    notificationTitle,
  } = useContext(WalletContext);

  const closeNotification = () => {
    setNotificationType("");
  };

  const showNotification = () => {
    if (notificationType == "normal")
      return (
        <div className="BaseNotification_container__vE4Ss BaseNotification_normal__q8xm_">
          <div className="BaseNotification_header__6Zzvn">
            <div className="BaseNotification_title__FlgiT">
              {notificationTitle}
            </div>
          </div>
          <div className="BaseNotification_content__1fgME">
            <div className="BaseNotification_notification_icon_container__mjI1N">
              <div className="BaseNotification_icon_header_container__qMTRQ"></div>
              <div className="BaseNotification_notification_icon__H6kSb">
                <div className="WaitingDice_dice__tginN">
                  <div className="WaitingDice_dice_dot__36Hat WaitingDice_one__Oh1As"></div>
                  <div className="WaitingDice_dice_dot__36Hat WaitingDice_two__uw95t"></div>
                  <div className="WaitingDice_dice_dot__36Hat WaitingDice_three__I61W_"></div>
                  <div className="WaitingDice_dice_dot__36Hat WaitingDice_four__r0wNx"></div>
                  <div className="WaitingDice_dice_dot__36Hat WaitingDice_five__Mm1ki"></div>
                  <div className="WaitingDice_dice_dot__36Hat WaitingDice_six__ny10W"></div>
                </div>
              </div>
            </div>
            <div className="BaseNotification_notification_contents__IqNTX">
              <div className="AllowanceRequestNotification_container__1wgJt">
                <div className="AllowanceRequestNotification_row__jQZ1Q">
                  <div className="StatusIndicator_pending__3_5PO"></div>
                  <div>
                    Awaiting wallet confirmation
                    <span>
                      <span className="WaitingDots_one__8dcRN">.</span>
                      <span className="WaitingDots_two__rzcRN">.</span>
                      <span className="WaitingDots_three__LVJO8">.</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    if (notificationType == "success")
      return (
        <div className="BaseNotification_container__vE4Ss BaseNotification_success__I9Pvx ">
          <div className="BaseNotification_header__6Zzvn">
            <div className="BaseNotification_title__FlgiT">
              {notificationTitle}
            </div>
            <div
              className="BaseNotification_notification_close__NG_Kq"
              onClick={closeNotification}
            >
              ✖
            </div>
          </div>
          <div className="BaseNotification_content__1fgME">
            <div className="BaseNotification_notification_icon_container__mjI1N">
              <div className="BaseNotification_icon_header_container__qMTRQ"></div>
              <div className="BaseNotification_notification_icon__H6kSb">
                <svg
                  height="1.5em"
                  version="1.1"
                  viewBox="0 0 82.49 94.049"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g transform="translate(-38.648 -171.52)" strokeWidth="0">
                    <path d="m39.541 195.28c-0.68375 0.01739-0.89348 0.86045-0.89348 2.3983v41.111c0 2.5768 0.8369 4.7233 2.3513 5.5976l37.864 21.183v-45.69c0-1.072-0.33246-2.1491-1.6123-2.9445l-36.722-21.317c-0.37094-0.21416-0.67396-0.32505-0.91984-0.33744-0.023051-0.00117-0.04564-0.0016-0.067696-0.00104zm5.7051 8.2899a5.9495 3.8001 66.556 0 1 5.131 4.8664 5.9495 3.8001 66.556 0 1 0.43925 2.1957l17.221 10.534a1.0001 1.0001 0 0 1 0.094051 1.6407l-19.702 15.41 15.067 8.2016a5.9495 3.8001 66.556 0 1 2.1192-2.2815 5.9495 3.8001 66.556 0 1 5.6606 4.8095 5.9495 3.8001 66.556 0 1-1.8624 6.4053 5.9495 3.8001 66.556 0 1-5.6612-4.81 5.9495 3.8001 66.556 0 1-0.41186-1.9306l-17.205-9.3669a1.0001 1.0001 0 0 1-0.13643-1.6655l19.762-15.459-15.202-9.3007a5.9495 3.8001 66.556 0 1-2.0459 2.0221 5.9495 3.8001 66.556 0 1-5.6606-4.8095 5.9495 3.8001 66.556 0 1 1.8624-6.4053 5.9495 3.8001 66.556 0 1 0.5302-0.05632z"></path>
                    <path d="m79.896 171.52c-0.58055-5.9e-4 -1.1623 0.1921-1.833 0.57929l-37.18 21.466 36.792 21.242c1.6009 0.92423 2.8831 0.89709 4.4369 0l36.792-21.242-37.18-21.466c-0.66659-0.38483-1.2462-0.5787-1.8268-0.57929zm-21.246 18.948a5.8218 3.09 0 0 1 5.8219 3.0897 5.8218 3.09 0 0 1-5.8219 3.0902 5.8218 3.09 0 0 1-5.8219-3.0902 5.8218 3.09 0 0 1 5.8219-3.0897zm21.988 0a5.8218 3.09 0 0 1 5.8219 3.0897 5.8218 3.09 0 0 1-5.8219 3.0902 5.8218 3.09 0 0 1-5.8219-3.0902 5.8218 3.09 0 0 1 5.8219-3.0897zm21.272 0a5.8218 3.09 0 0 1 5.8214 3.0897 5.8218 3.09 0 0 1-5.8214 3.0902 5.8218 3.09 0 0 1-5.8219-3.0902 5.8218 3.09 0 0 1 5.8219-3.0897z"></path>
                    <path d="m120.22 195.28c-0.25371 1.4e-4 -0.57119 0.11093-0.96532 0.33848l-36.722 21.317c-1.2798 0.79541-1.6123 1.8726-1.6123 2.9445v45.69l37.864-21.183c1.5144-0.87433 2.3513-3.0208 2.3513-5.5976v-41.111c0-1.5544-0.21427-2.3987-0.9157-2.3983zm-5.8306 8.2904a3.8001 5.9495 23.444 0 1 0.61185 0.05581 3.8001 5.9495 23.444 0 1 1.8624 6.4053 3.8001 5.9495 23.444 0 1-5.6606 4.8095 3.8001 5.9495 23.444 0 1-0.90382-0.41341l-16.213 20.671 15.503 1.8433a3.8001 5.9495 23.444 0 1 4.8819-4.2385 3.8001 5.9495 23.444 0 1 0.5302 0.05633 3.8001 5.9495 23.444 0 1 1.8624 6.4048 3.8001 5.9495 23.444 0 1-5.6606 4.81 3.8001 5.9495 23.444 0 1-2.1875-5.0865l-15.812-1.8795v7.0962a3.8001 5.9495 23.444 0 1 0.36845-0.02377 3.8001 5.9495 23.444 0 1 0.52968 0.05633 3.8001 5.9495 23.444 0 1 1.8629 6.4048 3.8001 5.9495 23.444 0 1-5.6612 4.81 3.8001 5.9495 23.444 0 1-1.8624-6.4053 3.8001 5.9495 23.444 0 1 2.7626-3.977v-9.0444a1.0001 1.0001 0 0 1 0-0.00982v-8.2543a3.8001 5.9495 23.444 0 1-0.9002-0.03462 3.8001 5.9495 23.444 0 1-1.8624-6.4048 3.8001 5.9495 23.444 0 1 5.0488-4.8658 3.8001 5.9495 23.444 0 1 0.61185 0.05581 3.8001 5.9495 23.444 0 1 1.8629 6.4048 3.8001 5.9495 23.444 0 1-2.7611 3.9827v6.1867l15.917-20.295a3.8001 5.9495 23.444 0 1 0.21962-4.254 3.8001 5.9495 23.444 0 1 5.0493-4.8658z"></path>
                  </g>
                </svg>
              </div>
            </div>
            <div className="BaseNotification_notification_contents__IqNTX">
              <div className="AllowanceRequestNotification_container__1wgJt">
                <div className="AllowanceRequestNotification_row__jQZ1Q">
                  <div className="StatusIndicator_success__uOAGw"></div>
                  <div>Wallet confirmation successful.</div>
                </div>
                <div className="AllowanceRequestNotification_row__jQZ1Q">
                  <div className="StatusIndicator_success__uOAGw"></div>
                  <div>Transaction accepted.</div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="BaseNotification_progress_bar__YPyEs"
            style={{ animationDuration: "5000ms" }}
          ></div>
        </div>
      );
    if (notificationType == "error")
      return (
        <div className="BaseNotification_container__vE4Ss BaseNotification_error__aVuGk">
          <div className="BaseNotification_header__6Zzvn">
            <div className="BaseNotification_title__FlgiT">
              {notificationTitle}
            </div>
            <div
              className="BaseNotification_notification_close__NG_Kq"
              onClick={closeNotification}
            >
              ✖
            </div>
          </div>
          <div className="BaseNotification_content__1fgME">
            <div className="BaseNotification_notification_icon_container__mjI1N">
              <div className="BaseNotification_icon_header_container__qMTRQ"></div>
              <div className="BaseNotification_notification_icon__H6kSb">
                <div className="AllowanceRequestNotification_exclamation_point__Yi1RD">
                  !
                </div>
              </div>
            </div>
            <div className="BaseNotification_notification_contents__IqNTX">
              <div className="AllowanceRequestNotification_container__1wgJt">
                <div className="AllowanceRequestNotification_row__jQZ1Q">
                  <div className="StatusIndicator_failed__WcMn6"></div>
                  <div>{notificationMessage}.</div>
                </div>
                <div>
                  <div className="AllowanceRequestNotification_error_container__rB_Kw">
                    <div className="AllowanceRequestNotification_error_title__of90i">
                      An error occurred!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="BaseNotification_progress_bar__YPyEs"
            style={{ animationDuration: "5000ms" }}
          ></div>
        </div>
      );
    if (notificationMessage == "") return;
  };

  return <div className="NotifierContext_container">{showNotification()}</div>;
}
