import React from "react";
import { useEffect } from "react";
import { Routes, Route, Outlet } from "react-router-dom";

import ReactGA from 'react-ga4';

import { BrowserView } from 'react-device-detect';

import { WalletContextProvider } from "./hooks/WalletContext";

import { Header } from "./components/Header";
import { NotifierContext } from "./components/NotifierContext";
import { LeftSideBar } from "./components/LeftSideBar";

//import { Temp } from "./pages/Temp"; 
import { Home } from "./pages/Home";
import { Dice } from "./pages/Dice";
import { Token } from "./pages/Token";
import { BauCua } from "./pages/BauCua";
import { TaiXiu } from "./pages/TaiXiu";
import { XocDia } from "./pages/XocDia";
import { Profile } from "./pages/Profile";
import { CoinFlip } from "./pages/CoinFlip";
import { RockPaperScissors } from "./pages/RockPaperScissors";

ReactGA.initialize('G-J1E02XYE96');

function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <WalletContextProvider>
        <Layout/>
      <Routes>
        <Route index element={<Home />} />
        {/* <Route path="/temp" element={<Temp />} /> */}
        <Route path="/xucxac" element={<Dice />} />
        <Route path="/baucua" element={<BauCua />} />
        <Route path="/taixiu" element={<TaiXiu />} />
        <Route path="/xocdia" element={<XocDia />} />
        <Route path="/tungxu" element={<CoinFlip />} />
        <Route path="/oantuti" element={<RockPaperScissors />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/tangtoken" element={<Token />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </WalletContextProvider>
  );
}

function Layout() {
  return (
    <>
      <Header />
      <NotifierContext />
      <BrowserView>
        <LeftSideBar />
      </BrowserView>
      <Outlet />
    </>
  );
}
export default App;

