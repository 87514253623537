
import { useState } from 'react';
import {GameExternalLinks} from './GameExternalLinks';
import { diceIcon } from './svg';
export function DiceDescription() {
    const [isOpen, setIsOpen] = useState("");

    const toogleOpen = () => {
        if (isOpen === "") setIsOpen("GameDescription_open__Fwx_r");
        else setIsOpen("");
    }

    return (
        <div className="GameDescription_container__kgwkr">
        <div className="GameDescription_header__emfaR">
            <div className="GameDescription_game_info__AA1oL">
                <div className="GameDescription_game_title__4Rcft">
                    <div className="GameDescription_game_icon__Lzy_J">
                    {diceIcon}
                    </div>
                    <h1 className="GameDescription_game_name__5fLMz">Xúc Xắc</h1>
                    <div className="GameDescription_house_edge_header__Vjdkf">Phí nhà cái: 1%</div>
                </div>
            </div>
            <div className="GameDescription_buttons__O_XMu">
                <button className={isOpen} onClick={toogleOpen}>
                    <svg height="1em" viewBox="6 6 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 10L12 15L17 10" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                </button>
            </div>
        </div>
        <div className={"GameDescription_content__piQVl " + isOpen}>
            <div className="GameDescription_description__6W8KV">Xúc xắc là trò chơi sòng bạc trên tiền điện tử phổ biến nhất, với nguồn gốc xuất phát từ năm 2012 khi việc sử dụng Bitcoin cho việc cá cược bắt đầu xuất hiện.</div>
            <div className="GameDescription_tutorial__pU2cG">Đây là một trò chơi may rủi đơn giản với cơ cấu cược dễ dàng điều chỉnh. Trượt thanh sang trái và phần thưởng nhân cho việc thắng cược của bạn tăng lên, đồng thời hy sinh cơ hội chiến thắng. Trượt thanh sang phải, và điều ngược lại xảy ra. Cơ hội chiến thắng của bạn tăng lên, trong khi phần thưởng nhân cho một chiến thắng giảm đi. Phần thưởng nhân và cơ hội chiến thắng cũng có thể được điều chỉnh thông qua hộp, với người dùng có thể nhập vào tỉ lệ nhân và phần trăm cơ hội chiến thắng mong muốn của họ. Khái niệm này đơn giản nhưng đáng tin cậy và có thể được điều chỉnh cho chiến lược cá cược của mỗi người dùng; rõ ràng tại sao trò chơi này vẫn giữ được sự phổ biến suốt gần một thập kỷ.</div>
            <GameExternalLinks/>
        </div>
    </div>
  );
}