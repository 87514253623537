import { useContext } from "react";
import { WalletContext } from "../../hooks/WalletContext";
import { 
  coinFlipAddress, 
  diceAddress, 
  rockPaperScissorsAddress,
  taiXiuAddress,
  bauCuaAddress,
} from './contractAddress';
import {uytinIcon} from './svg';

export function GameExternalLinks() {
  const { chainId } = useContext(WalletContext);
  const linkContract = () => {
    let address = "";
    if (window.location.pathname === '/tungxu') address = coinFlipAddress[chainId];
    else if (window.location.pathname === '/xucxac') address = diceAddress[chainId];
    else if (window.location.pathname === '/oantuti') address = rockPaperScissorsAddress[chainId];
    else if (window.location.pathname === '/taixiu') address = taiXiuAddress[chainId];
    else if (window.location.pathname === '/baucua') address = bauCuaAddress[chainId];

    if (chainId === 42161) return "https://arbiscan.io/address/" + address;
    else if (chainId === 137) return "https://polygonscan.com/address/" + address; 
    else if (chainId === 421614) return "https://sepolia.arbiscan.io/address/" + address;
    else return "";
  }
  return (
    <div className="GameDescription_external_links__v9NMI">
      <a href="https://huongdan.uytin.io/cach-he-thong-hoat-dong/hop-dong-thong-minh-cua-uytin.io" target="_blank" rel="noreferrer">
        Kết quả từ
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 184.65 56.71"
          fill="#375bd2"
        >
          <path d="M63.39,28a10,10,0,0,1,9.67-10.4h.41c5.1,0,8.11,3,9,6.51L79.3,25.17a5.58,5.58,0,0,0-5.83-4.38c-3.3,0-6.61,2.41-6.61,7.27s3.23,7.18,6.65,7.18a6,6,0,0,0,5.95-4.41l3.11,1a9,9,0,0,1-9.1,6.63,9.91,9.91,0,0,1-10.09-9.72C63.37,28.5,63.38,28.25,63.39,28Z"></path>
          <path d="M88.74,38H85.46V17.54h3.28v8a4.85,4.85,0,0,1,3.9-1.72c3.39,0,5,2.43,5,5.46V38H94.48V29.86a2.67,2.67,0,0,0-2.24-3,2.89,2.89,0,0,0-.59,0,2.86,2.86,0,0,0-2.86,2.86,2.09,2.09,0,0,0,0,.25Z"></path>
          <path d="M105,30.12l3.52-.55c.8-.1,1-.51,1-1,0-1.16-.78-2.09-2.6-2.09a2.62,2.62,0,0,0-2.82,2.37V29l-3-.67c.25-2.38,2.41-4.5,5.79-4.5,4.24,0,5.85,2.4,5.85,5.2v6.83a13.62,13.62,0,0,0,.17,2.21h-3a8,8,0,0,1-.13-1.76,4.78,4.78,0,0,1-4.24,2.17,4.32,4.32,0,0,1-4.66-3.94c0-.07,0-.14,0-.2C100.83,31.81,102.64,30.45,105,30.12Zm4.54,2.21V31.7l-3.59.54a1.89,1.89,0,0,0-1.83,1.87A1.82,1.82,0,0,0,106,35.9h.15a3.12,3.12,0,0,0,3.43-2.8A3.22,3.22,0,0,0,109.53,32.33Z"></path>
          <path d="M118.32,17.26a2.14,2.14,0,0,1,2.17,2.12v0a2.14,2.14,0,1,1-2.17-2.14Zm-1.6,20.8V24.24H120V38Z"></path>
          <path d="M127.47,38H124.2V24.24h3.19v1.83a4.55,4.55,0,0,1,4.07-2.23c3.36,0,5,2.43,5,5.46V38h-3.27V29.86c0-1.69-.77-3.06-2.83-3.06a2.9,2.9,0,0,0-2.88,2.92c0,.11,0,.22,0,.33Z"></path>
          <path d="M140.42,38V17.54h3.28V38Z"></path>
          <path d="M149.55,17.26a2.13,2.13,0,1,1-2.12,2.12A2.14,2.14,0,0,1,149.55,17.26Zm-1.61,20.8V24.24h3.25V38Z"></path>
          <path d="M158.71,38h-3.28V24.24h3.19v1.83a4.56,4.56,0,0,1,4.07-2.23c3.36,0,5,2.43,5,5.46V38h-3.28V29.86a2.67,2.67,0,0,0-2.24-3,3,3,0,0,0-.6,0,2.9,2.9,0,0,0-2.87,2.9,2,2,0,0,0,0,.35Z"></path>
          <path d="M178.88,30.05l5.77,8h-4l-4-5.67-1.69,1.78V38h-3.25V17.54h3.25V29.8l5.28-5.56h4.35Z"></path>
          <path d="M24.56,12l14.18,8.14V36.5L24.6,44.69,10.43,36.57V20.22L24.56,12m0-12-5.2,3L5.2,11.22l-5.2,3V42.59l5.2,3,14.18,8.14,5.2,3,5.2-3,14.13-8.2,5.2-3V14.12l-5.2-3L29.74,3l-5.2-3Z"></path>
        </svg>
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={linkContract()}
      >
        nhà cung cấp UYTIN.IO{uytinIcon}
      </a>
    </div>
  );
}
