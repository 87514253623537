/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from "react";
import { WalletContext } from "../hooks/WalletContext";
import { GameOutcomesDisplay } from "./GameOutcomesDisplay";
import {Bankroll} from './Bankroll';
import { buaImage, keoImage, giayImage } from "./static/svg";

export function RockPaperScissorsDisplay({ action, setGame, histories, randomActions, gameState }) {

  const { isWaitingVrf, round } = useContext(WalletContext);

  // actions selected: 0->Rock, 1-> Paper, 2->Scissors
  //const [result, setResult] = useState(0);

  const classCloser = () => {
    if (gameState === 1) return "OutcomeDisplay_closer__3_2yO";
    else if (round > histories.length || isWaitingVrf) return "OutcomeDisplay_closer__3_2yO";
    else return "";
  }

  const classResult = () => {
    if (gameState === 1) return "OutcomeDisplay_pending__NGLhs";
    else return "";
  }

  const playChoise = (value) => {
    setGame(value);
  };

  const classRock = () => {
    if (action === 0) return "HandSelector_selected__fHW5s";
    else return "";
  };
  const classPaper = () => {
    if (action === 1) return "HandSelector_selected__fHW5s";
    else return "";
  };
  const classScissors = () => {
    if (action === 2) return "HandSelector_selected__fHW5s";
    else return "";
  };

  const actionToImage = (action) => {
    if (Number(action) === 0) return buaImage;
    if (Number(action) === 1) return giayImage;
    return keoImage;
  };

  return (
    <div
      className="RockPaperScissors_display__SJXyw"
      style={{ "--color": "hsl(240, 30%, 25%)" }}
    >
      <div className="Display_container__Qd9xD">
        <Bankroll/>
        <GameOutcomesDisplay histories={histories} />
        <div className="Display_above__MNlFS">
          <div className="OutcomeDisplay_container__U3Z_x">
            <div className={"OutcomeDisplay_player_container__T74CJ " + classCloser()}>
              <div className={"OutcomeDisplay_player_hand__N3H_q " + classResult()}>
                {actionToImage(action)}
              </div>
            </div>
            <div className="OutcomeDisplay_versus__4oL8m">vs</div>
            <div className={"OutcomeDisplay_opponent_container__oh40e " + classCloser()}>
              <div className={"OutcomeDisplay_opponent_hand__VDbLR " + classResult()}>
                {actionToImage(randomActions)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="SpecificInputs_container__OLKbJ">
        <div className="HandSelector_container__jpQkK ">
          <button
            className={classRock()}
            disabled={action === 0}
            onClick={() => playChoise(0)}
          >
            Búa
          </button>

          <button
            className={classPaper()}
            disabled={action === 1}
            onClick={() => playChoise(1)}
          >
            Giấy
          </button>

          <button
            className={classScissors()}
            disabled={action === 2}
            onClick={() => playChoise(2)}
          >
            Kéo
          </button>
        </div>
      </div>
    </div>
  );
}
