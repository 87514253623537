/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import axios from "axios";
import cheerio from "cheerio";

import { MobileView } from "react-device-detect";
import { MobileMenu } from "../components/MobileMenu";
import { BottomMenu } from "../components/BottomMenu";

import { WalletContext } from "../hooks/WalletContext";

import { InputContainer } from "../components/InputContainer";
import { CoinFlipDisplay } from "../components/CoinFlipDisplay";
import { CoinFlipDescription } from "../components/static/CoinFlipDescription";
import { GameFeed } from "../components/GameFeed";
import { MoreGame } from "../components/MoreGame";
import { Footer } from "../components/static/Footer";
import { listenToEventWithExponentialBackoff, timestamptoTime, time } from "../hooks/function";

import {
  coinFlipAddress,
  erc20Address,
} from "../components/static/contractAddress";
import { rpcProviderUrl } from "../components/static/rpcProviderUrl";
import abi from "../abi/coinflip.json";
import erc20 from "../abi/erc20.json";

export function CoinFlip() {
  const {
    account,
    chainId,
    leftSideClose,
    isWaitingVrf,
    setWaitingVrf,
    round,
    setRound,
    setNotificationType,
    handleError,
  } = useContext(WalletContext);
  const multiplier = 1.98;
  const decimals = 10 ** 6;
  const [allowance, setAllowance] = useState(0);
  const [isHeads, setIsHeads] = useState(true);
  const [coinOutcomes, setLastOutCome] = useState(2);
  const [histories, setHistories] = useState([]);
  const [gameFeedData, setGameFeedData] = useState([]);
  const fetchedDataFlag = React.useRef(false); 

  const coinflipAbi = abi.abi;
  const erc20Abi = erc20.abi;

  const closeStyle = () => {
    if (leftSideClose) return "50px";
    else return "";
  };

  const updateAllowance = async () => {
    const provider = chainId
      ? new ethers.JsonRpcProvider(rpcProviderUrl[chainId][6])
      : null;

    const erc20Contract = erc20Address[chainId]
      ? new ethers.Contract(erc20Address[chainId], erc20Abi, provider)
      : null;

    if (erc20Contract && coinFlipAddress[chainId]) {
      const allowanceAmount = await erc20Contract.allowance(
        account,
        coinFlipAddress[chainId]
      );
      setAllowance(Number(allowanceAmount));
    }
  };
  const approveErc20 = async (amount) => {
    setNotificationType("normal");
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const erc20Contract = erc20Address[chainId]
      ? new ethers.Contract(erc20Address[chainId], erc20Abi, signer)
      : null;

    if (erc20Contract) {
      const tx = erc20Contract
        .approve(
          coinFlipAddress[chainId],
          Math.floor(Number(amount * decimals))
        )
        .then(() => {
          updateAllowance();
          setNotificationType("");
        })
        .catch((e) => {
          handleError(e);
        });
    }
  };
  useEffect(() => {
    updateAllowance();
    window.scrollTo(0, 0);
    setRound(0);

    /*if (!fetchedDataFlag.current && gameFeedData.length === 0) {
      fetchedDataFlag.current = true;

      // Update Arbitrum First.
      const fetchData = async () => {
        const ArbitrumOneUrl =
        "https://arbitrumone.uytin.io/address/" + coinFlipAddress[42161] + "#tokentxns";
        try {
          const response = await axios.get(ArbitrumOneUrl);
          const htmlContent = response.data;

          const tbodyRegex =
            /<tbody class="align-middle text-nowrap">([\s\S]*?)<\/tbody>/g;
          const tbodyMatch = tbodyRegex.exec(htmlContent);

          if (tbodyMatch && tbodyMatch[1]) {
            const tbodyContent = tbodyMatch[1];
            const hrefRegex =
              /<a class='hash-tag text-truncate myFnExpandBox_searchVal'[^>]*href='([^']+)'/g;
            let matches;
            const urls = [];
            while (
              (matches = hrefRegex.exec(tbodyContent)) !== null &&
              urls.length < 5
            ) {
              urls.push("https://arbitrumone.uytin.io" + matches[1]);
            }
            for (let i = 0; i < urls.length; i++) {
              axios
                .get(urls[i])
                .then((response) => {
                  const htmlString = response.data;
                  const $ = cheerio.load(htmlString);

                  const spanElement = $("#showUtcLocalDate");
                  const dataTimestamp = spanElement.attr("data-timestamp");

                  const regexA =
                    /<a[^>]*data-highlight-target="([^"]*)"[^>]*>/g;
                  const matchesA = [];
                  let matchA;
                  while ((matchA = regexA.exec(htmlString)) !== null) {
                    matchesA.push(matchA[1]);
                  }

                  const regexSpan = /<span class='me-1'[^>]*>(.*?)<\/span>/g;
                  const matchesSpan = [];
                  let matchSpan;
                  while ((matchSpan = regexSpan.exec(htmlString)) !== null) {
                    matchesSpan.push(matchSpan[1]);
                  }
                  // console.log(matchesSpan[matchesSpan.length-1]);

                  if (matchesA.length > 1 && matchesSpan.length > 0) {
                    // console.log(gameFeedData.length);
                    if (matchesA[0] !== matchesA[1]) {
                      const newFeed = {
                        chainId: 42161,
                        time: timestamptoTime(dataTimestamp),
                        game: "Tung Xu",
                        slash: "/tungxu",
                        player: matchesA[0],
                        wager: Number(matchesSpan[matchesSpan.length-1] * 1000000),
                        multiplier: "+-",
                        profit: "-",
                      };
                      setGameFeedData((gameFeedData) => [newFeed, ...gameFeedData]);
                    } // else console.log("DEPOSIT BANKROLL, DON'T ADD TO FEED");
                  }
                })
                .catch((error) => {
                  console.error("There was an error fetching the data!", error);
                });
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    } */

    const providerFix = chainId
      ? new ethers.JsonRpcProvider(rpcProviderUrl[chainId][6])
      : null;
    const contractFix =
      coinFlipAddress[chainId] && providerFix
        ? new ethers.Contract(
            coinFlipAddress[chainId],
            coinflipAbi,
            providerFix
          )
        : null;

    // STOP error loop playing -- unknown why have this error
    const intervalId = contractFix
      ? setInterval(() => {
          // Fix metamask send two or more transaction in one time use clicked.
          if (round < histories.length) setRound(histories.length);
          if (isWaitingVrf)
            contractFix.CoinFlip_GetState(account).then((CoinFlipGame) => {
              if (Number(CoinFlipGame.wager) === 0) setWaitingVrf(false);
            });
        }, 1000)
      : null;

    // get game status if on Waiting Vrf.
    if (contractFix) {
      contractFix.CoinFlip_GetState(account).then((CoinFlipGame) => {
        if (Number(CoinFlipGame.wager) !== 0) setWaitingVrf(true);
      });
    }

    const eventSepoliaListener = (
      playerAddress,
      wager,
      payout,
      tokenAddress,
      coinOutcomes,
      payouts,
      numGames
    ) => {
      if (account)
        if (account.toUpperCase() === playerAddress.toUpperCase()) {
          for (let i = 0; i < Number(numGames); i++) {
            setHistories((histories) => [
              ...histories,
              Number(payouts[i]) / Number(wager),
            ]);
          }
          setLastOutCome(coinOutcomes[Number(numGames) - 1]);
          setWaitingVrf(false);
          setNotificationType("");
          const roundNotBets = Number(payouts.length) - Number(numGames);
          setRound((round) => round - roundNotBets);
        }

      const wagers = Number(wager) * Number(payouts.length);
      const multiplierTemp = Number(payout) / wagers;
      const profit = Number(payout) - wagers;

      const newFeed = {
        chainId: 42161,
        time: time(),
        game: "Tung Xu",
        slash: "/tungxu",
        player: playerAddress,
        wager: wagers,
        multiplier: multiplierTemp.toFixed(2),
        profit: profit,
      };

      setGameFeedData((gameFeedData) => [newFeed, ...gameFeedData]);
    };
    const providerSepolia = new ethers.JsonRpcProvider(
      rpcProviderUrl[421614][6]
    );
    const coinFlipSepolia = coinFlipAddress[421614]
      ? new ethers.Contract(
          coinFlipAddress[421614],
          coinflipAbi,
          providerSepolia
        )
      : null;

    const initSepolia = async () => {
      try {
        await listenToEventWithExponentialBackoff(
          coinFlipSepolia,
          "CoinFlip_Outcome_Event",
          5,
          16000,
          eventSepoliaListener
        );
      } catch (e) {
        handleError(e);
      }
    };
    if (coinFlipSepolia) initSepolia();

    const eventPolygonListener = (
      playerAddress,
      wager,
      payout,
      tokenAddress,
      coinOutcomes,
      payouts,
      numGames
    ) => {
      if (account)
        if (account.toUpperCase() === playerAddress.toUpperCase()) {
          for (let i = 0; i < Number(numGames); i++) {
            setHistories((histories) => [
              ...histories,
              Number(payouts[i]) / Number(wager),
            ]);
          }
          setLastOutCome(coinOutcomes[Number(numGames) - 1]);
          setWaitingVrf(false);
          setNotificationType("");
          const roundNotBets = Number(payouts.length) - Number(numGames);
          setRound((round) => round - roundNotBets);
        }

      const wagers = Number(wager) * Number(payouts.length);
      const multiplierTemp = Number(payout) / wagers;
      const profit = Number(payout) - wagers;

      const newFeed = {
        chainId: 137,
        time: time(),
        game: "Tung Xu",
        slash: "/tungxu",
        player: playerAddress,
        wager: wagers,
        multiplier: multiplierTemp.toFixed(2),
        profit: profit,
      };
      setGameFeedData((gameFeedData) => [newFeed, ...gameFeedData]);
    };
    const providerPolygon = new ethers.JsonRpcProvider(rpcProviderUrl[137][6]);
    const coinFlipPolygon = coinFlipAddress[137]
      ? new ethers.Contract(coinFlipAddress[137], coinflipAbi, providerPolygon)
      : null;

    const initPolygon = async () => {
      try {
        await listenToEventWithExponentialBackoff(
          coinFlipPolygon,
          "CoinFlip_Outcome_Event",
          5,
          16000,
          eventPolygonListener
        );
      } catch (e) {
        handleError(e);
      }
    };
    if (coinFlipPolygon) initPolygon();

    const eventArbitrumListener = (
      playerAddress,
      wager,
      payout,
      tokenAddress,
      coinOutcomes,
      payouts,
      numGames
    ) => {
      if (account)
        if (account.toUpperCase() === playerAddress.toUpperCase()) {
          for (let i = 0; i < Number(numGames); i++) {
            setHistories((histories) => [
              ...histories,
              Number(payouts[i]) / Number(wager),
            ]);
          }
          setLastOutCome(coinOutcomes[Number(numGames) - 1]);
          setWaitingVrf(false);
          setNotificationType("");
          const roundNotBets = Number(payouts.length) - Number(numGames);
          setRound((round) => round - roundNotBets);
        }

      const wagers = Number(wager) * Number(payouts.length);
      const multiplierTemp = Number(payout) / wagers;
      const profit = Number(payout) - wagers;

      const newFeed = {
        chainId: 42161,
        time: time(),
        game: "Tung Xu",
        slash: "/tungxu",
        player: playerAddress,
        wager: wagers,
        multiplier: multiplierTemp.toFixed(2),
        profit: profit,
      };
      setGameFeedData((gameFeedData) => [newFeed, ...gameFeedData]);
    };
    const providerArbitrum = new ethers.JsonRpcProvider(
      rpcProviderUrl[42161][6]
    );
    const coinFlipArbitrum = coinFlipAddress[42161]
      ? new ethers.Contract(
          coinFlipAddress[42161],
          coinflipAbi,
          providerArbitrum
        )
      : null;

    const initArbitrum = async () => {
      try {
        await listenToEventWithExponentialBackoff(
          coinFlipArbitrum,
          "CoinFlip_Outcome_Event",
          5,
          16000,
          eventArbitrumListener
        );
      } catch (e) {
        handleError(e);
      }
    };
    if (coinFlipArbitrum) initArbitrum();

    return () => {
      if (contractFix) clearInterval(intervalId);
      if (coinFlipSepolia !== null) {
        coinFlipSepolia.off("CoinFlip_Outcome_Event", eventSepoliaListener);
      }
      if (coinFlipPolygon !== null) {
        coinFlipPolygon.off("CoinFlip_Outcome_Event", eventPolygonListener);
      }
      if (coinFlipArbitrum !== null) {
        coinFlipArbitrum.off("CoinFlip_Outcome_Event", eventArbitrumListener);
      }
    };
  }, [chainId, account]);

  const sendTransaction = async (
    wagerToContract,
    bets,
    _stopLoss,
    _stopGain
  ) => {
    if (!coinFlipAddress[chainId]) return;
    setNotificationType("normal");
    setRound((round) => round + Math.floor(bets));
    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const coinFlipContract =
        coinFlipAddress[chainId] && signer
          ? new ethers.Contract(coinFlipAddress[chainId], coinflipAbi, signer)
          : null;

      const transaction = await coinFlipContract
        .CoinFlip_Play(
          Math.floor(wagerToContract),
          erc20Address[chainId],
          isHeads,
          Math.floor(bets),
          _stopLoss.toString(),
          _stopGain.toString()
        )
        .then(() => {
          setNotificationType("");
          setWaitingVrf(true);
          updateAllowance();
        });
    } catch (error) {
      setWaitingVrf(false);
      setRound((round) => round - Math.floor(bets));
      handleError(error);
    }
  };

  return (
    <div
      className="Layout_middle_container__rQzvK"
      style={{ "--left_margin": closeStyle() }}
    >
      <main className="Layout_content__3KYZT">
        <div className="GameWrapper_game_container___djZh">
          <div className="CoinFlip_container__uL1r2">
            <InputContainer
              multiplier={multiplier}
              sendTransaction={sendTransaction}
              allowance={allowance}
              approveErc20={approveErc20}
            />
            <CoinFlipDisplay
              isHeads={isHeads}
              setIsHeads={setIsHeads}
              histories={histories}
              coinOutcomes={coinOutcomes}
            />
          </div>
          <CoinFlipDescription />
        </div>
        <GameFeed gameFeedData={gameFeedData} />
        <MoreGame />
        <MobileView>
          <MobileMenu />
          <BottomMenu />
        </MobileView>
        <Footer />
      </main>
    </div>
  );
}

/*
const axios = require('axios');
const cheerio = require('cheerio');

const url = 'https://polygon.uytin.io/address/0x658d831192bf5008e89ab57b373d8c8c7e6f480e';

axios.get(url)
  .then(response => {
    const html = response.data;
    const $ = cheerio.load(html);
    const links = $('a.hash-tag.text-truncate.myFnExpandBox_searchVal');

    let hrefArray = [];
    let count = 0;

    links.each((index, element) => {
      if (count < 5) {
        const href = $(element).attr('href');
        hrefArray.push("https://polygon.uytin.io" + href);
        count++;
      }
    });

    for (let i = 0; i < hrefArray.length; i++) {
      console.log(hrefArray[i]);
      axios.get(hrefArray[i])
  .then(response => {
    const html = response.data;
    const $ = cheerio.load(html);

    // Tìm thẻ <div> với class "d-flex flex-wrap align-items-center"
    const targetDiv = $('div.d-flex.flex-wrap.align-items-center');

    // Lấy giá trị từ thẻ <span class="me-1">5</span>
    const spanValue = targetDiv.find('span.me-1').eq(1).text().trim();
    // console.log('Wager:', spanValue);

    // Lấy thông tin data-highlight-target từ thẻ <a>
    const aTag = $('a[data-highlight-target="0x278e2ddc31ee9fe61e73b10ee5ce5fbf5a401049"]');
    const dataHighlightTarget = aTag.attr('data-highlight-target');
    // console.log('User Wallet:', dataHighlightTarget);

    // Lấy giá trị data-timestamp từ thẻ <span id="showUtcLocalDate">
    const timestamp = $('#showUtcLocalDate').attr('data-timestamp');
    // console.log('Data Timestamp:', timestamp);
    if (timestamp && dataHighlightTarget && spanValue) {
      console.log('Wager:', spanValue);
      console.log('User Wallet:', dataHighlightTarget);
      console.log('Data Timestamp:', timestamp);
    } else console.log('NOT FOUND:');
  })
  .catch(error => {
    console.error(`Could not retrieve data: ${error}`);
  });

    }
  })
  .catch(error => {
    console.error(`Could not retrieve data: ${error}`);
  });






*/