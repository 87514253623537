//     Arbitrum (chainId === 42161)    Polygon (chainId === 137)  Arbitrum Sepolia (chainId === 421614(0x66eee))  

export const erc20Address = {
  42161: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
    137: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
  421614: "0x295ba60A813ba6bfFbF14e68f5286F838B931F93"
};

export const bankrollAddress = {
  42161: "0xB933a02559808dCFCEbb03B32f07a0E487247708",
    137: "0xB933a02559808dCFCEbb03B32f07a0E487247708",
  421614: "0x39d512Ad8d4ba29aA69c34b8A8296A16a2d08ba8",
};

export const coinFlipAddress = {
  42161: "0xBd33838289FAE7f6a13F8e98e78B8d780De9F005",
    137: "0xBd33838289FAE7f6a13F8e98e78B8d780De9F005",
  421614: "0x1d49C4cb6F307516AE8041c49F53898a1DEa6123",
};

export const diceAddress = {
  42161: "0x79960bF3E40AD6B78382fc76cf1ebda3F8e7e1fe",
    137: "0x79960bF3E40AD6B78382fc76cf1ebda3F8e7e1fe",
  421614: "0x71c4bAEe5127808625c73129469f04765DA180bD",
};

export const rockPaperScissorsAddress = {
  42161: "0x19F35Cbc4116d30D38D4D5933EAB77b5fAEA8F17",
    137: "0x19F35Cbc4116d30D38D4D5933EAB77b5fAEA8F17",
  421614: "0x33CF6bCEc8FFfBA67022c0451c0504d67d8DD90f",
};

export const bauCuaAddress = {
  42161: "0x6CEa42A92415f8735deA3651c67aa7c7eC0CF50C",
    137: "0x6CEa42A92415f8735deA3651c67aa7c7eC0CF50C",
  421614: "0x13c046c9F08C34CDfD3E6B1685f926E6dC99c1C9",
};

export const taiXiuAddress = {
  42161: "0xa80aC0ec76d82c6a8B556e42f799E447beE3b9bc",
    137: "0xa80aC0ec76d82c6a8B556e42f799E447beE3b9bc",
  421614: "0x28E80DF7b8F0111648154CC2c6C59311f2F540dF"
};

export const xocDiaAddress = {
  42161: "0x60ad159477AC10A03750A383c92C0f77eF3302FD",
    137: "0x60ad159477AC10A03750A383c92C0f77eF3302FD",
  421614: "0xcA1E29ab0B141f0A5554368C398D54a4b763E356",
};