/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useContext } from "react";
import { NavLink } from "react-router-dom";

import { WalletContext } from "../hooks/WalletContext";
import {
  diceIcon,
  coinflipIcon,
  scissorsIcon,
  baucuaIcon,
  profileIcon,
  freeTokenIcon,
  telegramIcon,
  facebookIcon,
  whitepaperIcon,
  ipfsIcon,
  crabIcon,
  ukflagIcon,
  vnflagIcon,
} from "./static/svg";

export function MobileMenu() {
  const { isMobileMenu, toggleMobileMenu } = useContext(WalletContext);
  if (isMobileMenu)
  return (
    <div className="BottomMenu_menu_container__x099m">
      <div className="Menu_container__NMCD5">
        <div className="Navigation_container__I3X7z">
          <div className="Navigation_games_container__l7WaV">
            <NavLink to="/taixiu" className="Navigation_nav_link__gmu5X" onClick={toggleMobileMenu}>
              <div>{diceIcon}</div>
              <div>Tài Xỉu</div>
            </NavLink>
            <NavLink to="/tungxu" className="Navigation_nav_link__gmu5X" onClick={toggleMobileMenu}>
              <div>{coinflipIcon}</div>
              <div>Tung Xu</div>
            </NavLink>
            <NavLink to="/oantuti" className="Navigation_nav_link__gmu5X" onClick={toggleMobileMenu}>
              <div>{scissorsIcon}</div>
              <div>Oẳn tù tì</div>
            </NavLink>
            <NavLink to="/baucua" className="Navigation_nav_link__gmu5X" onClick={toggleMobileMenu}>
              <div>{crabIcon}</div>
              <div>Bầu Cua</div>
            </NavLink>
            <NavLink to="/xucxac" className="Navigation_nav_link__gmu5X" onClick={toggleMobileMenu}>
              <div>{diceIcon}</div>
              <div>Xúc xắc</div>
            </NavLink>
            <NavLink to="/xocdia" className="Navigation_nav_link__gmu5X" onClick={toggleMobileMenu}>
              <div>{coinflipIcon}</div>
              <div>Xóc Đĩa</div>
            </NavLink>
          </div>
          <hr />
          <div className="Navigation_others_container__g57az">
            <NavLink to="/profile" className="Navigation_nav_link__gmu5X" onClick={toggleMobileMenu}>
              <div>{profileIcon}</div>
              <div>Trang Cá Nhân</div>
            </NavLink>
            <NavLink to="/tangtoken" className="Navigation_nav_link__gmu5X" onClick={toggleMobileMenu}>
              <div>{freeTokenIcon}</div>
              <div>Token Miễn Phí</div>
            </NavLink>
          </div>
          <hr />
          <div className="Navigation_externals_container__rbrpF">
            <a
              className="Navigation_nav_link__gmu5X"
              href="https://t.me/uytinchamio"
              target="_blank"
              rel="noreferrer"
            >
              {telegramIcon}
              <div>Telegram</div>
              <svg opacity="0" viewBox="0 0 18 18">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2 2V16H16V9H18V16C18 17.1 17.1 18 16 18H2C0.89 18 0 17.1 0 16V2C0 0.9 0.89 0 2 0H9V2H2Z"
                ></path>
                <path d="M11 0V2H14.59L4.76 11.83L6.17 13.24L16 3.41V7H18V0H11Z"></path>
              </svg>
            </a>
            {/* <a
              className="Navigation_nav_link__gmu5X"
              href="https://www.facebook.com/groups/957095815798447"
              target="_blank"
              rel="noreferrer"
            >
              {facebookIcon}
              <div>Facebook</div>
              <svg opacity="0" viewBox="0 0 18 18">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2 2V16H16V9H18V16C18 17.1 17.1 18 16 18H2C0.89 18 0 17.1 0 16V2C0 0.9 0.89 0 2 0H9V2H2Z"
                ></path>
                <path d="M11 0V2H14.59L4.76 11.83L6.17 13.24L16 3.41V7H18V0H11Z"></path>
              </svg>
            </a> */}
            <a
              className="Navigation_nav_link__gmu5X"
              href="https://huongdan.uytin.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {whitepaperIcon}
              <div>Sách Trắng</div>
              <svg opacity="0" viewBox="0 0 18 18">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2 2V16H16V9H18V16C18 17.1 17.1 18 16 18H2C0.89 18 0 17.1 0 16V2C0 0.9 0.89 0 2 0H9V2H2Z"
                ></path>
                <path d="M11 0V2H14.59L4.76 11.83L6.17 13.24L16 3.41V7H18V0H11Z"></path>
              </svg>
            </a>
            <a
              className="Navigation_nav_link__gmu5X"
              href="https://t.me/huongdanuytinchamio"
              target="_blank"
              rel="noreferrer"
            >
              {telegramIcon}
              <div>Video Hướng Dẫn</div>
              <svg opacity="0" viewBox="0 0 18 18">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2 2V16H16V9H18V16C18 17.1 17.1 18 16 18H2C0.89 18 0 17.1 0 16V2C0 0.9 0.89 0 2 0H9V2H2Z"
                ></path>
                <path d="M11 0V2H14.59L4.76 11.83L6.17 13.24L16 3.41V7H18V0H11Z"></path>
              </svg>
            </a>
            {/* <a
              className="Navigation_nav_link__gmu5X"
              href="https://uytin.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {ipfsIcon}
              <div>IPFS Mirror</div>
            </a> */}
          </div>
        </div>
        <div className="Menu_bottom__IvPGz">
          {/* <div className="VolumeController_container__k1Lup">
            <button className="VolumeController_volume_button__wgqw0">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                <path d="M412.6 182c-10.28-8.334-25.41-6.867-33.75 3.402c-8.406 10.24-6.906 25.35 3.375 33.74C393.5 228.4 400 241.8 400 255.1c0 14.17-6.5 27.59-17.81 36.83c-10.28 8.396-11.78 23.5-3.375 33.74c4.719 5.806 11.62 8.802 18.56 8.802c5.344 0 10.75-1.779 15.19-5.399C435.1 311.5 448 284.6 448 255.1S435.1 200.4 412.6 182zM473.1 108.2c-10.22-8.334-25.34-6.898-33.78 3.34c-8.406 10.24-6.906 25.35 3.344 33.74C476.6 172.1 496 213.3 496 255.1s-19.44 82.1-53.31 110.7c-10.25 8.396-11.75 23.5-3.344 33.74c4.75 5.775 11.62 8.771 18.56 8.771c5.375 0 10.75-1.779 15.22-5.431C518.2 366.9 544 313 544 255.1S518.2 145 473.1 108.2zM534.4 33.4c-10.22-8.334-25.34-6.867-33.78 3.34c-8.406 10.24-6.906 25.35 3.344 33.74C559.9 116.3 592 183.9 592 255.1s-32.09 139.7-88.06 185.5c-10.25 8.396-11.75 23.5-3.344 33.74C505.3 481 512.2 484 519.2 484c5.375 0 10.75-1.779 15.22-5.431C601.5 423.6 640 342.5 640 255.1S601.5 88.34 534.4 33.4zM301.2 34.98c-11.5-5.181-25.01-3.076-34.43 5.29L131.8 160.1H48c-26.51 0-48 21.48-48 47.96v95.92c0 26.48 21.49 47.96 48 47.96h83.84l134.9 119.8C272.7 477 280.3 479.8 288 479.8c4.438 0 8.959-.9314 13.16-2.835C312.7 471.8 320 460.4 320 447.9V64.12C320 51.55 312.7 40.13 301.2 34.98z"></path>
              </svg>
            </button>
          </div> */}
          <div className="LanguageSelector_container__qSTkm">
            <div className="LanguageSelector_language_link__IooX_">
              <div className="LanguageSelector_flag_container__1x4Wp">
                {vnflagIcon}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ); else return "";
}
