/* eslint-disable @typescript-eslint/no-explicit-any */
// import {ethers} from "ethers";
import { useContext } from "react";
import { formatUnits } from "ethers";
import { WalletContext } from "../hooks/WalletContext";

// import { usdtIcon } from "./static/svg";

 // 
export function Bankroll() {
  const { bankrollPool } = useContext(WalletContext);

  const FormatBalance = (value) => {
    return (
      <>
        {parseFloat(formatUnits(value, 6)).toLocaleString("en", {
          minimumFractionDigits: 4,
        })}
        <span className="WalletBalance_symbol__ugzq4"> USDT</span>
        {/* {usdtIcon} */}
      </>
    );
  };

  return (
    <div style={{"display": "flex", "alignItems":"center"}}>
      <img
        alt="Bankroll"
        src={"/static/media/bank.svg"}
        decoding="async"
        data-nimg="fill"
        loading="lazy"
        style={{
          position: "relative",
          height: "50px",
          width: "50px"
        }}
      />
      <p style={{ display: "inline-block" }}>{FormatBalance(bankrollPool)}</p>
    </div>
  );
}
