/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect } from "react";
import { MobileView } from 'react-device-detect';
import { MobileMenu } from "../components/MobileMenu";
import { BottomMenu } from "../components/BottomMenu";

import { WalletContext } from "../hooks/WalletContext";
import { ProfileStatistics } from "../components/ProfileStatistics";
import { ProfilePendingRequests } from "../components/ProfilePendingRequests";
import { Footer } from "../components/static/Footer";

export function Profile() {
  const { leftSideClose } = useContext(WalletContext);
  const closeStyle = () => {
    if (leftSideClose) return "50px";
    else return "";
  };
  useEffect(()=>{window.scrollTo(0, 0);},[]);
  return (<>
    <div
      className="Layout_middle_container__rQzvK"
      style={{ "--left_margin": closeStyle() }}
    >
      <main className="Layout_content__3KYZT">
        <ProfileStatistics />
        <ProfilePendingRequests />
      </main>
      <Footer />
    </div>
    <MobileView>
      <MobileMenu/>
      <BottomMenu/>
    </MobileView>
  </>
  );
}
