/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState, useEffect, useRef } from "react";
import { ethers, formatUnits } from "ethers";

import { WalletContext } from "../hooks/WalletContext";
import { usdtIcon } from "./static/svg";

import { TaiXiuHistories } from "./TaiXiuHistories";
import { Bankroll } from "./Bankroll";

import { rpcProviderUrl } from "./static/rpcProviderUrl";
import taixiu from "../abi/taixiu.json";
import { taiXiuAddress } from "./static/contractAddress";
import { listenToEventWithExponentialBackoff } from "../hooks/function";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const FormatBalance = (_balance) => {
  return parseFloat(formatUnits(_balance, 6)).toLocaleString("en", {
    minimumFractionDigits: 0,
  });
};

export function TaiXiuDisplay({ setIsBig, updateBalance }) {
  const { handleError, account, chainId } =
    useContext(WalletContext);

  const taiXiuAbi = taixiu.abi;

  const [select, SetSelect] = useState(0);

  const [results, setResultArray] = useState([]);

  const [lastId, setId] = useState(0);
  const [countdown, setCountdown] = useState(0);
  const [tai, setTai] = useState(0);
  const [xiu, setXiu] = useState(0);
  const [bankrollAccepted, setBankrollAccepted] = useState(0);
  const [status, setStatus] = useState(0);
  const [taiPlayers, setTaiPlayers] = useState(0);
  const [xiuPlayers, setXiuPlayers] = useState(0);
  const [taiTotal, setTaiTotal] = useState(0);
  const [xiuTotal, setXiuTotal] = useState(0);
  const [result, setResult] = useState([0, 0, 0]);
  const [preChainId, setPreChainId] = useState(0);

  const playChoise = (value) => {
    SetSelect(value);
    setIsBig(value);
  };

  useEffect(() => {
    if (!account) return;
    if (!rpcProviderUrl[chainId]) return;
    if (!taiXiuAddress[chainId]) return;
    if (preChainId != chainId) {
      setPreChainId(chainId);
      setResultArray([]);
    }

    const provider = new ethers.JsonRpcProvider(rpcProviderUrl[chainId][10]);
    const taiXiuContract = new ethers.Contract(taiXiuAddress[chainId], taiXiuAbi, provider);

    const provider1 = new ethers.JsonRpcProvider(rpcProviderUrl[chainId][11]);
    const taiXiuContract1 = new ethers.Contract(taiXiuAddress[chainId], taiXiuAbi, provider1);

    const provider2 = new ethers.JsonRpcProvider(rpcProviderUrl[chainId][12]);
    const taiXiuContract2 = new ethers.Contract(taiXiuAddress[chainId], taiXiuAbi, provider2);

    const provider3 = new ethers.JsonRpcProvider(rpcProviderUrl[chainId][13]);
    const taiXiuContract3 = new ethers.Contract(taiXiuAddress[chainId], taiXiuAbi, provider3);

    const provider4 = new ethers.JsonRpcProvider(rpcProviderUrl[chainId][14]);
    const taiXiuContract4 = new ethers.Contract(taiXiuAddress[chainId], taiXiuAbi, provider4);

    const initData = taiXiuContract.getTurn().then((result) => {
      setId(Number(result[1]));
      setStatus(Number(result[0][2]));
      setTai(Number(result[0][3]));
      setXiu(Number(result[0][4]));
      setBankrollAccepted(Number(result[0][5]));
      if (Number(result[2]) - Number(result[0][1]) >= 60) setCountdown(0);
      else setCountdown(60 - (Number(result[2]) - Number(result[0][1])));
      setTaiPlayers(Number(result[3]));
      setXiuPlayers(Number(result[4]));

      if (account) {
        taiXiuContract.TaiXiu_GetState(account).then((results) => {
          setTaiTotal(Number(results[0]));
          setXiuTotal(Number(results[1]));
        });
      }

      if (results.length === 0)
        if (lastId > 10) {
          for (let i = lastId - 10; i < lastId; i++) {
            taiXiuContract.getResult(i).then((_result) => {
              // console.log(i, _result);
              setResultArray((results) => [
                ...results,
                {
                  xucxac1: _result[0],
                  xucxac2: _result[1],
                  xucxac3: _result[2],
                },
              ]);
            });
          }
        } else {
          for (let i = 1; i < lastId; i++) {
            taiXiuContract.getResult(i).then((_result) => {
              // console.log(i, _result);
              setResultArray((results) => [...results, _result]);
            });
          }
        }
    });
    const TaiXiu_Round_Listener = (
      _id,
      _tai,
      _xiu,
      _countdown,
      _taiPlayers,
      _xiuPlayers
    ) => {
      setId(Number(_id));
      setTai(Number(_tai));
      setXiu(Number(_xiu));
      setCountdown((countdown) => Number(_countdown));
      setStatus(1);
      setTaiPlayers(Number(_taiPlayers));
      setXiuPlayers(Number(_xiuPlayers));
      // console.log(lastId, tai, xiu, countdown);
    };
    const TaiXiu_Take_Balance_Listener = (_tai, _xiu, _bankrollAccepted) => {
      setTai(Number(_tai));
      setXiu(Number(_xiu));
      setBankrollAccepted(Number(_bankrollAccepted));
      if (status === 1 || status === 0) {
        setStatus(2);
      }
      if (account)
        taiXiuContract.TaiXiu_GetState(account).then((result) => {
          setTaiTotal(Number(result[0]));
          setXiuTotal(Number(result[1]));
        });
    };
    const TaiXiu_Play_Listener = (
      _playerAddress,
      _wager,
      _tokenAddress,
      _isBig
    ) => {
      // console.log(_playerAddress, _wager, _tokenAddress, _isBig);
      if (account)
        if (account.toUpperCase() === _playerAddress.toUpperCase()) {
          if (_isBig) setTaiTotal(Number(_wager));
          else setXiuTotal(Number(_wager));
        }
    };
    const TaiXiu_VRF_Request_Listener = (result1, result2, result3) => {
      setStatus(3);
      setResult([Number(result1), Number(result2), Number(result3)]);
      setResultArray((results) => [
        ...results,
        {
          xucxac1: Number(result1),
          xucxac2: Number(result2),
          xucxac3: Number(result3),
        },
      ]);
    };
    const RoundisOpenNow_Listener = () => {
      setStatus(0);
      setTai(0);
      setXiu(0);
      setBankrollAccepted(0);
      setTaiTotal(0);
      setXiuTotal(0);
      setId((lastId) => lastId + 1);
      updateBalance();
    };

    const startListening = async () => {
      try {
        await listenToEventWithExponentialBackoff(
          taiXiuContract1,
          "TaiXiu_Round_INFO_Event",
          5,
          16000,
          TaiXiu_Round_Listener
        );
        await listenToEventWithExponentialBackoff(
          taiXiuContract2,
          "TaiXiu_Take_Balance_EVENT",
          5,
          16000,
          TaiXiu_Take_Balance_Listener
        );
        await listenToEventWithExponentialBackoff(
          taiXiuContract,
          "TaiXiu_VRF_Request_EVENT",
          5,
          16000,
          TaiXiu_VRF_Request_Listener
        );
        await listenToEventWithExponentialBackoff(
          taiXiuContract3,
          "TaiXiu_Play_EVENT",
          5,
          16000,
          TaiXiu_Play_Listener
        );
        await listenToEventWithExponentialBackoff(
          taiXiuContract4,
          "RoundisOpenNow_EVENT",
          5,
          16000,
          RoundisOpenNow_Listener
        );
      } catch (e) {
        handleError(e);
      }
    };

    startListening();

    return () => {
      taiXiuContract1.off("TaiXiu_Round_INFO_Event", TaiXiu_Round_Listener);
      taiXiuContract2.off(
        "TaiXiu_Take_Balance_EVENT",
        TaiXiu_Take_Balance_Listener
      );
      taiXiuContract.off(
        "TaiXiu_VRF_Request_EVENT",
        TaiXiu_VRF_Request_Listener
      );
      taiXiuContract3.off("TaiXiu_Play_EVENT", TaiXiu_Play_Listener);
      taiXiuContract4.off("RoundisOpenNow_EVENT", RoundisOpenNow_Listener);
    };
  }, [chainId, lastId, account]);

  useInterval(() => {
    if (countdown > 0) setCountdown((countdown) => countdown - 1);
  }, 1000);

  const numberToSvg = (number) => {
    if (number === 6) return "/static/media/6.svg";
    else if (number === 1) return "/static/media/1.svg";
    else if (number === 2) return "/static/media/2.svg";
    else if (number === 3) return "/static/media/3.svg";
    else if (number === 4) return "/static/media/4.svg";
    else if (number === 5) return "/static/media/5.svg";
  };

  const displayBowl = () => {
    // bowl_baucua // baucua_result
    if (countdown !== 0)
      return (
        <>
          <div className="bowl_baucua"></div>
          <div className="TaiXiu_Countdown">ĐÓNG CƯỢC SAU {countdown} GIÂY</div>
        </>
      );
    else if (status === 1)
      return (
        <>
          <div className="baucua_result"></div>
          <div className="TaiXiu_TakeBalance">NHÀ CÁI NHẬN CƯỢC</div>
        </>
      );
    else if (status === 2)
      return (
        <>
          <div className="baucua_result"></div>
          <div className="TaiXiu_OpenBet">ĐANG CHỜ MỞ BÁT</div>
        </>
      );
    else if (status === 3)
      return (
        <div className="baucua_result">
          <div className="TaiXiu_PayWinner">ĐANG TRẢ THƯỞNG</div>
          <img className="baucua_dice1" src={numberToSvg(result[0])} />
          <img className="baucua_dice2" src={numberToSvg(result[1])} />
          <img className="baucua_dice3" src={numberToSvg(result[2])} />
        </div>
      );
    else if (status === 0)
      return (
        <div className="baucua_result ">
          <div className="TaiXiu_OpenBet">PHIÊN CƯỢC ĐANG MỞ</div>
        </div>
      );
  };
  const taiBet = () => {
    if (taiTotal) return FormatBalance(taiTotal) + " USDT";
    else return "";
  };
  const xiuBet = () => {
    if (xiuTotal) return FormatBalance(xiuTotal) + " USDT";
    else return "";
  };
  const caiNhanTai = () => {
    if (tai >= xiu) return FormatBalance(bankrollAccepted);
    else return 0;
  };
  const caiNhanXiu = () => {
    if (xiu >= tai) return FormatBalance(bankrollAccepted);
    else return 0;
  };
  const classTai = () => {
    if (select === 1) return "HandSelector_selected__fHW5s";
    else return "";
  };
  const classXiu = () => {
    if (select === 0) return "HandSelector_selected__fHW5s";
    else return "";
  };

  return (
    <>
      <div className="Display_container__qSXl5">
        <Bankroll />
        <TaiXiuHistories results={results} />
        <div className="Display_above__oCBz5">
          <div className="Coin_shadow__it9xJ"></div>
          <div
            style={{
              "--period": "750s",
              "--diameter": "240px",
              "--thickness": "20px",
            }}
          >
            <div className={"Coin_coin__bKUgo"}>{displayBowl()}</div>
          </div>
        </div>
        <div className="TaiXiu_Id">Lượt: #{lastId}</div>
        <table className="taixiu_history center">
          <tbody>
            <tr>
              <th>CỬA CƯỢC</th>
              <th>NGƯỜI CHƠI</th>
              <th>TỔNG CƯỢC</th>
              <th>NHÀ CÁI CÂN</th>
            </tr>
            <tr>
              <td>TÀI</td>
              <td>{taiPlayers}</td>
              <td>
                {FormatBalance(tai)}
                {usdtIcon}
              </td>
              <td>
                {caiNhanTai()}
                {usdtIcon}
              </td>
            </tr>
            <tr>
              <td>XỈU</td>
              <td>{xiuPlayers}</td>
              <td>
                {FormatBalance(xiu)}
                {usdtIcon}
              </td>
              <td>
                {caiNhanXiu()}
                {usdtIcon}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="SpecificInputs_container__OLKbJ">
          <div className="HandSelector_container__jpQkK ">
            <button
              className={classTai()}
              disabled={select === 1}
              onClick={() => playChoise(1)}
            >
              TÀI {taiBet()}
            </button>
            <button
              className={classXiu()}
              disabled={select === 0}
              onClick={() => playChoise(0)}
            >
              XỈU {xiuBet()}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
