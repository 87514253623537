/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState, useEffect } from "react";
import { WalletContext } from "../hooks/WalletContext";

import { GameOutcomesDisplay } from "./GameOutcomesDisplay";
import { BauCuaOutcomesDisplay } from "./BauCuaOutcomesDisplay";
import { Bankroll } from "./Bankroll";

export function BauCuaDisplay({ action, histories, randomActions, setAction, bauCuaOutCome }) {
  const { round, isWaitingVrf } = useContext(WalletContext);

  const [select, SetSelect] = useState(6);

  const playChoise = (value) => {
    SetSelect(value);
    setAction(value);
  };
  const isSelected = (value) => {
    if (select === value) return "selected"
    else return "";
  }

  useEffect(() => {
    // console.log(round, histories.length);
  }, [round, histories]);

  const numberToSvg = (number) => {
    if (number === 0) return "/static/media/bau.svg";
    else if (number === 1) return "/static/media/cua.svg";
    else if (number === 2) return "/static/media/ca.svg";
    else if (number === 3) return "/static/media/ga.svg";
    else if (number === 4) return "/static/media/tom.svg";
    else if (number === 5) return "/static/media/nai.svg";
  }

  const displayBowl = () => {
    if (round === 0) {
      return (<div className="bowl_baucua_start">
      <img
        alt="UYTIN.IO Bau Cua Blockchain USDT"
        style={{
          transform: "rotate(120deg)",
          left: "50px",
          top: "90px",
          height: "40px",
          position: "absolute",
        }}
        src="/static/media/bau.svg"
      />
      <img
        alt="UYTIN.IO Bau Cua Blockchain USDT"
        style={{
          transform: "rotate(67deg)",
          left: "50px",
          top: "195px",
          height: "40px",
          position: "absolute",
        }}
        src="/static/media/cua.svg"
      />
      <img
        alt="UYTIN.IO Bau Cua Blockchain USDT"
        style={{
          transform: "rotate(-90deg)",
          left: "130px",
          top: "35px",
          height: "40px",
          position: "absolute",
        }}
        src="/static/media/ca.svg"
      />
      <img
        alt="UYTIN.IO Bau Cua Blockchain USDT"
        style={{
          transform: "rotate(0deg)",
          left: "137px",
          top: "235px",
          height: "40px",
          position: "absolute",
        }}
        src="/static/media/ga.svg"
      />
      <img
        alt="UYTIN.IO Bau Cua Blockchain USDT"
        style={{
          transform: "rotate(247deg)",
          left: "220px",
          top: "90px",
          height: "40px",
          position: "absolute",
        }}
        src="/static/media/tom.svg"
      />
      <img
        alt="UYTIN.IO Bau Cua Blockchain USDT"
        style={{
          transform: "rotate(297deg)",
          left: "220px",
          top: "195px",
          height: "40px",
          position: "absolute",
        }}
        src="/static/media/nai.svg"
      />
      </div>)
    } else if (round > histories.length || isWaitingVrf) {
      return (<div className="bowl_baucua">
      <img
        alt="UYTIN.IO Bau Cua Blockchain USDT"
        style={{
          transform: "rotate(120deg)",
          left: "50px",
          top: "90px",
          height: "40px",
          position: "absolute",
        }}
        src="/static/media/bau.svg"
      />
      <img
        alt="UYTIN.IO Bau Cua Blockchain USDT"
        style={{
          transform: "rotate(67deg)",
          left: "50px",
          top: "195px",
          height: "40px",
          position: "absolute",
        }}
        src="/static/media/cua.svg"
      />
      <img
        alt="UYTIN.IO Bau Cua Blockchain USDT"
        style={{
          transform: "rotate(-90deg)",
          left: "130px",
          top: "35px",
          height: "40px",
          position: "absolute",
        }}
        src="/static/media/ca.svg"
      />
      <img
        alt="UYTIN.IO Bau Cua Blockchain USDT"
        style={{
          transform: "rotate(0deg)",
          left: "137px",
          top: "235px",
          height: "40px",
          position: "absolute",
        }}
        src="/static/media/ga.svg"
      />
      <img
        alt="UYTIN.IO Bau Cua Blockchain USDT"
        style={{
          transform: "rotate(247deg)",
          left: "220px",
          top: "90px",
          height: "40px",
          position: "absolute",
        }}
        src="/static/media/tom.svg"
      />
      <img
        alt="UYTIN.IO Bau Cua Blockchain USDT"
        style={{
          transform: "rotate(297deg)",
          left: "220px",
          top: "195px",
          height: "40px",
          position: "absolute",
        }}
        src="/static/media/nai.svg"
      />
      </div>)
    } else {
      return (<div className="baucua_result">
      <img alt="UYTIN.IO Bau Cua Blockchain USDT" className="baucua_dice1" src={numberToSvg(randomActions[0])} />
      <img alt="UYTIN.IO Bau Cua Blockchain USDT" className="baucua_dice2" src={numberToSvg(randomActions[1])} />
      <img alt="UYTIN.IO Bau Cua Blockchain USDT" className="baucua_dice3" src={numberToSvg(randomActions[2])} />
    </div>)
    }
  }

  return (
    <>
      <div className="Display_container__qSXl5">
        <Bankroll />
        <GameOutcomesDisplay histories={histories} />
        <BauCuaOutcomesDisplay bauCuaOutCome={bauCuaOutCome} />
        <div className="Display_above__oCBz5">
          <div className="Coin_shadow__it9xJ"></div>
          <div
            style={{
              "--period": "750s",
              "--diameter": "240px",
              "--thickness": "20px",
            }}
          >
            <div className={"Coin_coin__bKUgo"}>
              {displayBowl()}
            </div>
          </div>
        </div>
        <div className="Bau_Cua_Selection_List">
          <div
            className={"Bau_Cua_Selection bauIcon " + isSelected(0)}
            disabled={action === 0}
            onClick={() => playChoise(0)}
          ></div>
          <div
            className={"Bau_Cua_Selection cuaIcon " + isSelected(1)}
            disabled={action === 1}
            onClick={() => playChoise(1)}
          ></div>
          <div
            className={"Bau_Cua_Selection caIcon " + isSelected(2)}
            disabled={action === 2}
            onClick={() => playChoise(2)}
          ></div>
          <div
            className={"Bau_Cua_Selection gaIcon " + isSelected(3)}
            disabled={action === 3}
            onClick={() => playChoise(3)}
          ></div>
          <div
            className={"Bau_Cua_Selection tomIcon " + isSelected(4)}
            disabled={action === 4}
            onClick={() => playChoise(4)}
          ></div>
          <div
            className={"Bau_Cua_Selection naiIcon " + isSelected(5)}
            disabled={action === 5}
            onClick={() => playChoise(5)}
          ></div>
        </div>
      </div>
    </>
  );
}
