/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import {WalletContext} from '../hooks/WalletContext';

import {menuIcon} from './static/svg';

export function BottomMenu() {
    const navigate = useNavigate(); 
    const { isMobileMenu, toggleMobileMenu } = useContext(WalletContext);

    const routeChange = () =>{ 
        navigate('/profile');
    }
    const classText = () => {
        if (isMobileMenu) return "BottomMenu_active__JUFGd";
        else return "";
    }

    return (<div className="BottomMenu_container__tJIbc">
        {/* <button className="BottomMenu_nav_item__xcHNT BottomMenu_active__JUFGd"></button> */}
        <button className={"BottomMenu_nav_item__xcHNT " + classText()} onClick={toggleMobileMenu}>
            {menuIcon}
            <div>Menu</div>
        </button>
        {/* <button className="BottomMenu_wallet_button__Qf3p_ BottomMenu_active__JUFGd"></button> */}
        <button className={"BottomMenu_wallet_button__Qf3p_ " + classText()} onClick={routeChange}>
            <div className="paper" style={{borderRadius: '50px', display: 'inline-block', margin: '0px', overflow: 'hidden', padding: '0px', backgroundColor: 'rgb(36, 95, 225)', height: '40px', width: '40px'}}>
                <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="40" width="40"><rect x="0" y="0" rx="0" ry="0" height="40" width="40" transform="translate(-0.4648184469732778 1.530281629999972) rotate(276.5 20 20)" fill="#fc1958"></rect><rect x="0" y="0" rx="0" ry="0" height="40" width="40" transform="translate(14.644270330312901 -16.852994980437725) rotate(364.4 20 20)" fill="#1591f2"></rect><rect x="0" y="0" rx="0" ry="0" height="40" width="40" transform="translate(-23.769646618809855 21.658714212998582) rotate(265.8 20 20)" fill="#fc8000"></rect><rect x="0" y="0" rx="0" ry="0" height="40" width="40" transform="translate(-26.868878106509886 -46.047818497240705) rotate(296.0 20 20)" fill="#01828c"></rect></svg>
            </div>
        </button>
    </div>);
}