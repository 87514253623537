/* eslint-disable @typescript-eslint/no-explicit-any */
import { formatUnits, ethers } from "ethers";
import { useContext, useEffect, useState } from 'react';
import {WalletContext} from '../hooks/WalletContext';

import { coinFlipAddress, diceAddress, rockPaperScissorsAddress } from "./static/contractAddress";
import { rpcProviderUrl } from "./static/rpcProviderUrl";
import coinflip from '../abi/coinflip.json';
import dice from '../abi/dice.json';
import buakeogiay from '../abi/buakeogiay.json';
import {usdtIcon} from './static/svg';

const decimals = 10 ** 6;

const formatUsdt = (value) => {
  return parseFloat(formatUnits(value, 6)).toLocaleString("en", {
    minimumFractionDigits: 4,
  });
};

export function ProfilePendingRequests() {
    const coinflipAbi = coinflip.abi;
    const diceAbi = dice.abi;
    const rockPaperScissorsAbi = buakeogiay.abi;


    const [waitingVrfList, setWaitingVrfList] = useState([]);
    const {account, chainId, setNotificationType, setNotificationMessage, handleError} = useContext(WalletContext);

    const refundVrf = async(address) => {
        setNotificationType("normal");
        if (address === coinFlipAddress[chainId]) {
          try {
              const provider = new ethers.BrowserProvider(window.ethereum);
              const signer = await provider.getSigner();
              const coinFlipContract = new ethers.Contract(
                coinFlipAddress[chainId],
                coinflipAbi,
                signer
              );
              const transaction = await coinFlipContract.CoinFlip_Refund();
              await transaction.wait();
              window.location.reload();
              
            } catch (error) {
              // setNotificationType("error");
              // setNotificationMessage("Waiting for more confirmed blocks to initiate the refund");
              handleError(error);
            }
        }
        if (address === diceAddress[chainId]) {
          try {
              const provider = new ethers.BrowserProvider(window.ethereum);
              const signer = await provider.getSigner();
              const diceContract = new ethers.Contract(
                diceAddress[chainId],
                diceAbi,
                signer
              );
              const transaction = await diceContract.Dice_Refund();
              await transaction.wait();
              window.location.reload();
              
            } catch (error) {
              // setNotificationType("error");
              // setNotificationMessage("Waiting for more confirmed blocks to initiate the refund");
              handleError(error);
            }
        }
        if (address === rockPaperScissorsAddress[chainId]) {
          try {
              const provider = new ethers.BrowserProvider(window.ethereum);
              const signer = await provider.getSigner();
              const rockPaperScissorsContract = new ethers.Contract(
                rockPaperScissorsAddress[chainId],
                rockPaperScissorsAbi,
                signer
              );
              const transaction = await rockPaperScissorsContract.RockPaperScissors_Refund();
              await transaction.wait();
              window.location.reload();
              
            } catch (error) {
              // setNotificationType("error");
              // setNotificationMessage("Waiting for more confirmed blocks to initiate the refund");
              handleError(error);
            }
        }
    }

    const showWaitingVrf = () => {
        if (waitingVrfList.length > 0)
          return waitingVrfList.map((waitingVrf) => (
            <tr className="Table_row__FRFl_" key={crypto.randomUUID()} >
                <td>{waitingVrf.game}</td>
                <td>
                    <div style={{'display': 'flex', 'alignItems': 'center'}}>{formatUsdt(waitingVrf.wager)} {usdtIcon}</div>
                </td>
                <td><button className="ProfilePendingRequests_refund_button__DHc47" onClick={()=>refundVrf(waitingVrf.contractAddress)}>Refund</button></td>
            </tr>
          ));
        else return <tr><td className="Table_header_row__9cbIh">Danh sách cược đang chờ kết quả từ VRF Chainlink.</td></tr>;
    };

    useEffect(()=>{
        if (!chainId) return;
        if (!rpcProviderUrl[chainId]) return;
        const provider = new ethers.JsonRpcProvider(rpcProviderUrl[chainId][0]);
        const coinFlipContract = coinFlipAddress[chainId]
                        ? new ethers.Contract(coinFlipAddress[chainId], coinflipAbi, provider)
                        : null;
        const initCoinFlip = async () => {
            try {
                await coinFlipContract.CoinFlip_GetState(account)
                .then((CoinFlipGame) => {
                  const temp = Number(CoinFlipGame.wager) * Number(CoinFlipGame.numBets);
                  const waitingVrf = {
                    wager: temp,
                    game: "Tung Xu",
                    contractAddress: coinFlipAddress[chainId],
                    //abi: coinflipAbi
                  }
                  if (temp !== 0) {
                    setWaitingVrfList(waitingVrfList => [waitingVrf, ...waitingVrfList]);
                  }
                })
                .catch((error) => {
                  console.log(error.reason);
                });
            }catch(e) {
              console.log(e);
            }
          };
        if (coinFlipAddress[chainId])  initCoinFlip();

        const diceContract = diceAddress[chainId]
                        ? new ethers.Contract(diceAddress[chainId], diceAbi, provider)
                        : null;
        const initDice = async () => {
            try {
                await diceContract.Dice_GetState(account)
                .then((DiceGame) => {
                  const temp = Number(DiceGame.wager) * Number(DiceGame.numBets);
                  const waitingVrf = {
                    wager: temp,
                    game: "Xuc Xac",
                    contractAddress: diceAddress[chainId],
                    //abi: coinflipAbi
                  }
                  if (temp !== 0) {
                    setWaitingVrfList(waitingVrfList => [waitingVrf, ...waitingVrfList]);
                  }
                })
                .catch((error) => {
                  console.log(error.reason);
                });
            }catch(e) {
              console.log(e);
            }
          };
        if (diceAddress[chainId])  initDice();

        const rockPaperScissorsContract = rockPaperScissorsAddress[chainId]
                        ? new ethers.Contract(rockPaperScissorsAddress[chainId], rockPaperScissorsAbi, provider)
                        : null;
        const initRockPaperScissors = async () => {
            try {
                await rockPaperScissorsContract.RockPaperScissors_GetState(account)
                .then((RockPaperScissorsGame) => {
                  const temp = Number(RockPaperScissorsGame.wager) * Number(RockPaperScissorsGame.numBets);
                  const waitingVrf = {
                    wager: temp,
                    game: "Oan Tu Ti",
                    contractAddress: rockPaperScissorsAddress[chainId],
                    //abi: coinflipAbi
                  }
                  if (temp !== 0) {
                    setWaitingVrfList(waitingVrfList => [waitingVrf, ...waitingVrfList]);
                  }
                })
                .catch((error) => {
                  console.log(error.reason);
                });
            }catch(e) {
              console.log(e);
            }
          };
        if (rockPaperScissorsAddress[chainId])  initRockPaperScissors();
        
        return () => {
        };
    },[account, chainId]);

    return (
    <div className="ProfilePendingRequests_container__rqjoH">
        <div className="ProfilePendingRequests_title__WnPcz">Những cược đang chờ xử lý</div>
        <div className="ProfilePendingRequests_content__CcmXN">
            <div>
                <div className="Table_container__scijj">
                    <table className="Table_table__cHzwC">
                        <thead>
                            <tr className="Table_header_row__9cbIh"><th>Trò chơi</th><th>Tổng Cược</th><th>Hoàn Tiền</th></tr>
                        </thead>
                        <tbody>
                            {showWaitingVrf()}
                        </tbody>
                    </table>
                    <div className="Table_empty_container__JA324"></div>
                </div>
                
            </div>
        </div>
    </div>
  );
}