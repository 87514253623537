/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState, useEffect } from "react";
import { ethers } from "ethers";
import { MobileView } from "react-device-detect";
import { MobileMenu } from "../components/MobileMenu";
import { BottomMenu } from "../components/BottomMenu";

import { WalletContext } from "../hooks/WalletContext";
import { MultiPlayerInputContainer } from "../components/MultiPlayerInputContainer";
import { TaiXiuDisplay } from "../components/TaiXiuDisplay";
import { TaiXiuDescription } from "../components/static/TaiXiuDescription";
import { GameFeedMultiplayer } from "../components/GameFeedMultiplayer";
import { MoreGame } from "../components/MoreGame";
import { Footer } from "../components/static/Footer";

import { listenToEventWithExponentialBackoff } from "../hooks/function";

//import { rockPaperScissorsAddress, erc20Address } from "../components/static/contractAddress";
import { rpcProviderUrl } from "../components/static/rpcProviderUrl";
import taixiu from "../abi/taixiu.json";
import {
  taiXiuAddress,
  erc20Address,
} from "../components/static/contractAddress";

import erc20 from "../abi/erc20.json";

export function TaiXiu() {
  const {
    account,
    chainId,
    setNotificationType,
    leftSideClose,
    setWaitingVrf,
    round,
    setRound,
    handleError,
  } = useContext(WalletContext);

  const erc20Abi = erc20.abi;
  const taiXiuAbi = taixiu.abi;
  const decimals = 10 ** 6;

  const [isBig, setIsBig] = useState(0);
  const [allowance, setAllowance] = useState(0);
  const [balance, setBalance] = useState(0);

  const [gameFeedData, setGameFeedMultiplayerData] = useState([]);

  const closeStyle = () => {
    if (leftSideClose) return "50px";
    else return "";
  };

  const time = () => {
    const temp = new Date();
    const hours =
      temp.getHours() < 10 ? "0" + temp.getHours() : temp.getHours();
    const minutes =
      temp.getMinutes() < 10 ? "0" + temp.getMinutes() : temp.getMinutes();
    return hours + ":" + minutes;
  };

  const approveErc20 = async (amount) => {
    // console.log("approveErc20: ", amount);
    setNotificationType("normal");
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const erc20Contract = erc20Address[chainId]
      ? new ethers.Contract(erc20Address[chainId], erc20Abi, signer)
      : null;

    if (erc20Contract) {
      const tx = erc20Contract
        .approve(taiXiuAddress[chainId], Math.floor(Number(amount * decimals)))
        .then(() => {
          updateAllowance();
          setNotificationType("");
        })
        .catch((e) => {
          handleError(e);
        });
    }
  };
  const depositTransaction = async (amount) => {
    // updateBalance depositToGame(address tokenAddress, uint256 amount)
    if (!window.ethereum) return;
    if (!taiXiuAddress[chainId]) return;

    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const taiXiuContract = new ethers.Contract(
        taiXiuAddress[chainId],
        taiXiuAbi,
        signer
      );
      const transaction = taiXiuContract
        .depositToGame(erc20Address[chainId], amount * decimals)
        .then(() => {
          updateBalance();
          updateAllowance();
        })
        .catch((e) => {
          handleError(e);
        });
    } catch (error) {
      handleError(error);
    }
  };
  const withdrawTransaction = async () => {
    // updateBalance  withdrawAll
    if (!window.ethereum) return;
    if (!taiXiuAddress[chainId]) return;

    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const taiXiuContract = new ethers.Contract(
        taiXiuAddress[chainId],
        taiXiuAbi,
        signer
      );
      const transaction = taiXiuContract
        .withdrawAll(erc20Address[chainId])
        .then(() => {
          updateBalance();
        })
        .catch((e) => {
          handleError(e);
        });
    } catch (error) {
      handleError(error);
    }
  };
  const playTransaction = async (wagerToContract) => {
    if (!window.ethereum) return;
    if (!taiXiuAddress[chainId]) return;
    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const taiXiuContract = new ethers.Contract(
        taiXiuAddress[chainId],
        taiXiuAbi,
        signer
      );
      // console.log(wagerToContract * decimals, erc20Address[chainId], isBig);

      const transaction = taiXiuContract
        .TaiXiu_Play(wagerToContract * decimals, erc20Address[chainId], isBig)
        .then(() => {
          updateBalance();
        })
        .catch((e) => {
          handleError(e);
        });
    } catch (error) {
      handleError(error);
    }
  };

  const updateAllowance = async () => {
    const provider = new ethers.JsonRpcProvider(rpcProviderUrl[chainId][4]);
    const erc20Contract = new ethers.Contract(
      erc20Address[chainId],
      erc20Abi,
      provider
    );
    const allowanceAmount = await erc20Contract.allowance(
      account,
      taiXiuAddress[chainId]
    );
    setAllowance(Number(allowanceAmount));
  };

  const updateBalance = async () => {
    const provider = new ethers.JsonRpcProvider(rpcProviderUrl[chainId][4]);
    const taiXiuContract = new ethers.Contract(
      taiXiuAddress[chainId],
      taiXiuAbi,
      provider
    );

    const getBalance = taiXiuContract
      .getBalance(erc20Address[chainId], account)
      .then((balance) => {
        setBalance(Number(balance));
      })
      .catch((error) => handleError(error));
  };

  useEffect(() => {
    if (!account) return;
    if (!rpcProviderUrl[chainId]) return;
    if (!taiXiuAddress[chainId]) return;
    if (!erc20Address[chainId]) return;

    updateBalance();
    updateAllowance();

    const provider = new ethers.JsonRpcProvider(rpcProviderUrl[chainId][4]);
    const taiXiuContract = new ethers.Contract(taiXiuAddress[chainId], taiXiuAbi, provider);

    const TaiXiu_Feed_Listener = (playerAddress, wager, tokenAddress, isBig) => {
      // console.log(playerAddress, wager, tokenAddress, isBig);
      const wagers = Number(wager);
      const side = isBig ? "Tài" : "Xỉu";

      const newFeed = { chainId: chainId, time: time(), game: "Tài Xỉu", slash: "/taixiu",
        player: playerAddress, wager: wagers, side: side,
      };
      // console.log(newFeed);
      setGameFeedMultiplayerData((gameFeedData) => [newFeed, ...gameFeedData]);
    };

    const startListening = async () => {
      try {
        await listenToEventWithExponentialBackoff(
          taiXiuContract,
          "TaiXiu_Play_EVENT",
          5,
          16000,
          TaiXiu_Feed_Listener
        );
      } catch (e) {
        handleError(e);
      }
    };
    startListening();

    return () => {
      taiXiuContract.off("TaiXiu_Play_EVENT", TaiXiu_Feed_Listener);
    };
  }, [account, chainId]);

  return (
    <div
      className="Layout_middle_container__rQzvK"
      style={{ "--left_margin": closeStyle() }}
    >
      <main className="Layout_content__3KYZT">
        <div className="GameWrapper_game_container___djZh">
          <div className="Dice_container___ID90">
            <MultiPlayerInputContainer
              approveErc20={approveErc20}
              playTransaction={playTransaction}
              depositTransaction={depositTransaction}
              withdrawTransaction={withdrawTransaction}
              allowance={allowance}
              balance={balance}
            />
            <TaiXiuDisplay setIsBig={setIsBig} updateBalance={updateBalance} />
          </div>
          <TaiXiuDescription />
        </div>
        <GameFeedMultiplayer gameFeedData={gameFeedData} />
        <MoreGame />
        <MobileView>
          <MobileMenu />
          <BottomMenu />
        </MobileView>
        <Footer />
      </main>
    </div>
  );
}
