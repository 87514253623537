/*
0: XocDia: XocDia_VRF_Request_EVENT
1: XocDia: XocDia_Round_INFO_Event
2: XocDia: XocDia_Take_Balance_EVENT
3: XocDia: XocDia_Play_EVENT
4: XocDia: RoundisOpenNow_EVENT

5: BauCua
6: CoinFlip
7: Dice
8: RockPaperScissors
9: Header

10: TaiXiu
11: TaiXiu
12: TaiXiu
13: TaiXiu
14: TaiXiu
*/

export const rpcProviderUrl = {
  42161: [
    "https://arb-mainnet.g.alchemy.com/v2/2EP26GBk8F54LO6KnBNCdxl6enRN1tig",
    "https://arb-mainnet.g.alchemy.com/v2/U_ZodkS9d6ubdnKwxYVVrUGih--vpGxM",
    "https://arb-mainnet.g.alchemy.com/v2/fKwcrsqdAwsEQtxaNf4LmI6sO2KakFiE",
    "https://arb-mainnet.g.alchemy.com/v2/XqWOm1t4RRd3L8q716q1jG4yDVnGgPqi",
    "https://arb-mainnet.g.alchemy.com/v2/noy_TRGjrPzNeETOTNsGxewnc1_d4Kra",
    "https://arb-mainnet.g.alchemy.com/v2/jpIx2N7X0B7Oge_CIsFw9OkpW1CyanF3",
    "https://arb-mainnet.g.alchemy.com/v2/TqpxxMkrS2DZ-I8cpgbf_xsKDPTRhNyG",
    "https://arb-mainnet.g.alchemy.com/v2/KDfdFgDqqRh-tW0HejWaIKtcpxRYw5kj",
    "https://arb-mainnet.g.alchemy.com/v2/8LRxG6FtvrpLLDKj79ouEaLlz_3YukjV",
    "https://arb-mainnet.g.alchemy.com/v2/urmbb6xVoM30p2dPkVis9rBgJM0yAzJB",
    "https://arb-mainnet.g.alchemy.com/v2/GhSJ0PHBWby4mtPfcqEqPri6bAe1ngsv",
    "https://arb-mainnet.g.alchemy.com/v2/RqPDyBcq7wO6nwLaW7VbCN47a5X9l2kJ",
    "https://arb-mainnet.g.alchemy.com/v2/QQVLzuwhpowIUovnmVlv7yGEDR_c6ajK",
    "https://arb-mainnet.g.alchemy.com/v2/nVaHBFN7ruaPh2ay6C0ecSMNQzjmiDSR",
    "https://arb-mainnet.g.alchemy.com/v2/6Ut0H2puNkYFYDkF2yr-KiWfBgXZb0jZ",
  ],
  137: [
    "https://polygon-mainnet.g.alchemy.com/v2/2EP26GBk8F54LO6KnBNCdxl6enRN1tig",
    "https://polygon-mainnet.g.alchemy.com/v2/U_ZodkS9d6ubdnKwxYVVrUGih--vpGxM",
    "https://polygon-mainnet.g.alchemy.com/v2/fKwcrsqdAwsEQtxaNf4LmI6sO2KakFiE",
    "https://polygon-mainnet.g.alchemy.com/v2/XqWOm1t4RRd3L8q716q1jG4yDVnGgPqi",
    "https://polygon-mainnet.g.alchemy.com/v2/noy_TRGjrPzNeETOTNsGxewnc1_d4Kra",
    "https://polygon-mainnet.g.alchemy.com/v2/jpIx2N7X0B7Oge_CIsFw9OkpW1CyanF3",
    "https://polygon-mainnet.g.alchemy.com/v2/TqpxxMkrS2DZ-I8cpgbf_xsKDPTRhNyG",
    "https://polygon-mainnet.g.alchemy.com/v2/KDfdFgDqqRh-tW0HejWaIKtcpxRYw5kj",
    "https://polygon-mainnet.g.alchemy.com/v2/8LRxG6FtvrpLLDKj79ouEaLlz_3YukjV",
    "https://polygon-mainnet.g.alchemy.com/v2/urmbb6xVoM30p2dPkVis9rBgJM0yAzJB",
    "https://polygon-mainnet.g.alchemy.com/v2/GhSJ0PHBWby4mtPfcqEqPri6bAe1ngsv",
    "https://polygon-mainnet.g.alchemy.com/v2/RqPDyBcq7wO6nwLaW7VbCN47a5X9l2kJ",
    "https://polygon-mainnet.g.alchemy.com/v2/QQVLzuwhpowIUovnmVlv7yGEDR_c6ajK",
    "https://polygon-mainnet.g.alchemy.com/v2/nVaHBFN7ruaPh2ay6C0ecSMNQzjmiDSR",
    "https://polygon-mainnet.g.alchemy.com/v2/6Ut0H2puNkYFYDkF2yr-KiWfBgXZb0jZ",
  ],
  421614: [
    "https://arb-sepolia.g.alchemy.com/v2/2EP26GBk8F54LO6KnBNCdxl6enRN1tig",
    "https://arb-sepolia.g.alchemy.com/v2/U_ZodkS9d6ubdnKwxYVVrUGih--vpGxM",
    "https://arb-sepolia.g.alchemy.com/v2/fKwcrsqdAwsEQtxaNf4LmI6sO2KakFiE",
    "https://arb-sepolia.g.alchemy.com/v2/XqWOm1t4RRd3L8q716q1jG4yDVnGgPqi",
    "https://arb-sepolia.g.alchemy.com/v2/noy_TRGjrPzNeETOTNsGxewnc1_d4Kra",
    "https://arb-sepolia.g.alchemy.com/v2/jpIx2N7X0B7Oge_CIsFw9OkpW1CyanF3",
    "https://arb-sepolia.g.alchemy.com/v2/TqpxxMkrS2DZ-I8cpgbf_xsKDPTRhNyG",
    "https://arb-sepolia.g.alchemy.com/v2/KDfdFgDqqRh-tW0HejWaIKtcpxRYw5kj",
    "https://arb-sepolia.g.alchemy.com/v2/8LRxG6FtvrpLLDKj79ouEaLlz_3YukjV",
    "https://arb-sepolia.g.alchemy.com/v2/urmbb6xVoM30p2dPkVis9rBgJM0yAzJB",
    "https://arb-sepolia.g.alchemy.com/v2/GhSJ0PHBWby4mtPfcqEqPri6bAe1ngsv",
    "https://arb-sepolia.g.alchemy.com/v2/RqPDyBcq7wO6nwLaW7VbCN47a5X9l2kJ",
    "https://arb-sepolia.g.alchemy.com/v2/QQVLzuwhpowIUovnmVlv7yGEDR_c6ajK",
    "https://arb-sepolia.g.alchemy.com/v2/nVaHBFN7ruaPh2ay6C0ecSMNQzjmiDSR",
    "https://arb-sepolia.g.alchemy.com/v2/6Ut0H2puNkYFYDkF2yr-KiWfBgXZb0jZ",
  ],
};


export const rpcProviderUrlBackUp = {
  42161: [
    "https://arb-mainnet.g.alchemy.com/v2/2EP26GBk8F54LO6KnBNCdxl6enRN1tig",
    "https://arb-mainnet.g.alchemy.com/v2/U_ZodkS9d6ubdnKwxYVVrUGih--vpGxM",
    "https://arb-mainnet.g.alchemy.com/v2/fKwcrsqdAwsEQtxaNf4LmI6sO2KakFiE",
    "https://arb-mainnet.g.alchemy.com/v2/XqWOm1t4RRd3L8q716q1jG4yDVnGgPqi",
    "https://arb-mainnet.g.alchemy.com/v2/noy_TRGjrPzNeETOTNsGxewnc1_d4Kra",
  ],
  137: [
    "https://polygon-mainnet.g.alchemy.com/v2/jpIx2N7X0B7Oge_CIsFw9OkpW1CyanF3",
    "https://polygon-mainnet.g.alchemy.com/v2/TqpxxMkrS2DZ-I8cpgbf_xsKDPTRhNyG",
    "https://polygon-mainnet.g.alchemy.com/v2/KDfdFgDqqRh-tW0HejWaIKtcpxRYw5kj",
    "https://polygon-mainnet.g.alchemy.com/v2/8LRxG6FtvrpLLDKj79ouEaLlz_3YukjV",
    "https://polygon-mainnet.g.alchemy.com/v2/urmbb6xVoM30p2dPkVis9rBgJM0yAzJB",
  ],
  421614: [
    "https://arb-sepolia.g.alchemy.com/v2/GhSJ0PHBWby4mtPfcqEqPri6bAe1ngsv",
    "https://arb-sepolia.g.alchemy.com/v2/RqPDyBcq7wO6nwLaW7VbCN47a5X9l2kJ",
    "https://arb-sepolia.g.alchemy.com/v2/QQVLzuwhpowIUovnmVlv7yGEDR_c6ajK",
    "https://arb-sepolia.g.alchemy.com/v2/nVaHBFN7ruaPh2ay6C0ecSMNQzjmiDSR",
    "https://arb-sepolia.g.alchemy.com/v2/6Ut0H2puNkYFYDkF2yr-KiWfBgXZb0jZ",
  ],
};