/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from "react";
import {formatUnits} from "ethers";
import { NavLink } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { BrowserView, MobileView } from 'react-device-detect';


import { WalletContext } from "../hooks/WalletContext";

import { arbitrumIcon, polygonIcon, ethereumIcon, usdtIcon } from "./static/svg";
import { 
    coinFlipAddress, 
    diceAddress, 
    rockPaperScissorsAddress,
    bauCuaAddress
  } from './static/contractAddress';

const formatUsdt = (value) => {
    if (value === "-") return "+-x";
    else return parseFloat(formatUnits(value, 6)).toLocaleString("en", {
      minimumFractionDigits: 2,
    });
  };

export function GameFeed({gameFeedData}) {
    // console.log(gameFeedData);
    const { account } = useContext(WalletContext);

    // Arbitrum (chainId === 42161)    Polygon (chainId === 137)  Arbitrum Sepolia (chainId === 421614(0x66eee))  
    const explorer = {
        42161: "https://arbiscan.io/",
        137: "https://polygonscan.com/",
        421614: "https://sepolia.arbiscan.io/"
    } 
   //  console.log(gameFeedData.chainId, explorer[gameFeedData.chainId]);

    const networkIcon = (value) => {
        if (value === 42161) return arbitrumIcon;
        if (value === 137) return polygonIcon;
        if (value === 421614) return arbitrumIcon;
        else return ethereumIcon
    }

    const classProfit = (value) => {
        if (value > 0 ) return "GlobalGameFeed_win__KSd5_";
        else return "";
    }

    const classRow = (player) => {
        if (account) {
            if (account.toUpperCase() === player.toUpperCase()) return "GameFeed_player_row__AAVaw";
            else return "";
        } else return "";
    };
    
    const contractExplorer = (slash, _chainId) => {
        let address = "";
        if (slash === '/tungxu') address = coinFlipAddress[_chainId];
        else if (slash === '/xucxac') address = diceAddress[_chainId];
        else if (slash === '/oantuti') address = rockPaperScissorsAddress[_chainId];
        else if (slash === '/baucua') address = bauCuaAddress[_chainId];
        return address;
    }

    const feed = () => {
        // console.log(gameFeedData);
        if (gameFeedData.length !== 0)
        return gameFeedData.slice(0, 10).map((data) => (
            <div className={classRow(data.player) + " GlobalGameFeed_row__PhCQS"} key={uuidv4()}>
                <div className="GlobalGameFeed_timestamp__mMI_x">
                        <NavLink to={explorer[data.chainId] + "address/" + contractExplorer(data.slash,data.chainId)} >
                            {data.time}
                            <svg height="1em" viewBox="0 0 18 18"><path fillRule="evenodd" clipRule="evenodd" d="M2 2V16H16V9H18V16C18 17.1 17.1 18 16 18H2C0.89 18 0 17.1 0 16V2C0 0.9 0.89 0 2 0H9V2H2Z"></path><path d="M11 0V2H14.59L4.76 11.83L6.17 13.24L16 3.41V7H18V0H11Z"></path></svg>
                        </NavLink>
                    </div>
                <BrowserView>
                    <div className="GlobalGameFeed_game__z6kpc">
                        <NavLink to={data.slash}>
                            <div className="GlobalGameFeed_network_icon__7mxXB">
                                {networkIcon(data.chainId)}
                            </div>
                            {data.game}
                        </NavLink>
                    </div>
                </BrowserView>
                <BrowserView>
                    <div className="GlobalGameFeed_player__DUdJR GlobalGameFeed_address__HLDUh " >
                        <a href={explorer[data.chainId] + "address/" + data.player}>
                            <div className="paper" style={{'borderRadius': '50px', 'display': 'inline-block', 'margin': '0px', 'overflow': 'hidden', 'padding': '0px', 'backgroundColor': 'rgb(252, 25, 41)', 'height': '15px', 'width': '15px'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="15" width="15"><rect x="0" y="0" rx="0" ry="0" height="15" width="15" transform="translate(-0.06281948292294663 0.024767708564772433) rotate(216.5 7.5 7.5)" fill="#f1d802"></rect><rect x="0" y="0" rx="0" ry="0" height="15" width="15" transform="translate(7.577561773192083 3.1649802581979065) rotate(161.2 7.5 7.5)" fill="#f77b01"></rect><rect x="0" y="0" rx="0" ry="0" height="15" width="15" transform="translate(7.6331182503584225 -6.494913455115599) rotate(485.4 7.5 7.5)" fill="#e8f300"></rect><rect x="0" y="0" rx="0" ry="0" height="15" width="15" transform="translate(-18.711989763735442 1.3748646815010066) rotate(225.8 7.5 7.5)" fill="#01658c"></rect></svg>
                            </div>
                            {data.player.slice(0, 8)}
                        </a>
                    </div>
                </BrowserView>
                <MobileView>
                    <div className="GlobalGameFeed_player__DUdJR GlobalGameFeed_address__HLDUh " style={{'minWidth':'80px', 'width':'80px'}}>
                        <a href={explorer[data.chainId] + "address/" + data.player}>
                            <div className="paper" style={{'borderRadius': '50px', 'display': 'inline-block', 'margin': '0px', 'overflow': 'hidden', 'padding': '0px', 'backgroundColor': 'rgb(252, 25, 41)', 'height': '15px', 'width': '15px'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="15" width="15"><rect x="0" y="0" rx="0" ry="0" height="15" width="15" transform="translate(-0.06281948292294663 0.024767708564772433) rotate(216.5 7.5 7.5)" fill="#f1d802"></rect><rect x="0" y="0" rx="0" ry="0" height="15" width="15" transform="translate(7.577561773192083 3.1649802581979065) rotate(161.2 7.5 7.5)" fill="#f77b01"></rect><rect x="0" y="0" rx="0" ry="0" height="15" width="15" transform="translate(7.6331182503584225 -6.494913455115599) rotate(485.4 7.5 7.5)" fill="#e8f300"></rect><rect x="0" y="0" rx="0" ry="0" height="15" width="15" transform="translate(-18.711989763735442 1.3748646815010066) rotate(225.8 7.5 7.5)" fill="#01658c"></rect></svg>
                            </div>
                            {data.player.slice(0, 8)}
                        </a>
                    </div>
                </MobileView>
                <div className="GlobalGameFeed_wager__SyapG">
                    {formatUsdt(data.wager)}
                    {usdtIcon}
                </div>
                <BrowserView>
                    <div className="GlobalGameFeed_multiplier__SYFYL">{data.multiplier}×</div>
                </BrowserView>
                <BrowserView>
                    <div className={"GlobalGameFeed_profit__YTw0q " + classProfit(data.profit)}>
                        <span>
                            {formatUsdt(data.profit)} {usdtIcon}
                        </span>
                    </div>
                </BrowserView>
            </div>
        )); 
        else return <div className="GlobalGameFeed_timestamp__mMI_x"><div className="GameFeed_empty_text__KFOgU"><span>Danh sách cược của bạn sẽ được hiển thị ở đây</span></div></div>;
    }
    
    return (
        <div className="GlobalGameFeed_container__E1D38">
                <div className="GlobalGameFeed_header__7EEgt">
                    <div className="GlobalGameFeed_title__Qrh6a">
                        <div className="GlobalGameFeed_live_indicator__PG1W_"></div>
                        <h2 className="GlobalGameFeed_title_name__muTne">Đặt Cược Gần Nhất</h2>
                    </div>
                </div>
                <div className="GlobalGameFeed_table_container__0jAJG">
                    <div className="GlobalGameFeed_table__O_wEx">
                        <div className="GlobalGameFeed_header_row__ELXCZ">
                            <div className="GlobalGameFeed_header_timestamp__4f0iM">Thời gian</div>
                            <BrowserView>
                                <div className="GlobalGameFeed_header_game___ZETQ">Game</div>
                            </BrowserView>
                            <BrowserView>
                                <div className="GlobalGameFeed_header_player__IrfmX" >Người chơi</div>
                            </BrowserView>
                            <MobileView>
                                <div className="GlobalGameFeed_header_player__IrfmX"  style={{'minWidth':'80px', 'width':'80px'}}>Người chơi</div>
                            </MobileView>
                            <div className="GlobalGameFeed_header_wager__fntom">Cược</div>
                            <BrowserView>
                                <div className="GlobalGameFeed_header_multiplier__zB67M">Tỉ lệ nhân</div>
                            </BrowserView>
                            <BrowserView>
                                <div className="GlobalGameFeed_header_profit__XZsuP">Lợi nhuận</div>
                            </BrowserView>
                        </div>
                        {feed()}
                    </div>
                    
                </div>
            </div>
  );
}
