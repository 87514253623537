/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState, useEffect } from "react";

import { WalletContext } from "../hooks/WalletContext";
import { usdtIcon } from "./static/svg";


export function InputContainer({
  multiplier,
  sendTransaction,
  allowance,
  approveErc20,
}) {
  const {
    account,
    chainId,
    bankrollPool,
    erc20Balance,
    connectMetaMask,
    isWaitingVrf
  } = useContext(WalletContext);
  
  const decimals = 10 ** 6;
  const [wager, setWager] = useState(0);
  const [kellyWager, setKellyWager] = useState(0);
  const [bets, setBets] = useState(1);
  const [advanced, SetAdvanced] = useState(false);
  const [stop, setStop] = useState(0);
  const [take, setTake] = useState(0);

  useEffect(()=>{
    if (window.location.pathname === '/tungxu') setKellyWager((bankrollPool * 1000000) / 100000000);
    else if (window.location.pathname === '/xucxac') setKellyWager((bankrollPool * 100) / (multiplier*10**4 - 10000));
    else if (window.location.pathname === '/oantuti') setKellyWager((bankrollPool * 1530571) / 100000000);
    else setKellyWager((bankrollPool * 1000000) / 100000000);
  },[allowance, bankrollPool, multiplier, erc20Balance]);

  const handleWager = (event) => {
    setWager(event.target.value);
  };
  const maxWager = () => {
    // let tempApprove = 0;
    // if (erc20Balance / decimals < kellyWager/10**6) tempApprove = Math.floor(erc20Balance / decimals);
    // else tempApprove = Math.floor(kellyWager/10**6);
    // if (tempApprove * bets > erc20Balance) return erc20Balance / bets
    if (erc20Balance / decimals < kellyWager/10**6) return Math.floor(erc20Balance / decimals);
    else return Math.floor(kellyWager/10**6);
  };

  const blurWager = (event) => {
    if (event.target.value < 4) setWager(4);
    else setWager(event.target.value);
  }

  const handleBets = (event) => {
    setBets(event.target.value);
  };
  const blurBets = (event) => {
    if (Math.floor(event.target.value) <= 0) setBets(1);
    else if (Math.floor(event.target.value) > 100) setBets(100);
    else setBets(Math.floor(event.target.value));
  };

  const handleStop = (event) => {
    setStop(event.target.value);
  };

  const handleTake = (event) => {
    setTake(event.target.value);
  };

  const totalWager = () => {
    const total = wager * bets;
    if (total === 0) return "-";
    else return total.toFixed(4);
  };

  const maxPayout = () => {
    const payout = wager * bets * multiplier;
    if (payout === 0) return "-";
    else return payout.toFixed(4);
  };

  const toogleAdvanced = () => {
    if (advanced) SetAdvanced(false);
    else SetAdvanced(true);
  };

  const classButton = () => {
    if (advanced) return "MultibetsInput_shown__WTZb1";
    else return "";
  };

  const classInput = () => {
    if (advanced) return "MultibetsInput_show_advanced__18Hq_";
    else return "";
  };

  const playGame = () => {
    const stopGain = take * decimals || "7237005577332262213973186563042994240829374041602535252466099000494570602496";
    const stopLoss = stop * decimals || "7237005577332262213973186563042994240829374041602535252466099000494570602496";
     sendTransaction(Math.floor(wager * decimals), bets, stopLoss, stopGain); 
  };

  const validChain = () => {
    if (chainId === 42161) return true;
    else if (chainId === 137) return true;
    else if (chainId === 421614) return true;
    else return false;
  }

  const playBtn = () => {
    if (!account)
      return (
        <button
          className="Inputs_connect_button__CHJzC"
          onClick={connectMetaMask}
        >
          Connect Wallet
        </button>
      );
    else if (isWaitingVrf)
      return (
        <button className="Inputs_play_button__fdpyi" disabled={true}>
          Playing...
        </button>
      );
    else if (!validChain()) 
      return (
        <button className="Inputs_play_button__fdpyi" disabled={true}>
          Change Network
        </button>
      );
    else if (totalWager() === "-")
      return (
        <button className="Inputs_play_button__fdpyi" disabled={true}>
          Play
        </button>
      );
    else if (totalWager() > erc20Balance / decimals)
      return (
        <button className="Inputs_play_button__BqDjt" disabled={true}>
          Exceeds Max
        </button>
      );
    else if (wager * bets * 10 ** 6 > allowance)
      return (
        <button className="Inputs_util_button__HL2Vc" onClick={() => {approveErc20(wager * bets)}}>
          Approve USDT
        </button>
      );
    else
      return (
        <button className="Inputs_play_button__fdpyi" onClick={playGame}>
          Play
        </button>
      );
  };

  return (
    <div className="Inputs_container__JmLHD">
      <div className="WagerInput_container__fnLjZ ">
        <div className="WagerInput_title__BpTQv">Tiền Cược</div>
        <div className="WagerInput_inputs__70u4x">
          <div className="WagerInput_inputs_top__w_nKi">
            <input
              className="WagerInput_input__WL9np"
              type="number"
              inputMode="decimal"
              placeholder="Chọn một mức cược ..."
              onChange={handleWager}
              onBlur={blurWager}
              value={wager || ""}
            />
            <div className="WagerInput_token_icon__QwSuK">{usdtIcon}</div>
          </div>
          <input
            className="WagerInput_slider__RhOmz"
            type="range"
            min="4"
            max={maxWager()}
            onChange={handleWager}
            value={wager}
            step="0.0001"
            style={{ "--percent": (wager * 100) / maxWager() + "%" }}
          />
          <div className="WagerInput_buttons__MkH8c">
            <button onClick={() => {if (wager >= 8) setWager(wager / 2); else setWager(4)}}>½</button>
            <button onClick={() => {if (wager*2 <= maxWager())setWager(wager * 2); else setWager(maxWager())}}>2×</button>
            <button onClick={() => {setWager(maxWager())}}>Max</button>
          </div>
        </div>
      </div>
      <div className="MultibetsInput_container___njtd">
        <div className="MultibetsInput_row__ldmAb">
          <div className="Bets_container__33LAm ">
            <div className="Bets_title__GSLCH">Số lần cược</div>
            <div className="Bets_inputs__9JGTF">
              <input
                className="Bets_number_input__O5OQn"
                type="number"
                inputMode="numeric"
                onChange={handleBets}
                onBlur={blurBets}
                value={bets}
              />
              <input
                className="Bets_slider__hA5gN"
                type="range"
                min="1"
                max="100"
                onChange={handleBets}
                value={bets || ""}
                style={{ "--percent": bets + "%" }}
              />
            </div>
          </div>
        </div>
        <div className="MultibetsInput_row__ldmAb">
          <div className="TotalWagerDisplay_container__NEV4v">
            <div className="TotalWagerDisplay_title__jxvgC">Tổng tiền cược</div>
            <div className="TotalWagerDisplay_content__T1_pd">
              <div className="TotalWagerDisplay_no_wager__Ke3Ft">
                {totalWager()}
              </div>
              <div className="TotalWagerDisplay_token_icon__cpEPi">
                {usdtIcon}
              </div>
            </div>
          </div>
          <div className="ProfitDisplay_container__CzbUB">
            <div className="ProfitDisplay_title__cOkih">Tiền thưởng tối đa</div>
            <div className="ProfitDisplay_content__hWqmL">
              <div className="ProfitDisplay_no_wager___Po2S">{maxPayout()}</div>
              <div className="ProfitDisplay_token_icon__Q6Agr">
                {usdtIcon}
              </div>
            </div>
          </div>
        </div>
        <div className="MultibetsInput_advanced__q7dd5">
          <button
            className={"MultibetsInput_advanced_button__8aXqH " + classButton()}
            onClick={toogleAdvanced}
          >
            <span
              className={
                "MultibetsInput_advanced_button__8aXqH " + classButton()
              }
            >
              Nâng Cao
              <svg
                viewBox="6 6 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 10L12 15L17 10"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </span>
            <hr className="MultibetsInput_advanced_hr__oFc25" />
          </button>
          <div
            className={"MultibetsInput_advanced_content__K_4fl " + classInput()}
          >
            <div className="StopLoss_container__9XRQs ">
              <div className="StopLoss_title___iHRo">Dừng khi lỗ</div>
              <div className="StopLoss_inputs__FI057">
                <div className="StopLoss_inputs_top__zJjdD">
                  <input
                    className="StopLoss_number_input__tgkqZ"
                    type="number"
                    inputMode="numeric"
                    placeholder="-"
                    onChange={handleStop}
                    value={stop || ""}
                  />
                  <div>{usdtIcon}</div>
                </div>
                {/* <input
                  className="StopLoss_slider__Lt2en"
                  type="range"
                  min="0.0001"
                  max={totalWager()}
                  onChange={handleStop}
                  value={stop}
                  style={{ "--percent": ((stop * 100) / totalWager()) + "%" }}
                /> */}
              </div>
            </div>
            <div className="StopGain_container__O_9Qy ">
              <div className="StopGain_title__bE3OY">Chốt Lãi</div>
              <div className="StopGain_inputs___zYkl">
                <div className="StopGain_inputs_top__PKaf6">
                  <input
                    className="StopGain_number_input___wvjx"
                    type="number"
                    inputMode="numeric"
                    placeholder="-"
                    onChange={handleTake}
                    value={take || ""}
                  />
                  <div>{usdtIcon}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {playBtn()}
    </div>
  );
}
