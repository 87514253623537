/* eslint-disable @typescript-eslint/no-explicit-any */
import { ethers, formatUnits } from "ethers";
import { BrowserView, MobileView } from 'react-device-detect';

import { useContext, useState, useEffect } from "react";
import { WalletContext } from "../hooks/WalletContext";
import { erc20Address } from "../components/static/contractAddress";
import { bankrollAddress, coinFlipAddress } from "../components/static/contractAddress";
import abi from "../abi/bankroll.json";
import erc20 from "../abi/erc20.json";
import coinflip from "../abi/coinflip.json";

import {usdtIcon} from './static/svg';

const decimals = 10 ** 6;

const formatUsdt = (value) => {
  return parseFloat(formatUnits(value, 6)).toLocaleString("en", {
    minimumFractionDigits: 4,
  });
};

export function ProfileStatistics() {
  const { account, chainId, connectMetaMask, setNotificationType, handleError } =
    useContext(WalletContext);
  const bankrollAbi = abi.abi;
  const erc20Abi = erc20.abi;
  const coinflipAbi = coinflip.abi;

  const [wagers, setWagers] = useState(0);
  const [reward, setReward] = useState(0);
  const [ref, setRef] = useState("0x0000000000000000000000000000000000000000"); // Address(0)

  const [inputRef, setInputRef] = useState("");

  const [bankrollBalance, setBankrollBalance] = useState(0);
  const [shares, setShares] = useState(0);
  const [amount, setAmount] = useState(0);
  const [allowance, setAllowance] = useState(0);

  const handleRef = (event) => {
    //console.log(event.target.value);
    setInputRef(event.target.value);
  }

  const addRefToContract = async() => {
    // console.log("addRefToContract: ", inputRef);
    // Check not connected and wrong chain.
    if (!account) return;
    if (!bankrollAddress[chainId]) return;
    if (!erc20Address[chainId]) return;

    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(
          bankrollAddress[chainId],
          bankrollAbi,
          signer
      );
      setNotificationType("normal");
      const transaction = await contract.setRef(inputRef);
      await transaction.wait().then(()=>{
        setRef(inputRef);
      });
      // window.location.reload();
    } catch(error) {
      handleError(error);
    }
  }

  const handleAmount = (event) => {
    setAmount(event.target.value);
  }

  const withdrawReward = async() => {
    // Check not connected and wrong chain.
    if (!account) return;
    if (!bankrollAddress[chainId]) return;
    if (!erc20Address[chainId]) return;

    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(
        bankrollAddress[chainId],
        bankrollAbi,
        signer
    );
    setNotificationType("normal");
    const transaction = await contract.withdrawReward();
    await transaction.wait();
    window.location.reload();
  }

  const investmentBanking = async() => {
    // Check not connected and wrong chain.
    if (!account) return;
    if (!coinFlipAddress[chainId]) return;
    if (!erc20Address[chainId]) return;

    try {
      setNotificationType("normal");
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(
        coinFlipAddress[chainId],
        coinflipAbi,
        signer
      );
      const transaction = await contract.CoinFlip_Play(
        amount * decimals,
        erc20Address[chainId],
        true,
        0,
        "7237005577332262213973186563042994240829374041602535252466099000494570602496",
        "7237005577332262213973186563042994240829374041602535252466099000494570602496"
      );
      await transaction.wait();
      window.location.reload();
    } catch (error) {
      handleError(error);
    }
  }


  const withdrawShares = async() => {
    // Check not connected and wrong chain.
    if (!account) return;
    if (!bankrollAddress[chainId]) return;
    if (!erc20Address[chainId]) return;

    try {
      setNotificationType("normal");
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(
            bankrollAddress[chainId],
            bankrollAbi,
            signer
        );
        const transaction = await contract.withdrawShares(amount * decimals, erc20Address[chainId]);
        await transaction.wait();
        window.location.reload();
    } catch(e) {
      handleError(e);
    }
  }

  const depositButton = () => {
    if (Number(amount * decimals) <= Number(allowance)) 
    return (<button className="ProfilePendingRequests_refund_button__DHc47" onClick={investmentBanking}>
        Nạp USDT{usdtIcon}
    </button>);
    else return (<button className="ProfilePendingRequests_refund_button__DHc47" style={{color:"yellow"}} onClick={approveErc20}>
        Approve USDT{usdtIcon}
    </button>);
  }

  const refferal = () => {
    if (ref === "0x0000000000000000000000000000000000000000")
      return (
        <div className="ProfileStatistics_stat__7280y">
          <div>Ví giới thiệu</div>
          <div className="ProfileStatistics_value__8jh8L">
            <input
              className="ProfileSearch_input__hOi89"
              type="text"
              placeholder="Nhập địa chỉ ví ..."
              onChange={handleRef}
              value={inputRef}
            ></input>
          </div>
          <button className="ProfilePendingRequests_refund_button__DHc47" onClick={addRefToContract}>
            Thêm
          </button>
        </div>
      );
    else
      return (
        <div className="ProfileStatistics_stat__7280y">
          <div>Ví giới thiệu</div> 
          <div className="ProfileStatistics_value__8jh8L"><BrowserView>{ref}</BrowserView><MobileView>{ref.slice(0, 8)}</MobileView></div>
        </div>
      );
  };

  const approveErc20 = async () => {
    if (erc20Address[chainId] && account) {
      setNotificationType("normal");
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(
        erc20Address[chainId],
        erc20Abi,
        provider
      );
      const contractWithSigner = contract.connect(signer);
      const value = (amount > 100) ? amount : '100';
      const tx = contractWithSigner
        .approve(coinFlipAddress[chainId], value * decimals)
        .then(() => {
          setNotificationType("");
          window.location.reload();
        })
        .catch((e) => {
          handleError(e);
        });
    }
  };

  useEffect(() => {
    if (!account) return;
    if (!bankrollAddress[chainId]) return;
    if (!erc20Address[chainId]) return;
    const provider = new ethers.BrowserProvider(window.ethereum);
    const bankrollReader = new ethers.Contract(
      bankrollAddress[chainId],
      bankrollAbi,
      provider
    );
    const erc20Reader = new ethers.Contract(
        erc20Address[chainId],
        erc20Abi,
        provider
    );

    const init = async () => {
      bankrollReader
        .poolLiquidityOf(erc20Address[chainId])
        .then((result) => setBankrollBalance(Number(result).toString()))
        .catch((e) => handleError(e));

      bankrollReader
        .getCurrentlyHold(account, erc20Address[chainId])
        .then((result) => setShares(Number(result).toString()))
        .catch((e) => handleError(e));

      bankrollReader
        .getRef(account)
        .then((result) => setRef(result))
        .catch((e) => handleError(e)); //(address playerAddress, address tokenAddress

      bankrollReader
        .getWagers(account, erc20Address[chainId])
        .then((result) => setWagers(Number(result).toString()))
        .catch((e) => handleError(e));

      bankrollReader
        .getReward(account, erc20Address[chainId])
        .then((result) => setReward(Number(result).toString()))
        .catch((e) => handleError(e));
    
        const allowanceAmount = await erc20Reader.allowance(
            account,
            coinFlipAddress[chainId]
        );
        setAllowance(Number(allowanceAmount).toString());
        //setAllowance(0);
    };

    init();
    return () => {
      // bankrollAddress.off('CoinFlip_Outcome_Event',()=>{});
    };
  }, [account, chainId]);

  return (
    <>
      <div className="ProfileBanner_container___VtRb">
        <div className="ProfileBanner_overlay__GkWn1"></div>
        <div className="ProfileBanner_content__W4XC9">
          <div className="ProfileBanner_left__1Mlkv">
            <div className="ProfileBanner_profile_img__Rsw_4">
              <div
                className="paper"
                style={{
                  borderRadius: "50px",
                  display: "inline-block",
                  margin: "0px",
                  overflow: "hidden",
                  padding: "0px",
                  backgroundColor: "rgb(3, 61, 93)",
                  height: "100px",
                  width: "100px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0"
                  y="0"
                  height="100"
                  width="100"
                >
                  <rect
                    x="0"
                    y="0"
                    rx="0"
                    ry="0"
                    height="100"
                    width="100"
                    transform="translate(-10.664895867991694 -13.009940361968908) rotate(336.4 50 50)"
                    fill="#fc1932"
                  ></rect>
                  <rect
                    x="0"
                    y="0"
                    rx="0"
                    ry="0"
                    height="100"
                    width="100"
                    transform="translate(37.44052829249325 8.80073201359511) rotate(177.3 50 50)"
                    fill="#f77101"
                  ></rect>
                  <rect
                    x="0"
                    y="0"
                    rx="0"
                    ry="0"
                    height="100"
                    width="100"
                    transform="translate(-75.92297726482232 30.029806873602208) rotate(274.5 50 50)"
                    fill="#243fe1"
                  ></rect>
                  <rect
                    x="0"
                    y="0"
                    rx="0"
                    ry="0"
                    height="100"
                    width="100"
                    transform="translate(22.023246323868772 -105.07049079255252) rotate(339.0 50 50)"
                    fill="#156cf2"
                  ></rect>
                </svg>
              </div>
            </div>
          </div>
          <div>
            <div className="ProfileBanner_username__6GO9A"></div>
            <div className="ProfileBanner_address__26cYx">
              {account || (
                <button
                  className="ConnectButton_container__7wq8q"
                  onClick={connectMetaMask}
                >
                  Connect Wallet
                </button>
              )}
            </div>
            <div className="ProfileBanner_join_date__f6De9">
              Đã đặt cược: {formatUsdt(wagers)} USDT
            </div>
          </div>
        </div>
      </div>
      <div className="ProfileStatistics_container__1sfGL">
        <div className="ProfileStatistics_title___iqkx">
          Thống kê ví cá nhân
        </div>
        <div>
            {refferal()}
          <div className="ProfileStatistics_stat__7280y">
            <div>Tiền thưởng</div>
            <div
              className="ProfileStatistics_value__8jh8L"
              style={{ color: "lime" }}
            >
              {formatUsdt(reward)}
            </div>
            <button className="ProfilePendingRequests_refund_button__DHc47" onClick={withdrawReward}>
                Rút USDT{usdtIcon}
            </button>
          </div>
        </div>
      </div>
      <div className="ProfileStatistics_container__1sfGL">
        <div className="ProfileStatistics_title___iqkx">Ngân hàng nhà cái</div>
        <div>
          <div className="ProfileStatistics_stat__7280y">
            <div>Ngân hàng nhà cái</div>
            <div
              className="ProfileStatistics_value__8jh8L"
              style={{ color: "lime" }}
            >
              {formatUsdt(bankrollBalance)}{usdtIcon}
            </div>
          </div>
          <div className="ProfileStatistics_stat__7280y">
            <div>Cổ phần của bạn</div>
            <div className="ProfileStatistics_value__8jh8L">
              {formatUsdt(shares)}{usdtIcon} - {" "}
              {((shares / bankrollBalance) * 100).toFixed(2)}%
            </div>
          </div>
          <div className="ProfileStatistics_stat__7280y">
            <BrowserView>
              <div>Nạp/Rút cổ phần</div>
            </BrowserView>
            <div className="ProfileStatistics_value__8jh8L">
            <div className="WagerInput_inputs_top__w_nKi">
            <input
                className="Share_number_input"
                type="number"
                inputMode="numeric"
                placeholder="số USDT"
                onChange={handleAmount}
                value={amount || ""}
              />
              <div className="WagerInput_token_icon__QwSuK">{usdtIcon}</div>
            </div>
              
            </div>
                {depositButton()}
            <button className="ProfilePendingRequests_refund_button__DHc47" onClick={withdrawShares}>
              Rút USDT{usdtIcon}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
