/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState, useEffect } from "react";
import { ethers } from "ethers";
import axios from "axios";
import cheerio from "cheerio";


import { MobileView } from 'react-device-detect';
import { MobileMenu } from "../components/MobileMenu";
import { BottomMenu } from "../components/BottomMenu";


import { WalletContext } from "../hooks/WalletContext";
import { InputContainer } from "../components/InputContainer";
import { RockPaperScissorsDisplay } from "../components/RockPaperScissorsDisplay";
import { RockPaperScissorsDescription } from "../components/static/RockPaperScissorsDescription";
import { GameFeed } from "../components/GameFeed";
import { MoreGame } from "../components/MoreGame";
import { Footer } from "../components/static/Footer";
import { listenToEventWithExponentialBackoff, timestamptoTime, time } from "../hooks/function";

import { rockPaperScissorsAddress, erc20Address } from "../components/static/contractAddress";
import { rpcProviderUrl } from "../components/static/rpcProviderUrl";

import json from "../abi/buakeogiay.json";
import erc20 from "../abi/erc20.json";

export function RockPaperScissors() {
  const { account, chainId, setNotificationType, leftSideClose, isWaitingVrf, setWaitingVrf, round, setRound, handleError } =
    useContext(WalletContext);

  const RockPaperScissorsAbi = json.abi;
  const erc20Abi = erc20.abi;

  const multiplier = 1.98;
  const decimals = 10 ** 6;
  const [allowance, setAllowance] = useState(0);

  // actions selected: 0->Rock, 1-> Paper, 2->Scissors
  const [action, setAction] = useState(0);
  const [gameState, setGameState] = useState(0); // 0: use chosing, 1: show result  |  + WaitingVRF.
  const [lastOutCome, setLastOutCome] = useState(0);
  const [histories, setHistories] = useState([]);
  const [gameFeedData, setGameFeedData] = useState([]);
  const fetchedDataFlag = React.useRef(false); 

  const setGame = (value) => {
    setAction(value);
    setGameState(0);
  }

  const closeStyle = () => {
    if (leftSideClose) return "50px";
    else return "";
  };

  const updateAllowance = async () => {
    const provider = chainId
      ? new ethers.JsonRpcProvider(rpcProviderUrl[chainId][8])
      : null;

    const erc20Contract = erc20Address[chainId]
      ? new ethers.Contract(erc20Address[chainId], erc20Abi, provider)
      : null;

    if (erc20Contract && rockPaperScissorsAddress[chainId]) {
      const allowanceAmount = await erc20Contract.allowance(
        account,
        rockPaperScissorsAddress[chainId]
      );
      setAllowance(Number(allowanceAmount));
    }
  }

  const approveErc20 = async (amount) => {
    setNotificationType("normal");
    const provider =  new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const erc20Contract = erc20Address[chainId]
      ? new ethers.Contract(erc20Address[chainId], erc20Abi, signer)
      : null;

    if (erc20Contract) {
      const tx = erc20Contract
      .approve(rockPaperScissorsAddress[chainId], Math.floor(Number(amount * decimals)))
      .then(() => {
        updateAllowance();
        setNotificationType("");
      })
      .catch((e) => {
        handleError(e);
      });
    }
  }

  useEffect(() => {
    updateAllowance();
    window.scrollTo(0, 0);
    setRound(0);

    /*if (!fetchedDataFlag.current && gameFeedData.length === 0) {
      fetchedDataFlag.current = true;

      // Update Arbitrum First.
      const fetchData = async () => {
        const ArbitrumOneUrl =
        "https://arbitrumone.uytin.io/address/" + rockPaperScissorsAddress[42161] + "#tokentxns";
        try {
          const response = await axios.get(ArbitrumOneUrl);
          const htmlContent = response.data;

          const tbodyRegex =
            /<tbody class="align-middle text-nowrap">([\s\S]*?)<\/tbody>/g;
          const tbodyMatch = tbodyRegex.exec(htmlContent);

          if (tbodyMatch && tbodyMatch[1]) {
            const tbodyContent = tbodyMatch[1];
            const hrefRegex =
              /<a class='hash-tag text-truncate myFnExpandBox_searchVal'[^>]*href='([^']+)'/g;
            let matches;
            const urls = [];
            while (
              (matches = hrefRegex.exec(tbodyContent)) !== null &&
              urls.length < 5
            ) {
              urls.push("https://arbitrumone.uytin.io" + matches[1]);
            }
            for (let i = 0; i < urls.length; i++) {
              axios
                .get(urls[i])
                .then((response) => {
                  const htmlString = response.data;
                  const $ = cheerio.load(htmlString);

                  const spanElement = $("#showUtcLocalDate");
                  const dataTimestamp = spanElement.attr("data-timestamp");

                  const regexA =
                    /<a[^>]*data-highlight-target="([^"]*)"[^>]*>/g;
                  const matchesA = [];
                  let matchA;
                  while ((matchA = regexA.exec(htmlString)) !== null) {
                    matchesA.push(matchA[1]);
                  }

                  const regexSpan = /<span class='me-1'[^>]*>(.*?)<\/span>/g;
                  const matchesSpan = [];
                  let matchSpan;
                  while ((matchSpan = regexSpan.exec(htmlString)) !== null) {
                    matchesSpan.push(matchSpan[1]);
                  }
                  // console.log(matchesSpan[matchesSpan.length-1]);

                  if (matchesA.length > 1 && matchesSpan.length > 0) {
                    // console.log(gameFeedData.length);
                    if (matchesA[0] !== matchesA[1]) {
                      const newFeed = {
                        chainId: 42161,
                        time: timestamptoTime(dataTimestamp),
                        game: "Oẳn Tù Tì",
                        slash: "/oantuti",
                        player: matchesA[0],
                        wager: Number(matchesSpan[matchesSpan.length-1] * 1000000),
                        multiplier: "+-",
                        profit: "-",
                      };
                      setGameFeedData((gameFeedData) => [newFeed, ...gameFeedData]);
                    } // else console.log("DEPOSIT BANKROLL, DON'T ADD TO FEED");
                  }
                })
                .catch((error) => {
                  console.error("There was an error fetching the data!", error);
                });
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    } */
    
    const eventSepoliaListener = ( playerAddress, wager, payout, tokenAddress, outcomes, randomActions, payouts, numGames) => {
      if (account) if (account.toUpperCase() === playerAddress.toUpperCase()) {
        for (let i = 0; i < Number(numGames); i++) {
          setHistories(histories => [...histories, Number(payouts[i])/Number(wager)]);
        }
        setLastOutCome(randomActions[Number(numGames) - 1]);
        setWaitingVrf(false);
        setNotificationType("");
        const roundNotBets = Number(payouts.length) - Number(numGames);
        setRound((round) => round-roundNotBets);
        setGameState(1);
      }
      const wagers = Number(wager) * Number(payouts.length);
      const multiplierTemp = Number(payout)/wagers;
      const profit = Number(payout) - wagers;

      const newFeed = {
        'chainId': 421614, 'time': time(), 'game': "Oẳn Tù Tì", 'slash': '/oantuti',
        'player': playerAddress, 'wager': wagers, 'multiplier': multiplierTemp.toFixed(2), 'profit': profit
      };
      setGameFeedData(gameFeedData => [newFeed, ...gameFeedData]);
    } 
    const providerSepolia = new ethers.JsonRpcProvider(rpcProviderUrl[421614][8]);
    const contractSepolia = rockPaperScissorsAddress[421614] 
                    ? new ethers.Contract(rockPaperScissorsAddress[421614], RockPaperScissorsAbi, providerSepolia)
                    : null;

    const initSepolia = async () => {
      try {
        await listenToEventWithExponentialBackoff(
          contractSepolia,
          "RockPaperScissors_Outcome_Event",
          5,
          16000,
          eventSepoliaListener
        );
      }catch(e) {
        handleError(e);
      }
    };
    if (contractSepolia) initSepolia();

    const eventPolygonListener = ( playerAddress, wager, payout, tokenAddress, outcomes, randomActions, payouts, numGames) => {
      if (account) if (account.toUpperCase() === playerAddress.toUpperCase()) {
        for (let i = 0; i < Number(numGames); i++) {
          setHistories(histories => [...histories, Number(payouts[i])/Number(wager)]);
        }
        setLastOutCome(randomActions[Number(numGames) - 1]);
        setWaitingVrf(false);
        setNotificationType("");
        const roundNotBets = Number(payouts.length) - Number(numGames);
        setRound((round) => round-roundNotBets);
        setGameState(1);
      }
      const wagers = Number(wager) * Number(payouts.length);
      const multiplierTemp = Number(payout)/wagers;
      const profit = Number(payout) - wagers;

      const newFeed = {
        'chainId': 137, 'time': time(), 'game': "Oẳn Tù Tì", 'slash': '/oantuti',
        'player': playerAddress, 'wager': wagers, 'multiplier': multiplierTemp.toFixed(2), 'profit': profit
      };
      setGameFeedData(gameFeedData => [newFeed, ...gameFeedData]);
    } 
    const providerPolygon = new ethers.JsonRpcProvider(rpcProviderUrl[137][8]);
    const contractPolygon = rockPaperScissorsAddress[137] 
                    ? new ethers.Contract(rockPaperScissorsAddress[137], RockPaperScissorsAbi, providerPolygon)
                    : null;

    const initPolygon = async () => {
      try {
        await listenToEventWithExponentialBackoff(
          contractPolygon,
          "RockPaperScissors_Outcome_Event",
          5,
          16000,
          eventPolygonListener
        );
      }catch(e) {
        handleError(e);
      }
    };
    if (contractPolygon) initPolygon();

    const eventArbitrumListener = ( playerAddress, wager, payout, tokenAddress, outcomes, randomActions, payouts, numGames) => {
      if (account) if (account.toUpperCase() === playerAddress.toUpperCase()) {
        for (let i = 0; i < Number(numGames); i++) {
          setHistories(histories => [...histories, Number(payouts[i])/Number(wager)]);
        }
        setLastOutCome(randomActions[Number(numGames) - 1]);
        setWaitingVrf(false);
        setNotificationType("");
        const roundNotBets = Number(payouts.length) - Number(numGames);
        setRound((round) => round-roundNotBets);
        setGameState(1);
      }
      const wagers = Number(wager) * Number(payouts.length);
      const multiplierTemp = Number(payout)/wagers;
      const profit = Number(payout) - wagers;

      const newFeed = {
        'chainId': 42161, 'time': time(), 'game': "Oẳn Tù Tì", 'slash': '/oantuti',
        'player': playerAddress, 'wager': wagers, 'multiplier': multiplierTemp.toFixed(2), 'profit': profit
      };
      setGameFeedData(gameFeedData => [newFeed, ...gameFeedData]);
    } 
    const providerArbitrum = new ethers.JsonRpcProvider(rpcProviderUrl[42161][8]);
    const contractArbitrum = rockPaperScissorsAddress[42161] 
                    ? new ethers.Contract(rockPaperScissorsAddress[42161], RockPaperScissorsAbi, providerArbitrum)
                    : null;

    const initArbitrum = async () => {
      try {
        await listenToEventWithExponentialBackoff(
          contractArbitrum,
          "RockPaperScissors_Outcome_Event",
          5,
          16000,
          eventArbitrumListener
        );
      }catch(e) {
        handleError(e);
      }
    };
    if (contractArbitrum) initArbitrum();


    // STOP error loop playing -- unknown why have this error
    const providerFix = chainId
      ? new ethers.JsonRpcProvider(rpcProviderUrl[chainId][8])
      : null;
    const contractFix = rockPaperScissorsAddress[chainId] && providerFix
                    ? new ethers.Contract(rockPaperScissorsAddress[chainId], RockPaperScissorsAbi, providerFix)
                    : null;

    const intervalId = contractFix
      ? setInterval(() => {
        // Fix metamask send two or more transaction in one time use clicked.
        if (round < histories.length) setRound(histories.length);
        if (isWaitingVrf) {
          contractFix.RockPaperScissors_GetState(account).then((RockPaperScissorsGame) => {
            if (Number(RockPaperScissorsGame.wager) === 0) setWaitingVrf(false);
          });
        }
        }, 1000)
      : null;

    // get game state when mount component.
    if (contractFix) {
      contractFix.RockPaperScissors_GetState(account).then((RockPaperScissorsGame) => {
        if (Number(RockPaperScissorsGame.wager) !== 0) setWaitingVrf(true);
      });
    }
    

    return () => {
      if (contractFix !== null ) {
        clearInterval(intervalId);
      }
      if (contractSepolia !== null ) {
        contractSepolia.off('RockPaperScissors_Outcome_Event', eventSepoliaListener);
      }
      if (contractPolygon !== null ) {
        contractPolygon.off('RockPaperScissors_Outcome_Event', eventPolygonListener);
      }
      if (contractArbitrum !== null ) {
        contractArbitrum.off('RockPaperScissors_Outcome_Event', eventArbitrumListener);
      }
    };
  }, [chainId, account]);

  const sendTransaction = async (wagerToContract, bets, _stopLoss, _stopGain) => {
    try {
      if (!rockPaperScissorsAddress[chainId]) return;
      setGameState(0);
      setNotificationType("normal");
      setRound((round) => round+Math.floor(bets));
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const rockPaperScissorsContract = new ethers.Contract(
        rockPaperScissorsAddress[chainId],
        RockPaperScissorsAbi,
        signer
      );
    
      const transaction = await rockPaperScissorsContract.RockPaperScissors_Play(
        Math.floor(wagerToContract),
        erc20Address[chainId],
        action,
        Math.floor(bets),
        _stopGain.toString(),
        _stopLoss.toString(),
      ).then(() => {
        setNotificationType("");
        setWaitingVrf(true)
        updateAllowance();
      });
    }catch (error) {
      handleError(error);
      setWaitingVrf(false);
      setRound((round) => round-Math.floor(bets));
      
    }
  };

  return (
    <div
      className="Layout_middle_container__rQzvK"
      style={{ "--left_margin": closeStyle() }}
    >
      <main className="Layout_content__3KYZT">
        <div className="GameWrapper_game_container___djZh">
          <div className="Dice_container___ID90">
            <InputContainer
              multiplier={multiplier}
              sendTransaction={sendTransaction}
              allowance={allowance}
              approveErc20={approveErc20}
            />
            <RockPaperScissorsDisplay action={action} setGame={setGame} histories={histories} randomActions={lastOutCome} gameState={gameState}/>
          </div>
          <RockPaperScissorsDescription />
        </div>
        <GameFeed gameFeedData={gameFeedData}/>
        <MoreGame />
        <MobileView>
          <MobileMenu/>
          <BottomMenu/>
        </MobileView>
        <Footer />
      </main>
    </div>
  );
}