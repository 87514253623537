/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState, useEffect } from "react";
import { ethers } from "ethers";
import { MobileView } from "react-device-detect";
import { MobileMenu } from "../components/MobileMenu";
import { BottomMenu } from "../components/BottomMenu";

import { WalletContext } from "../hooks/WalletContext";
import { MultiPlayerInputContainer } from "../components/MultiPlayerInputContainer";
import { XocDiaDisplay } from "../components/XocDiaDisplay";
import { XocDiaDescription } from "../components/static/XocDiaDescription";
import { GameFeedMultiplayer } from "../components/GameFeedMultiplayer";
import { MoreGame } from "../components/MoreGame";
import { Footer } from "../components/static/Footer";

import { listenToEventWithExponentialBackoff } from "../hooks/function";

import { rpcProviderUrl } from "../components/static/rpcProviderUrl";
import xocdia from "../abi/xocdia.json";
import {
  xocDiaAddress,
  erc20Address,
} from "../components/static/contractAddress";

import erc20 from "../abi/erc20.json";

export function XocDia() {
  const {
    account,
    chainId,
    setNotificationType,
    leftSideClose,
    handleError,
  } = useContext(WalletContext);

  const erc20Abi = erc20.abi;
  const xocDiaAbi = xocdia.abi;
  const decimals = 10 ** 6;

  const [isOdd, setIsOdd] = useState(0);
  const [allowance, setAllowance] = useState(0);
  const [balance, setBalance] = useState(0);

  const [gameFeedData, setGameFeedMultiplayerData] = useState([]);

  const closeStyle = () => {
    if (leftSideClose) return "50px";
    else return "";
  };

  const time = () => {
    const temp = new Date();
    const hours =
      temp.getHours() < 10 ? "0" + temp.getHours() : temp.getHours();
    const minutes =
      temp.getMinutes() < 10 ? "0" + temp.getMinutes() : temp.getMinutes();
    return hours + ":" + minutes;
  };

  const approveErc20 = async (amount) => {
    // console.log("approveErc20: ", amount);
    setNotificationType("normal");
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const erc20Contract = erc20Address[chainId]
      ? new ethers.Contract(erc20Address[chainId], erc20Abi, signer)
      : null;

    if (erc20Contract) {
      const tx = erc20Contract
        .approve(xocDiaAddress[chainId], Math.floor(Number(amount * decimals)))
        .then(() => {
          updateAllowance();
          setNotificationType("");
        })
        .catch((e) => {
          handleError(e);
        });
    }
  };
  const depositTransaction = async (amount) => {
    // updateBalance depositToGame(address tokenAddress, uint256 amount)
    if (!window.ethereum) return;
    if (!xocDiaAddress[chainId]) return;

    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const xocDiaContract = new ethers.Contract(
        xocDiaAddress[chainId],
        xocDiaAbi,
        signer
      );
      const transaction = xocDiaContract
        .depositToGame(erc20Address[chainId], amount * decimals)
        .then(() => {
          updateBalance();
          updateAllowance();
        })
        .catch((e) => {
          handleError(e);
        });
    } catch (error) {
      handleError(error);
    }
  };
  const withdrawTransaction = async () => {
    // updateBalance  withdrawAll
    if (!window.ethereum) return;
    if (!xocDiaAddress[chainId]) return;

    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const xocDiaContract = new ethers.Contract(
        xocDiaAddress[chainId],
        xocDiaAbi,
        signer
      );
      const transaction = xocDiaContract
        .withdrawAll(erc20Address[chainId])
        .then(() => {
          updateBalance();
        })
        .catch((e) => {
          handleError(e);
        });
    } catch (error) {
      handleError(error);
    }
  };
  const playTransaction = async (wagerToContract) => {
    if (!window.ethereum) return;
    if (!xocDiaAddress[chainId]) return;
    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const xocDiaContract = new ethers.Contract(
        xocDiaAddress[chainId],
        xocDiaAbi,
        signer
      );
      // console.log(wagerToContract * decimals, erc20Address[chainId], isOdd);

      const transaction = xocDiaContract
        .XocDia_Play(wagerToContract * decimals, erc20Address[chainId], isOdd)
        .then(() => {
          updateBalance();
        })
        .catch((e) => {
          handleError(e);
        });
    } catch (error) {
      handleError(error);
    }
  };

  const updateAllowance = async () => {
    const provider = new ethers.JsonRpcProvider(rpcProviderUrl[chainId][4]);
    const erc20Contract = new ethers.Contract(
      erc20Address[chainId],
      erc20Abi,
      provider
    );
    const allowanceAmount = await erc20Contract.allowance(
      account,
      xocDiaAddress[chainId]
    );
    setAllowance(Number(allowanceAmount));
  };

  const updateBalance = async () => {
    const provider = new ethers.JsonRpcProvider(rpcProviderUrl[chainId][4]);
    const xocDiaContract = new ethers.Contract(
      xocDiaAddress[chainId],
      xocDiaAbi,
      provider
    );

    const getBalance = xocDiaContract
      .getBalance(erc20Address[chainId], account)
      .then((balance) => {
        setBalance(Number(balance));
      })
      .catch((error) => handleError(error));
  };

  useEffect(() => {
    if (!account) return;
    if (!rpcProviderUrl[chainId]) return;
    if (!xocDiaAddress[chainId]) return;
    if (!erc20Address[chainId]) return;

    updateBalance();
    updateAllowance();

    const provider = new ethers.JsonRpcProvider(rpcProviderUrl[chainId][4]);
    const xocDiaContract = new ethers.Contract(xocDiaAddress[chainId], xocDiaAbi, provider);

    const XocDia_Feed_Listener = (playerAddress, wager, tokenAddress, isOdd) => {
      // console.log(playerAddress, wager, tokenAddress, isOdd);
      const wagers = Number(wager);
      const side = isOdd ? "Lẻ" : "Chẵn";

      const newFeed = { chainId: chainId, time: time(), game: "Xóc Đĩa", slash: "/xocdia",
        player: playerAddress, wager: wagers, side: side,
      };
      // console.log(newFeed);
      setGameFeedMultiplayerData((gameFeedData) => [newFeed, ...gameFeedData]);
    };

    const startListening = async () => {
      try {
        await listenToEventWithExponentialBackoff(
          xocDiaContract,
          "XocDia_Play_EVENT",
          5,
          16000,
          XocDia_Feed_Listener
        );
      } catch (e) {
        handleError(e);
      }
    };
    if (xocDiaContract) startListening();

    return () => {
      xocDiaContract.off("XocDia_Play_EVENT", XocDia_Feed_Listener);
    };
  }, [account, chainId]);

  return (
    <div
      className="Layout_middle_container__rQzvK"
      style={{ "--left_margin": closeStyle() }}
    >
      <main className="Layout_content__3KYZT">
        <div className="GameWrapper_game_container___djZh">
          <div className="Dice_container___ID90">
            <MultiPlayerInputContainer
              approveErc20={approveErc20}
              playTransaction={playTransaction}
              depositTransaction={depositTransaction}
              withdrawTransaction={withdrawTransaction}
              allowance={allowance}
              balance={balance}
            />
            <XocDiaDisplay setIsOdd={setIsOdd} updateBalance={updateBalance} />
          </div>
          <XocDiaDescription />
        </div>
        <GameFeedMultiplayer gameFeedData={gameFeedData} />
        <MoreGame />
        <MobileView>
          <MobileMenu />
          <BottomMenu />
        </MobileView>
        <Footer />
      </main>
    </div>
  );
}
