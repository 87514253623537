/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState, useEffect, useRef } from "react";
import { ethers, formatUnits } from "ethers";

import { WalletContext } from "../hooks/WalletContext";
import { usdtIcon } from "./static/svg";

import { XocDiaHistories } from "./XocDiaHistories";
import { Bankroll } from "./Bankroll";

import { rpcProviderUrl } from "./static/rpcProviderUrl";
import xocdia from "../abi/xocdia.json";
import { xocDiaAddress } from "./static/contractAddress";
import { listenToEventWithExponentialBackoff } from "../hooks/function";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const FormatBalance = (_balance) => {
  return parseFloat(formatUnits(_balance, 6)).toLocaleString("en", {
    minimumFractionDigits: 0,
  });
};

export function XocDiaDisplay({ setIsOdd, updateBalance }) {
  const { round, isWaitingVrf, handleError, account, chainId } =
    useContext(WalletContext);

  const xocDiaAbi = xocdia.abi;

  const [select, SetSelect] = useState(0);

  const [results, setResultArray] = useState([]);

  const [lastId, setId] = useState(0);
  const [countdown, setCountdown] = useState(0);
  const [le, setLe] = useState(0);
  const [chan, setChan] = useState(0);
  const [bankrollAccepted, setBankrollAccepted] = useState(0);
  const [status, setStatus] = useState(0);
  const [lePlayers, setLePlayers] = useState(0);
  const [chanPlayers, setChanPlayers] = useState(0);
  const [leTotal, setLeTotal] = useState(0);
  const [chanTotal, setChanTotal] = useState(0);
  const [result, setResult] = useState([1, 0, 0, 1]);
  const [preChainId, setPreChainId] = useState(0);

  const playChoise = (value) => {
    SetSelect(value);
    setIsOdd(value);
  };

  useEffect(() => {

    if (!account) return;
    if (!rpcProviderUrl[chainId]) return;
    if (!xocDiaAddress[chainId]) return;
    if (preChainId != chainId) {
      setPreChainId(chainId);
      setResultArray([]);
    }

    const provider = new ethers.JsonRpcProvider(rpcProviderUrl[chainId][0]);
    const xocDiaContract = new ethers.Contract(xocDiaAddress[chainId], xocDiaAbi, provider);

    const provider1 = new ethers.JsonRpcProvider(rpcProviderUrl[chainId][1]);
    const xocDiaContract1 = new ethers.Contract(xocDiaAddress[chainId], xocDiaAbi, provider1);

    const provider2 = new ethers.JsonRpcProvider(rpcProviderUrl[chainId][2]);
    const xocDiaContract2 = new ethers.Contract(xocDiaAddress[chainId], xocDiaAbi, provider2);

    const provider3 = new ethers.JsonRpcProvider(rpcProviderUrl[chainId][3]);
    const xocDiaContract3 = new ethers.Contract(xocDiaAddress[chainId], xocDiaAbi, provider3);

    const provider4 = new ethers.JsonRpcProvider(rpcProviderUrl[chainId][4]);
    const xocDiaContract4 = new ethers.Contract(xocDiaAddress[chainId], xocDiaAbi, provider4);

    if (!xocDiaContract) return;

    const initData = xocDiaContract.getTurn().then((result) => {
      setId(Number(result[1]));
      setStatus(Number(result[0][2]));
      setLe(Number(result[0][3]));
      setChan(Number(result[0][4]));
      setBankrollAccepted(Number(result[0][5]));
      if (Number(result[2]) - Number(result[0][1]) >= 60) setCountdown(0);
      else setCountdown(60 - (Number(result[2]) - Number(result[0][1])));
      setLePlayers(Number(result[3]));
      setChanPlayers(Number(result[4]));

      if (account) {
        xocDiaContract.XocDia_GetState(account).then(( results) => {
          setLeTotal(Number(results[0]));
          setChanTotal(Number(results[1]));
        })
      }

      if (results.length === 0)
        if (lastId > 10) {
          let histories = [];
          for (let i = lastId - 10; i < lastId; i++) {
            xocDiaContract.getResult(i).then((_result) => {
              // console.log(i, _result);
              setResultArray((results) => [
                ...results,
                {
                  quan1: _result[0],
                  quan2: _result[1],
                  quan3: _result[2],
                  quan4: _result[3],
                },
              ]);
            });
          }
        } else {
          for (let i = 1; i < lastId; i++) {
            xocDiaContract.getResult(i).then((_result) => {
              // console.log(i, _result);
              setResultArray((results) => [...results, _result]);
            });
          }
        }
    });
    const TaiXiu_Round_Listener = (
      _id,
      _le,
      _chan,
      _countdown,
      _lePlayers,
      _chanPlayers
    ) => {
      setId(Number(_id));
      setLe(Number(_le));
      setChan(Number(_chan));
      setCountdown((countdown) => Number(_countdown));
      setStatus(1);
      setLePlayers(Number(_lePlayers));
      setChanPlayers(Number(_chanPlayers));
      // console.log(lastId, le, chan, countdown);
    };
    const XocDia_Take_Balance_Listener = (_le, _chan, _bankrollAccepted) => {
      setLe(Number(_le));
      setChan(Number(_chan));
      setBankrollAccepted(Number(_bankrollAccepted));
      if (status === 1 || status === 0) {
        setStatus(2);
      }
      if (account)
        xocDiaContract.TaiXiu_GetState(account).then((result) => {
          setLeTotal(Number(result[0]));
          setChanTotal(Number(result[1]));
        });
    };
    const XocDia_Play_Listener = (
      _playerAddress,
      _wager,
      _tokenAddress,
      _isBig
    ) => {
      // console.log(_playerAddress, _wager, _tokenAddress, _isBig);
      if (account)
        if (account.toUpperCase() === _playerAddress.toUpperCase()) {
          if (_isBig) setLeTotal(Number(_wager));
          else setChanTotal(Number(_wager));
        }
    };
    const XocDia_VRF_Request_Listener = (
      result1,
      result2,
      result3,
      result4
    ) => {
      setStatus(3);
      setResult([
        Number(result1),
        Number(result2),
        Number(result3),
        Number(result4),
      ]);
      setResultArray((results) => [
        ...results,
        {
          quan1: Number(result1),
          quan2: Number(result2),
          quan3: Number(result3),
          quan4: Number(result4),
        },
      ]);
    };
    const RoundisOpenNow_Listener = () => {
      setStatus(0);
      setLe(0);
      setChan(0);
      setBankrollAccepted(0);
      setLeTotal(0);
      setChanTotal(0);
      setId((lastId) => lastId + 1);
      updateBalance();
    };

    const startListening = async () => {
      try {
        await listenToEventWithExponentialBackoff(
          xocDiaContract1,
          "XocDia_Round_INFO_Event",
          5,
          16000,
          TaiXiu_Round_Listener
        );
        await listenToEventWithExponentialBackoff(
          xocDiaContract2,
          "XocDia_Take_Balance_EVENT",
          5,
          16000,
          XocDia_Take_Balance_Listener
        );
        await listenToEventWithExponentialBackoff(
          xocDiaContract,
          "XocDia_VRF_Request_EVENT",
          5,
          16000,
          XocDia_VRF_Request_Listener
        );
        await listenToEventWithExponentialBackoff(
          xocDiaContract3,
          "XocDia_Play_EVENT",
          5,
          16000,
          XocDia_Play_Listener
        );
        await listenToEventWithExponentialBackoff(
          xocDiaContract4,
          "RoundisOpenNow_EVENT",
          5,
          16000,
          RoundisOpenNow_Listener
        );
      } catch (e) {
        handleError(e);
      }
    };

    startListening();

    return () => {
      xocDiaContract1.off("XocDia_Round_INFO_Event", TaiXiu_Round_Listener);
      xocDiaContract2.off(
        "XocDia_Take_Balance_EVENT",
        XocDia_Take_Balance_Listener
      );
      xocDiaContract.off(
        "XocDia_VRF_Request_EVENT",
        XocDia_VRF_Request_Listener
      );
      xocDiaContract3.off("XocDia_Play_EVENT", XocDia_Play_Listener);
      xocDiaContract4.off("RoundisOpenNow_EVENT", RoundisOpenNow_Listener);
    };
  }, [chainId, lastId, account]);

  useInterval(() => {
    if (countdown > 0) setCountdown((countdown) => countdown - 1);
  }, 1000);

  const colorClass = (value) => {
    if (value === 1) return "quantrang";
    else return "quanden";
  }; // result

  const displayBowl = () => {
    // bowl_baucua // xocdia_result
    if (countdown !== 0)
      return (
        <>
          <div className="bowl_baucua"></div>
          <div className="TaiXiu_Countdown">ĐÓNG CƯỢC SAU {countdown} GIÂY</div>
        </>
      );
    else if (status === 1)
      return (
        <>
          <div className="xocdia_result"></div>
          <div className="TaiXiu_TakeBalance">NHÀ CÁI NHẬN CƯỢC</div>
        </>
      );
    else if (status === 2)
      return (
        <>
          <div className="xocdia_result"></div>
          <div className="TaiXiu_OpenBet">ĐANG CHỜ MỞ BÁT</div>
        </>
      );
    else if (status === 3 || status === 4)
      return (
        <div className="xocdia_result">
          <div className="TaiXiu_PayWinner">ĐANG TRẢ THƯỞNG</div>
          <span
            style={{
              left: "70px",
              top: "155px",
              height: "50px",
              width: "50px",
              borderRadius: "50%",
              position: "absolute",
              // backgroundColor: "black",
            }}
            className={colorClass(result[0])}
          ></span>
          <span
            style={{
              left: "170px",
              top: "155px",
              height: "50px",
              width: "50px",
              borderRadius: "50%",
              position: "absolute",
              // backgroundColor: "pink",
            }}
            className={colorClass(result[1])}
          ></span>
          <span
            style={{
              left: "170px",
              top: "95px",
              height: "50px",
              width: "50px",
              borderRadius: "50%",
              position: "absolute",
              // backgroundColor: "white",
            }}
            className={colorClass(result[2])}
          ></span>
          <span
            style={{
              left: "90px",
              top: "75px",
              height: "50px",
              width: "50px",
              borderRadius: "50%",
              position: "absolute",
              // backgroundColor: "red",
            }}
            className={colorClass(result[3])}
          ></span>
        </div>
      );
    else if (status === 0)
      return (
        <div className="xocdia_result ">
          <div className="TaiXiu_OpenBet">PHIÊN CƯỢC ĐANG MỞ</div>
        </div>
      );
  };
  const leBet = () => {
    if (leTotal) return FormatBalance(leTotal) + " USDT";
    else return "";
  };
  const chanBet = () => {
    if (chanTotal) return FormatBalance(chanTotal) + " USDT";
    else return "";
  };
  const caiNhanLe = () => {
    if (le >= chan) return FormatBalance(bankrollAccepted);
    else return 0;
  };
  const caiNhanChan = () => {
    if (chan >= le) return FormatBalance(bankrollAccepted);
    else return 0;
  };
  const classLe = () => {
    if (select === 1) return "HandSelector_selected__fHW5s";
    else return "";
  };
  const classChan = () => {
    if (select === 0) return "HandSelector_selected__fHW5s";
    else return "";
  };

  return (
    <>
      <div className="Display_container__qSXl5">
        <Bankroll />
        <XocDiaHistories results={results} />
        <div className="Display_above__oCBz5">
          <div className="Coin_shadow__it9xJ"></div>
          <div
            style={{
              "--period": "750s",
              "--diameter": "240px",
              "--thickness": "20px",
            }}
          >
            <div className={"Coin_coin__bKUgo"}>{displayBowl()}</div>
          </div>
        </div>
        <div className="TaiXiu_Id">Lượt: #{lastId}</div>
        <table className="taixiu_history center">
          <tbody>
            <tr>
              <th>CỬA CƯỢC</th>
              <th>NGƯỜI CHƠI</th>
              <th>TỔNG CƯỢC</th>
              <th>NHÀ CÁI CÂN</th>
            </tr>
            <tr>
              <td>LẺ</td>
              <td>{lePlayers}</td>
              <td>
                {FormatBalance(le)}
                {usdtIcon}
              </td>
              <td>
                {caiNhanLe()}
                {usdtIcon}
              </td>
            </tr>
            <tr>
              <td>CHẴN</td>
              <td>{chanPlayers}</td>
              <td>
                {FormatBalance(chan)}
                {usdtIcon}
              </td>
              <td>
                {caiNhanChan()}
                {usdtIcon}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="SpecificInputs_container__OLKbJ">
          <div className="HandSelector_container__jpQkK ">
            <button
              className={classLe()}
              disabled={select === 1}
              onClick={() => playChoise(1)}
            >
              LẺ {leBet()}
            </button>
            <button
              className={classChan()}
              disabled={select === 0}
              onClick={() => playChoise(0)}
            >
              CHẴN {chanBet()}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
