/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState, useEffect } from "react";
import {ethers} from 'ethers';
import {formatUnits} from "ethers";
import { NavLink } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

import axios from "axios";
import cheerio from "cheerio";
import { BrowserView, MobileView } from 'react-device-detect';

import { WalletContext } from "../hooks/WalletContext";
import { listenToEventWithExponentialBackoff, timestamptoTime, time } from "../hooks/function";

import { rpcProviderUrl } from "../components/static/rpcProviderUrl";
import {diceAddress, coinFlipAddress, rockPaperScissorsAddress, bauCuaAddress} from "./static/contractAddress";
import dice from "../abi/dice.json";
import coinflip from "../abi/coinflip.json";
import buakeogiay from "../abi/buakeogiay.json";
import baucua from '../abi/baucua.json';

import { arbitrumIcon, polygonIcon, ethereumIcon, usdtIcon } from "./static/svg";

const formatUsdt = (value) => {
    if (value === "-") return "+-x";
    return parseFloat(formatUnits(value, 6)).toLocaleString("en", {
      minimumFractionDigits: 2,
    });
  };

export function HomeFeed() {
    const { account, handleError } = useContext(WalletContext);
    const diceAbi = dice.abi;
    const coinflipAbi = coinflip.abi;
    const rockPaperScissorsAbi = buakeogiay.abi;
    const baucuaAbi = baucua.abi;
    const [gameFeedData, setGameFeedData] = useState([]);
    // const fetchedDataFlag = React.useRef(false); 

    // Arbitrum (chainId === 42161)    Polygon (chainId === 137)  Arbitrum Sepolia (chainId === 421614(0x66eee))  
    const explorer = {
        42161: "https://arbiscan.io/",
        137: "https://polygonscan.com/",
        421614: "https://sepolia.arbiscan.io/"
    } 

    const networkIcon = (value) => {
        if (value === 42161) return arbitrumIcon;
        if (value === 137) return polygonIcon;
        if (value === 421614) return arbitrumIcon;
        else return ethereumIcon
    }

    const classProfit = (value) => {
        if (value > 0 ) return "GlobalGameFeed_win__KSd5_";
        else return "";
    }

    const classRow = (player) => {
        if (account) {
            if (account.toUpperCase() === player.toUpperCase()) return "GameFeed_player_row__AAVaw";
            else return "";
        } else return "";
    };

    const contractExplorer = (slash, _chainId) => {
        let address = "";
        if (slash === '/tungxu') address = coinFlipAddress[_chainId];
        else if (slash === '/xucxac') address = diceAddress[_chainId];
        else if (slash === '/oantuti') address = rockPaperScissorsAddress[_chainId];
        else if (slash === '/baucua') address = bauCuaAddress[_chainId];
        return address;
    }

    const feed = () => {
        if (gameFeedData.length !== 0)
        return gameFeedData.slice(0, 10).map((data) => (
            <div className={classRow(data.player) + " GlobalGameFeed_row__PhCQS"} key={uuidv4()}>
                <BrowserView>
                <div className="GlobalGameFeed_timestamp__mMI_x">
                    <NavLink to={explorer[data.chainId] + "address/" + contractExplorer(data.slash,data.chainId)} >
                        {data.time}
                        <svg height="1em" viewBox="0 0 18 18"><path fillRule="evenodd" clipRule="evenodd" d="M2 2V16H16V9H18V16C18 17.1 17.1 18 16 18H2C0.89 18 0 17.1 0 16V2C0 0.9 0.89 0 2 0H9V2H2Z"></path><path d="M11 0V2H14.59L4.76 11.83L6.17 13.24L16 3.41V7H18V0H11Z"></path></svg>
                    </NavLink>
                </div>
                </BrowserView>
                <BrowserView>
                    <div className="GlobalGameFeed_game__z6kpc">
                        <NavLink to={data.slash}>
                            <div className="GlobalGameFeed_network_icon__7mxXB">
                                {networkIcon(data.chainId)}
                            </div>
                            {data.game}
                        </NavLink>
                    </div>
                </BrowserView>
                <MobileView>
                    <div className="GlobalGameFeed_game__z6kpc" style={{'minWidth':'120px', 'width':'120px'}}>
                        <NavLink to={data.slash}>
                            <div className="GlobalGameFeed_network_icon__7mxXB">
                                {networkIcon(data.chainId)}
                            </div>
                            {data.game}
                        </NavLink>
                    </div>
                </MobileView>
                <BrowserView>
                    <div className="GlobalGameFeed_player__DUdJR GlobalGameFeed_address__HLDUh " >
                        <a href={explorer[data.chainId] + "address/" + data.player}>
                            <div className="paper" style={{'borderRadius': '50px', 'display': 'inline-block', 'margin': '0px', 'overflow': 'hidden', 'padding': '0px', 'backgroundColor': 'rgb(252, 25, 41)', 'height': '15px', 'width': '15px'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="15" width="15"><rect x="0" y="0" rx="0" ry="0" height="15" width="15" transform="translate(-0.06281948292294663 0.024767708564772433) rotate(216.5 7.5 7.5)" fill="#f1d802"></rect><rect x="0" y="0" rx="0" ry="0" height="15" width="15" transform="translate(7.577561773192083 3.1649802581979065) rotate(161.2 7.5 7.5)" fill="#f77b01"></rect><rect x="0" y="0" rx="0" ry="0" height="15" width="15" transform="translate(7.6331182503584225 -6.494913455115599) rotate(485.4 7.5 7.5)" fill="#e8f300"></rect><rect x="0" y="0" rx="0" ry="0" height="15" width="15" transform="translate(-18.711989763735442 1.3748646815010066) rotate(225.8 7.5 7.5)" fill="#01658c"></rect></svg>
                            </div>
                            {data.player.slice(0, 8)}
                        </a>
                    </div>
                </BrowserView>
                <MobileView>
                    <div className="GlobalGameFeed_player__DUdJR GlobalGameFeed_address__HLDUh " style={{'minWidth':'50px', 'width':'50px'}}>
                        <a href={explorer[data.chainId] + "address/" + data.player}>
                            <div className="paper" style={{'borderRadius': '50px', 'display': 'inline-block', 'margin': '0px', 'overflow': 'hidden', 'padding': '0px', 'backgroundColor': 'rgb(252, 25, 41)', 'height': '15px', 'width': '15px'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="15" width="15"><rect x="0" y="0" rx="0" ry="0" height="15" width="15" transform="translate(-0.06281948292294663 0.024767708564772433) rotate(216.5 7.5 7.5)" fill="#f1d802"></rect><rect x="0" y="0" rx="0" ry="0" height="15" width="15" transform="translate(7.577561773192083 3.1649802581979065) rotate(161.2 7.5 7.5)" fill="#f77b01"></rect><rect x="0" y="0" rx="0" ry="0" height="15" width="15" transform="translate(7.6331182503584225 -6.494913455115599) rotate(485.4 7.5 7.5)" fill="#e8f300"></rect><rect x="0" y="0" rx="0" ry="0" height="15" width="15" transform="translate(-18.711989763735442 1.3748646815010066) rotate(225.8 7.5 7.5)" fill="#01658c"></rect></svg>
                            </div>
                            {data.player.slice(0, 8)}
                        </a>
                    </div>
                </MobileView>
                <div className="GlobalGameFeed_wager__SyapG">
                    {formatUsdt(data.wager)}
                    {usdtIcon}
                </div>
                <BrowserView>
                    <div className="GlobalGameFeed_multiplier__SYFYL">{data.multiplier}×</div>
                </BrowserView>
                <BrowserView>
                    <div className={"GlobalGameFeed_profit__YTw0q " + classProfit(data.profit)}>
                        <span>
                            {formatUsdt(data.profit)} {usdtIcon}
                        </span>
                    </div>
                </BrowserView>
            </div>
        )); 
        else return <></>;
    }

    useEffect(()=>{

        /*if (!fetchedDataFlag.current && gameFeedData.length === 0) {
          fetchedDataFlag.current = true;
    
          // Update Arbitrum First.
          const fetchDataCoinFlip = async () => {
            const ArbitrumOneUrl =
            "https://arbitrumone.uytin.io/address/" + coinFlipAddress[42161] + "#tokentxns";
            try {
              const response = await axios.get(ArbitrumOneUrl);
              const htmlContent = response.data;
    
              const tbodyRegex =
                /<tbody class="align-middle text-nowrap">([\s\S]*?)<\/tbody>/g;
              const tbodyMatch = tbodyRegex.exec(htmlContent);
    
              if (tbodyMatch && tbodyMatch[1]) {
                const tbodyContent = tbodyMatch[1];
                const hrefRegex =
                  /<a class='hash-tag text-truncate myFnExpandBox_searchVal'[^>]*href='([^']+)'/g;
                let matches;
                const urls = [];
                while (
                  (matches = hrefRegex.exec(tbodyContent)) !== null &&
                  urls.length < 2
                ) {
                  urls.push("https://arbitrumone.uytin.io" + matches[1]);
                }
                for (let i = 0; i < urls.length; i++) {
                  axios
                    .get(urls[i])
                    .then((response) => {
                      const htmlString = response.data;
                      const $ = cheerio.load(htmlString);
    
                      const spanElement = $("#showUtcLocalDate");
                      const dataTimestamp = spanElement.attr("data-timestamp");
    
                      const regexA =
                        /<a[^>]*data-highlight-target="([^"]*)"[^>]*>/g;
                      const matchesA = [];
                      let matchA;
                      while ((matchA = regexA.exec(htmlString)) !== null) {
                        matchesA.push(matchA[1]);
                      }
    
                      const regexSpan = /<span class='me-1'[^>]*>(.*?)<\/span>/g;
                      const matchesSpan = [];
                      let matchSpan;
                      while ((matchSpan = regexSpan.exec(htmlString)) !== null) {
                        matchesSpan.push(matchSpan[1]);
                      }
    
                      if (matchesA.length > 1 && matchesSpan.length > 0) {
                        if (matchesA[0] !== matchesA[1]) {
                          const newFeed = {
                            chainId: 42161,
                            time: timestamptoTime(dataTimestamp),
                            game: "Tung Xu",
                            slash: "/tungxu",
                            player: matchesA[0],
                            wager: Number(matchesSpan[matchesSpan.length-1] * 1000000),
                            multiplier: "+-",
                            profit: "-",
                          };
                          setGameFeedData((gameFeedData) => [newFeed, ...gameFeedData]);
                        } // else console.log("DEPOSIT BANKROLL, DON'T ADD TO FEED");
                      }
                    })
                    .catch((error) => {
                      console.error("There was an error fetching the data!", error);
                    });
                }
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          };
          fetchDataCoinFlip();

          const fetchDataDice = async () => {
              const ArbitrumOneUrl =
              "https://arbitrumone.uytin.io/address/" + diceAddress[42161] + "#tokentxns";
              try {
                const response = await axios.get(ArbitrumOneUrl);
                const htmlContent = response.data;
      
                const tbodyRegex =
                  /<tbody class="align-middle text-nowrap">([\s\S]*?)<\/tbody>/g;
                const tbodyMatch = tbodyRegex.exec(htmlContent);
      
                if (tbodyMatch && tbodyMatch[1]) {
                  const tbodyContent = tbodyMatch[1];
                  const hrefRegex =
                    /<a class='hash-tag text-truncate myFnExpandBox_searchVal'[^>]*href='([^']+)'/g;
                  let matches;
                  const urls = [];
                  while (
                    (matches = hrefRegex.exec(tbodyContent)) !== null &&
                    urls.length < 2
                  ) {
                    urls.push("https://arbitrumone.uytin.io" + matches[1]);
                  }
                  for (let i = 0; i < urls.length; i++) {
                    axios
                      .get(urls[i])
                      .then((response) => {
                        const htmlString = response.data;
                        const $ = cheerio.load(htmlString);
      
                        const spanElement = $("#showUtcLocalDate");
                        const dataTimestamp = spanElement.attr("data-timestamp");
      
                        const regexA =
                          /<a[^>]*data-highlight-target="([^"]*)"[^>]*>/g;
                        const matchesA = [];
                        let matchA;
                        while ((matchA = regexA.exec(htmlString)) !== null) {
                          matchesA.push(matchA[1]);
                        }
      
                        const regexSpan = /<span class='me-1'[^>]*>(.*?)<\/span>/g;
                        const matchesSpan = [];
                        let matchSpan;
                        while ((matchSpan = regexSpan.exec(htmlString)) !== null) {
                          matchesSpan.push(matchSpan[1]);
                        }
                        // console.log(matchesSpan[matchesSpan.length-1]);
      
                        if (matchesA.length > 1 && matchesSpan.length > 0) {
                          // console.log(gameFeedData.length);
                          if (matchesA[0] !== matchesA[1]) {
                            const newFeed = {
                              chainId: 42161,
                              time: timestamptoTime(dataTimestamp),
                              game: "Xúc Xắc",
                              slash: "/xucxac",
                              player: matchesA[0],
                              wager: Number(matchesSpan[matchesSpan.length-1] * 1000000),
                              multiplier: "+-",
                              profit: "-",
                            };
                            setGameFeedData((gameFeedData) => [newFeed, ...gameFeedData]);
                          } // else console.log("DEPOSIT BANKROLL, DON'T ADD TO FEED");
                        }
                      })
                      .catch((error) => {
                        console.error("There was an error fetching the data!", error);
                      });
                  }
                }
              } catch (error) {
                console.error("Error fetching data:", error);
              }
            };
          fetchDataDice();

          const fetchDataOanTuTi = async () => {
              const ArbitrumOneUrl =
              "https://arbitrumone.uytin.io/address/" + rockPaperScissorsAddress[42161] + "#tokentxns";
              try {
                const response = await axios.get(ArbitrumOneUrl);
                const htmlContent = response.data;
      
                const tbodyRegex =
                  /<tbody class="align-middle text-nowrap">([\s\S]*?)<\/tbody>/g;
                const tbodyMatch = tbodyRegex.exec(htmlContent);
      
                if (tbodyMatch && tbodyMatch[1]) {
                  const tbodyContent = tbodyMatch[1];
                  const hrefRegex =
                    /<a class='hash-tag text-truncate myFnExpandBox_searchVal'[^>]*href='([^']+)'/g;
                  let matches;
                  const urls = [];
                  while (
                    (matches = hrefRegex.exec(tbodyContent)) !== null &&
                    urls.length < 2
                  ) {
                    urls.push("https://arbitrumone.uytin.io" + matches[1]);
                  }
                  for (let i = 0; i < urls.length; i++) {
                    axios
                      .get(urls[i])
                      .then((response) => {
                        const htmlString = response.data;
                        const $ = cheerio.load(htmlString);
      
                        const spanElement = $("#showUtcLocalDate");
                        const dataTimestamp = spanElement.attr("data-timestamp");
      
                        const regexA =
                          /<a[^>]*data-highlight-target="([^"]*)"[^>]*>/g;
                        const matchesA = [];
                        let matchA;
                        while ((matchA = regexA.exec(htmlString)) !== null) {
                          matchesA.push(matchA[1]);
                        }
      
                        const regexSpan = /<span class='me-1'[^>]*>(.*?)<\/span>/g;
                        const matchesSpan = [];
                        let matchSpan;
                        while ((matchSpan = regexSpan.exec(htmlString)) !== null) {
                          matchesSpan.push(matchSpan[1]);
                        }
                        // console.log(matchesSpan[matchesSpan.length-1]);
      
                        if (matchesA.length > 1 && matchesSpan.length > 0) {
                          // console.log(gameFeedData.length);
                          if (matchesA[0] !== matchesA[1]) {
                            const newFeed = {
                              chainId: 42161,
                              time: timestamptoTime(dataTimestamp),
                              game: "Oẳn Tù Tì",
                              slash: "/oantuti",
                              player: matchesA[0],
                              wager: Number(matchesSpan[matchesSpan.length-1] * 1000000),
                              multiplier: "+-",
                              profit: "-",
                            };
                            setGameFeedData((gameFeedData) => [newFeed, ...gameFeedData]);
                          } // else console.log("DEPOSIT BANKROLL, DON'T ADD TO FEED");
                        }
                      })
                      .catch((error) => {
                        console.error("There was an error fetching the data!", error);
                      });
                  }
                }
              } catch (error) {
                console.error("Error fetching data:", error);
              }
            };
          fetchDataOanTuTi(); 

          const fetchDataBauCua = async () => {
              const ArbitrumOneUrl =
              "https://arbitrumone.uytin.io/address/" + bauCuaAddress[42161] + "#tokentxns";
              try {
                const response = await axios.get(ArbitrumOneUrl);
                const htmlContent = response.data;
      
                const tbodyRegex =
                  /<tbody class="align-middle text-nowrap">([\s\S]*?)<\/tbody>/g;
                const tbodyMatch = tbodyRegex.exec(htmlContent);
      
                if (tbodyMatch && tbodyMatch[1]) {
                  const tbodyContent = tbodyMatch[1];
                  const hrefRegex =
                    /<a class='hash-tag text-truncate myFnExpandBox_searchVal'[^>]*href='([^']+)'/g;
                  let matches;
                  const urls = [];
                  while (
                    (matches = hrefRegex.exec(tbodyContent)) !== null &&
                    urls.length < 2
                  ) {
                    urls.push("https://arbitrumone.uytin.io" + matches[1]);
                  }
                  for (let i = 0; i < urls.length; i++) {
                    axios
                      .get(urls[i])
                      .then((response) => {
                        const htmlString = response.data;
                        const $ = cheerio.load(htmlString);
      
                        const spanElement = $("#showUtcLocalDate");
                        const dataTimestamp = spanElement.attr("data-timestamp");
      
                        const regexA =
                          /<a[^>]*data-highlight-target="([^"]*)"[^>]*>/g;
                        const matchesA = [];
                        let matchA;
                        while ((matchA = regexA.exec(htmlString)) !== null) {
                          matchesA.push(matchA[1]);
                        }
      
                        const regexSpan = /<span class='me-1'[^>]*>(.*?)<\/span>/g;
                        const matchesSpan = [];
                        let matchSpan;
                        while ((matchSpan = regexSpan.exec(htmlString)) !== null) {
                          matchesSpan.push(matchSpan[1]);
                        }
                        
                        if (matchesA.length > 1 && matchesSpan.length > 0) {
                          if (matchesA[0] !== matchesA[1]) {
                            const newFeed = {
                              chainId: 42161,
                              time: timestamptoTime(dataTimestamp),
                              game: "Bầu Cua",
                              slash: "/baucua",
                              player: matchesA[0],
                              wager: Number(matchesSpan[matchesSpan.length-1] * 1000000),
                              multiplier: "+-",
                              profit: "-",
                            };
                            setGameFeedData((gameFeedData) => [newFeed, ...gameFeedData]);
                          } // else console.log("DEPOSIT BANKROLL, DON'T ADD TO FEED");
                        }
                      })
                      .catch((error) => {
                        console.error("There was an error fetching the data!", error);
                      });
                  }
                }
              } catch (error) {
                console.error("Error fetching data:", error);
              }
            };
          fetchDataBauCua(); 
        } */
        
        // Feed from Arbitrum Sepolia: chainID: 421614
        const diceProviderSepolia = new ethers.JsonRpcProvider(rpcProviderUrl[421614][7]);
        const diceListenerSepolia = (playerAddress, wager, payout, tokenAddress, multiplier, isOver, diceOutcomes, payouts, numGames) => {
            const wagers = Number(wager) * Number(payouts.length);
            const multiplierTemp = Number(payout)/wagers;
            const profit = Number(payout) - wagers;
      
            const newFeed = {
              'chainId': 421614, 'time': time(), 'game': "Xúc Xắc", 'slash': '/xucxac',
              'player': playerAddress, 'wager': wagers, 'multiplier': multiplierTemp.toFixed(2), 'profit': profit
            };
            setGameFeedData(gameFeedData => [newFeed, ...gameFeedData]);
        }
        const diceContractSepolia = diceAddress[421614] 
                        ? new ethers.Contract(diceAddress[421614], diceAbi, diceProviderSepolia)
                        : null;
        const initDiceSepolia = async () => {
            try {
                await listenToEventWithExponentialBackoff(
                    diceContractSepolia,
                    "Dice_Outcome_Event",
                    5,
                    16000,
                    diceListenerSepolia
                );
            }catch(e) {
                handleError(e);
            }
        }

        const coinFlipProviderSepolia = new ethers.JsonRpcProvider(rpcProviderUrl[421614][6]);
        const coinFlipListenerSepolia = ( playerAddress, wager, payout, tokenAddress, coinOutcomes, payouts, numGames ) => {      
            const wagers = Number(wager) * Number(payouts.length);
            const multiplierTemp = Number(payout) / wagers;
            const profit = Number(payout) - wagers;
            
            const newFeed = { 
              'chainId': 421614, 'time': time(), 'game': "Tung Xu", 'slash': "/tungxu",
              'player': playerAddress, 'wager': wagers, 'multiplier': multiplierTemp.toFixed(2), 'profit': profit,
            };
            setGameFeedData((gameFeedData) => [newFeed, ...gameFeedData]);
          };
        const coinFlipContractSepolia = coinFlipAddress[421614]
        ? new ethers.Contract(coinFlipAddress[421614], coinflipAbi, coinFlipProviderSepolia)
        : null;
        const initCoinFlipSepolia = async () => {
            try {
                await listenToEventWithExponentialBackoff(
                    coinFlipContractSepolia,
                    "CoinFlip_Outcome_Event",
                    5,
                    16000,
                    coinFlipListenerSepolia
                );
            }catch(e) {
                handleError(e);
            }
        }

        const rockPaperScissorsProviderSepolia = new ethers.JsonRpcProvider(rpcProviderUrl[421614][8]);
        const rockPaperScissorsListenerSepolia = ( playerAddress, wager, payout, tokenAddress, outcomes, randomActions, payouts, numGames) => {
            const wagers = Number(wager) * Number(payouts.length);
            const multiplierTemp = Number(payout)/wagers;
            const profit = Number(payout) - wagers;
      
            const newFeed = {
              'chainId': 421614, 'time': time(), 'game': "Oẳn Tù Tì", 'slash': '/oantuti',
              'player': playerAddress, 'wager': wagers, 'multiplier': multiplierTemp.toFixed(2), 'profit': profit
            };
            setGameFeedData(gameFeedData => [newFeed, ...gameFeedData]);
        }
        const rockPaperScissorsContractSepolia = rockPaperScissorsAddress[421614] 
                    ? new ethers.Contract(rockPaperScissorsAddress[421614], rockPaperScissorsAbi, rockPaperScissorsProviderSepolia)
                    : null;
        const initPaperScissorsContractSepolia = async () => {
            try {
                await listenToEventWithExponentialBackoff(
                    rockPaperScissorsContractSepolia,
                    "RockPaperScissors_Outcome_Event",
                    5,
                    16000,
                    rockPaperScissorsListenerSepolia
                );
            }catch(e) {
                handleError(e);
            }
        };

        const bauCuaProviderSepolia = new ethers.JsonRpcProvider(rpcProviderUrl[421614][5]);
        const bauCuaListenerSepolia = ( playerAddress, wager, payout, tokenAddress, randomActions, payouts, numGames ) => {
            const wagers = Number(wager) * Number(payouts.length);
            const multiplierTemp = Number(payout) / wagers;
            const profit = Number(payout) - wagers;
      
            const newFeed = {
              chainId: 421614,
              time: time(),
              game: "Bầu Cua",
              slash: "/baucua",
              player: playerAddress,
              wager: wagers,
              multiplier: multiplierTemp.toFixed(2),
              profit: profit,
            };
            setGameFeedData((gameFeedData) => [newFeed, ...gameFeedData]);
        };
        const bauCuaContractSepolia = bauCuaAddress[421614]
                    ? new ethers.Contract(bauCuaAddress[421614], baucuaAbi, bauCuaProviderSepolia)
                    : null;
        const initBauCuaContractSepolia = async () => {
            try {
                await listenToEventWithExponentialBackoff(
                    bauCuaContractSepolia,
                    "BauCua_Outcome_Event",
                    5,
                    16000,
                    bauCuaListenerSepolia
                );
            }catch(e) {
                handleError(e);
            }
        };

        if (diceContractSepolia) initDiceSepolia();
        if (coinFlipContractSepolia) initCoinFlipSepolia();
        if (rockPaperScissorsContractSepolia) initPaperScissorsContractSepolia();
        if (bauCuaContractSepolia) initBauCuaContractSepolia();

        const diceProviderPolygon = new ethers.JsonRpcProvider(rpcProviderUrl[137][7]);
        const diceListenerPolygon = (playerAddress, wager, payout, tokenAddress, multiplier, isOver, diceOutcomes, payouts, numGames) => {
            const wagers = Number(wager) * Number(payouts.length);
            const multiplierTemp = Number(payout)/wagers;
            const profit = Number(payout) - wagers;
      
            const newFeed = {
              'chainId': 137, 'time': time(), 'game': "Xúc Xắc", 'slash': '/xucxac',
              'player': playerAddress, 'wager': wagers, 'multiplier': multiplierTemp.toFixed(2), 'profit': profit
            };
            setGameFeedData(gameFeedData => [newFeed, ...gameFeedData]);
        }
        const diceContractPolygon = diceAddress[137] 
                        ? new ethers.Contract(diceAddress[137], diceAbi, diceProviderPolygon)
                        : null;
        const initDicePolygon = async () => {
            try {
                await listenToEventWithExponentialBackoff(
                    diceContractPolygon,
                    "Dice_Outcome_Event",
                    5,
                    16000,
                    diceListenerPolygon
                );
            }catch(e) {
                handleError(e);
            }
        }

        const coinFlipProviderPolygon = new ethers.JsonRpcProvider(rpcProviderUrl[137][6]);
        const coinFlipListenerPolygon = ( playerAddress, wager, payout, tokenAddress, coinOutcomes, payouts, numGames ) => {      
            const wagers = Number(wager) * Number(payouts.length);
            const multiplierTemp = Number(payout) / wagers;
            const profit = Number(payout) - wagers;
            
            const newFeed = { 
              'chainId': 137, 'time': time(), 'game': "Tung Xu", 'slash': "/tungxu",
              'player': playerAddress, 'wager': wagers, 'multiplier': multiplierTemp.toFixed(2), 'profit': profit,
            };
            setGameFeedData((gameFeedData) => [newFeed, ...gameFeedData]);
          };
        const coinFlipContractPolygon = coinFlipAddress[137]
        ? new ethers.Contract(coinFlipAddress[137], coinflipAbi, coinFlipProviderPolygon)
        : null;
        const initCoinFlipPolygon = async () => {
            try {
                await listenToEventWithExponentialBackoff(
                    coinFlipContractPolygon,
                    "CoinFlip_Outcome_Event",
                    5,
                    16000,
                    coinFlipListenerPolygon
                );
            } catch(e) {
                handleError(e);
            }
        }

        const rockPaperScissorsProviderPolygon = new ethers.JsonRpcProvider(rpcProviderUrl[137][8]);
        const rockPaperScissorsListenerPolygon = ( playerAddress, wager, payout, tokenAddress, outcomes, randomActions, payouts, numGames) => {
            const wagers = Number(wager) * Number(payouts.length);
            const multiplierTemp = Number(payout)/wagers;
            const profit = Number(payout) - wagers;
      
            const newFeed = {
              'chainId': 137, 'time': time(), 'game': "Oẳn Tù Tì", 'slash': '/oantuti',
              'player': playerAddress, 'wager': wagers, 'multiplier': multiplierTemp.toFixed(2), 'profit': profit
            };
            setGameFeedData(gameFeedData => [newFeed, ...gameFeedData]);
        }
        const rockPaperScissorsContractPolygon = rockPaperScissorsAddress[137] 
                    ? new ethers.Contract(rockPaperScissorsAddress[137], rockPaperScissorsAbi, rockPaperScissorsProviderPolygon)
                    : null;
        const initPaperScissorsContractPolygon = async () => {
            try {
                await listenToEventWithExponentialBackoff(
                    rockPaperScissorsContractPolygon,
                    "RockPaperScissors_Outcome_Event",
                    5,
                    16000,
                    rockPaperScissorsListenerPolygon
                );
            }catch(e) {
                handleError(e);
            }
        };

        const bauCuaProviderPolygon = new ethers.JsonRpcProvider(rpcProviderUrl[137][5]);
        const bauCuaListenerPolygon = ( playerAddress, wager, payout, tokenAddress, randomActions, payouts, numGames ) => {
            const wagers = Number(wager) * Number(payouts.length);
            const multiplierTemp = Number(payout) / wagers;
            const profit = Number(payout) - wagers;
      
            const newFeed = {
              chainId: 137,
              time: time(),
              game: "Bầu Cua",
              slash: "/baucua",
              player: playerAddress,
              wager: wagers,
              multiplier: multiplierTemp.toFixed(2),
              profit: profit,
            };
            setGameFeedData((gameFeedData) => [newFeed, ...gameFeedData]);
        };
        const bauCuaContractPolygon = bauCuaAddress[137]
                    ? new ethers.Contract(bauCuaAddress[137], baucuaAbi, bauCuaProviderPolygon)
                    : null;
        const initBauCuaContractPolygon = async () => {
            try {
                await listenToEventWithExponentialBackoff(
                    bauCuaContractPolygon,
                    "BauCua_Outcome_Event",
                    5,
                    16000,
                    bauCuaListenerPolygon
                );
            }catch(e) {
                handleError(e);
            }
        };

        if (diceContractPolygon) initDicePolygon();
        if (coinFlipContractPolygon) initCoinFlipPolygon();
        if (rockPaperScissorsContractPolygon) initPaperScissorsContractPolygon();
        if (bauCuaContractPolygon) initBauCuaContractPolygon();

        const diceProviderArbitrum = new ethers.JsonRpcProvider(rpcProviderUrl[42161][7]);
        const diceListenerArbitrum = (playerAddress, wager, payout, tokenAddress, multiplier, isOver, diceOutcomes, payouts, numGames) => {
            const wagers = Number(wager) * Number(payouts.length);
            const multiplierTemp = Number(payout)/wagers;
            const profit = Number(payout) - wagers;
      
            const newFeed = {
              'chainId': 42161, 'time': time(), 'game': "Xúc Xắc", 'slash': '/xucxac',
              'player': playerAddress, 'wager': wagers, 'multiplier': multiplierTemp.toFixed(2), 'profit': profit
            };
            setGameFeedData(gameFeedData => [newFeed, ...gameFeedData]);
        }
        const diceContractArbitrum = diceAddress[42161] 
                        ? new ethers.Contract(diceAddress[42161], diceAbi, diceProviderArbitrum)
                        : null;
        const initDiceArbitrum = async () => {
            try {
                await listenToEventWithExponentialBackoff(
                    diceContractArbitrum,
                    "Dice_Outcome_Event",
                    5,
                    16000,
                    diceListenerArbitrum
                );
            }catch(e) {
                handleError(e);
            }
        }

        const coinFlipProviderArbitrum = new ethers.JsonRpcProvider(rpcProviderUrl[42161][6]);
        const coinFlipListenerArbitrum = ( playerAddress, wager, payout, tokenAddress, coinOutcomes, payouts, numGames ) => {      
            const wagers = Number(wager) * Number(payouts.length);
            const multiplierTemp = Number(payout) / wagers;
            const profit = Number(payout) - wagers;
            
            const newFeed = { 
              'chainId': 42161, 'time': time(), 'game': "Tung Xu", 'slash': "/tungxu",
              'player': playerAddress, 'wager': wagers, 'multiplier': multiplierTemp.toFixed(2), 'profit': profit,
            };
            setGameFeedData((gameFeedData) => [newFeed, ...gameFeedData]);
          };
        const coinFlipContractArbitrum = coinFlipAddress[42161]
        ? new ethers.Contract(coinFlipAddress[42161], coinflipAbi, coinFlipProviderArbitrum)
        : null;
        const initCoinFlipArbitrum = async () => {
            try {
                await listenToEventWithExponentialBackoff(
                    coinFlipContractArbitrum,
                    "CoinFlip_Outcome_Event",
                    5,
                    16000,
                    coinFlipListenerArbitrum
                );
            }catch(e) {
                handleError(e);
            }
        }

        const rockPaperScissorsProviderArbitrum = new ethers.JsonRpcProvider(rpcProviderUrl[42161][8]);
        const rockPaperScissorsListenerArbitrum = ( playerAddress, wager, payout, tokenAddress, outcomes, randomActions, payouts, numGames) => {
            const wagers = Number(wager) * Number(payouts.length);
            const multiplierTemp = Number(payout)/wagers;
            const profit = Number(payout) - wagers;
      
            const newFeed = {
              'chainId': 42161, 'time': time(), 'game': "Oẳn Tù Tì", 'slash': '/oantuti',
              'player': playerAddress, 'wager': wagers, 'multiplier': multiplierTemp.toFixed(2), 'profit': profit
            };
            setGameFeedData(gameFeedData => [newFeed, ...gameFeedData]);
        }
        const rockPaperScissorsContractArbitrum = rockPaperScissorsAddress[42161] 
                    ? new ethers.Contract(rockPaperScissorsAddress[42161], rockPaperScissorsAbi, rockPaperScissorsProviderArbitrum)
                    : null;
        const initPaperScissorsContractArbitrum = async () => {
            try {
                await listenToEventWithExponentialBackoff(
                    rockPaperScissorsContractArbitrum,
                    "RockPaperScissors_Outcome_Event",
                    5,
                    16000,
                    rockPaperScissorsListenerArbitrum
                );
            }catch(e) {
                handleError(e);
            }
        };

        const bauCuaProviderArbitrum = new ethers.JsonRpcProvider(rpcProviderUrl[42161][5]);
        const bauCuaListenerArbitrum = ( playerAddress, wager, payout, tokenAddress, randomActions, payouts, numGames ) => {
            const wagers = Number(wager) * Number(payouts.length);
            const multiplierTemp = Number(payout) / wagers;
            const profit = Number(payout) - wagers;
      
            const newFeed = {
              chainId: 42161,
              time: time(),
              game: "Bầu Cua",
              slash: "/baucua",
              player: playerAddress,
              wager: wagers,
              multiplier: multiplierTemp.toFixed(2),
              profit: profit,
            };
            setGameFeedData((gameFeedData) => [newFeed, ...gameFeedData]);
        };
        const bauCuaContractArbitrum = bauCuaAddress[42161]
                    ? new ethers.Contract(bauCuaAddress[42161], baucuaAbi, bauCuaProviderArbitrum)
                    : null;
        const initBauCuaContractArbitrum = async () => {
            try {
                await listenToEventWithExponentialBackoff(
                    bauCuaContractArbitrum,
                    "BauCua_Outcome_Event",
                    5,
                    16000,
                    bauCuaListenerArbitrum
                );
            }catch(e) {
                handleError(e);
            }
        };

        if (diceContractArbitrum) initDiceArbitrum();
        if (coinFlipContractArbitrum) initCoinFlipArbitrum();
        if (rockPaperScissorsContractArbitrum) initPaperScissorsContractArbitrum();
        if (bauCuaContractArbitrum) initBauCuaContractArbitrum();

        return () => {
            if (diceContractSepolia) {
                diceContractSepolia.off('Dice_Outcome_Event', diceListenerSepolia);
            } 
            if (coinFlipContractSepolia) {
                coinFlipContractSepolia.off('CoinFlip_Outcome_Event', coinFlipListenerSepolia);
            }
            if (rockPaperScissorsContractSepolia) {
                rockPaperScissorsContractSepolia.off('RockPaperScissors_Outcome_Event', rockPaperScissorsListenerSepolia);
            }
            if (bauCuaContractSepolia) {
                bauCuaContractSepolia.off('BauCua_Outcome_Event', bauCuaListenerSepolia);
            }
            if (diceContractPolygon) {
                diceContractPolygon.off('Dice_Outcome_Event', diceListenerPolygon);
            } 
            if (coinFlipContractPolygon) {
                coinFlipContractPolygon.off('CoinFlip_Outcome_Event', coinFlipListenerPolygon);
            }
            if (rockPaperScissorsContractPolygon) {
                rockPaperScissorsContractPolygon.off('RockPaperScissors_Outcome_Event', rockPaperScissorsListenerPolygon);
            }
            if (bauCuaContractPolygon) {
                bauCuaContractPolygon.off('BauCua_Outcome_Event', bauCuaListenerPolygon);
            }
            if (diceContractArbitrum) {
                diceContractArbitrum.off('Dice_Outcome_Event', diceListenerArbitrum);
            } 
            if (coinFlipContractArbitrum) {
                coinFlipContractArbitrum.off('CoinFlip_Outcome_Event', coinFlipListenerArbitrum);
            }
            if (rockPaperScissorsContractArbitrum) {
                rockPaperScissorsContractArbitrum.off('RockPaperScissors_Outcome_Event', rockPaperScissorsListenerArbitrum);
            }
            if (bauCuaContractArbitrum) {
                bauCuaContractArbitrum.off('BauCua_Outcome_Event', bauCuaListenerArbitrum);
            }
        };
    },[]);
    
    return (
        <div className="GlobalGameFeed_container__E1D38">
            <div className="GlobalGameFeed_header__7EEgt">
                <div className="GlobalGameFeed_title__Qrh6a">
                    <div className="GlobalGameFeed_live_indicator__PG1W_"></div>
                    <h2 className="GlobalGameFeed_title_name__muTne">Đặt Cược Gần Nhất</h2>
                </div>
            </div>
            <div className="GlobalGameFeed_table_container__0jAJG">
                <div className="GlobalGameFeed_table__O_wEx">
                    <div className="GlobalGameFeed_header_row__ELXCZ">
                        <BrowserView>
                            <div className="GlobalGameFeed_header_timestamp__4f0iM">Thời gian</div>
                        </BrowserView>
                        <MobileView>
                            <div className="GlobalGameFeed_header_game___ZETQ" style={{'minWidth':'120px', 'width':'120px'}}>Game</div>
                        </MobileView>
                        <MobileView>
                        <div className="GlobalGameFeed_header_player__IrfmX" style={{'minWidth':'50px', 'width':'50px'}}>Người chơi</div>
                        </MobileView>
                        <BrowserView>
                            <div className="GlobalGameFeed_header_game___ZETQ" >Game</div>
                        </BrowserView>
                        <BrowserView>
                            <div className="GlobalGameFeed_header_player__IrfmX" >Người chơi</div>
                        </BrowserView>
                        <div className="GlobalGameFeed_header_wager__fntom">Cược</div>
                        <BrowserView>
                            <div className="GlobalGameFeed_header_multiplier__zB67M">Tỉ lệ nhân</div>
                        </BrowserView>
                        <BrowserView>
                            <div className="GlobalGameFeed_header_profit__XZsuP">Lợi nhuận</div>
                        </BrowserView>
                    </div>
                    {feed()}
                </div>
                
            </div>
        </div>
  );
}
