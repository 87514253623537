import { useContext, useState, useEffect } from "react";
import { formatUnits, ethers } from "ethers";
import { NavLink } from "react-router-dom";

import { WalletContext } from "../hooks/WalletContext";
import { arbitrumIcon, polygonIcon, uytinIcon, usdtIcon } from "./static/svg";

import {rpcProviderUrl} from './static/rpcProviderUrl';
import { erc20Address, bankrollAddress } from "./static/contractAddress";
import erc20 from "../abi/erc20.json";

export function Header() {
  const {
    account,
    chainId,
    connectMetaMask,
    erc20Balance,
    updateBalance,
    updateBankroll
  } = useContext(WalletContext);

  const erc20Abi = erc20.abi;

  const [isNetworkSelector, setNetworkSelector] = useState({
    opacity: 0,
    pointerEvents: "none",
  });
  const [classNetworkSelector, setClassNetworkSelector] = useState("");
  const [onSelector, setOnSelector] = useState(false);


  const showNetworkSelector = (event) => {
    setNetworkSelector({ opacity: 1, pointerEvents: "all" });
    setClassNetworkSelector("NetworkSelector_active_selected__mXxlJ");
  };
  const hiddenNetworkSelector = (event) => {
    setTimeout(() => {
      if (onSelector === false) {
        setNetworkSelector({ opacity: 0, pointerEvents: "none" });
        setClassNetworkSelector("");
      }
    }, 500);
  };
  const onNetworkSelector = (event) => {
    setOnSelector(true);
    setTimeout(() => {
      setNetworkSelector({ opacity: 1, pointerEvents: "all" });
    }, 500);
  };
  const offNetworkSelector = (event) => {
    setOnSelector(false);
    setNetworkSelector({ opacity: 0, pointerEvents: "none" });
    setClassNetworkSelector("");
  };

  //ChainID: Arbitrum One: 42161(0xa4b1); Polygon: 137 (0x89); Ethereum Sepolia: 11155111
  const networkIcon = (id) => {
    if (id === 42161) return arbitrumIcon;
    else if (id === 137) return polygonIcon;
    else if (id === 421614) return uytinIcon;
  };

  const FormatBalance = () => {
    return (
      <>
        {parseFloat(formatUnits(erc20Balance, 6)).toLocaleString("en", {
          minimumFractionDigits: 4,
        })}
        <span className="WalletBalance_symbol__ugzq4">USDT</span>
        {usdtIcon}
      </>
    );
  };

  const changeNetwork = async (_chainId) => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: _chainId }], // chainId must be in hexadecimal numbers
      });
    } catch (e) {
      if(e.code === 4902) {
        try {
          const result = await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [{
              chainId: "0x66eee",
              rpcUrls: ["https://arbitrum-sepolia.blockpi.network/v1/rpc/public"],
              chainName: "Arbitrum Sepolia",
              nativeCurrency: {
                name: "ETH",
                symbol: "ETH",
                decimals: 18
              },
              blockExplorerUrls: ["https://sepolia-explorer.arbitrum.io"]
            }]
          });
        } catch (error){
          console.log(error)
        }
      }
    }
  };


  const NetworkSelector = () => {
    return (
      <div
        className="NetworkSelector_dropdown_container__p_XYU"
        style={isNetworkSelector}
        onMouseEnter={onNetworkSelector}
        onMouseLeave={offNetworkSelector}
      >
        <div
          className="NetworkSelector_network_entry__ftWrt"
          onClick={() => changeNetwork("0xa4b1")}
        >
          Arbitrum
          <span className="NetworkSelector_network_icon__1JJIc">
            {arbitrumIcon}
          </span>
        </div>
        <div
          className="NetworkSelector_network_entry__ftWrt"
          onClick={() => changeNetwork("0x89")}
        >
          Polygon
          <span className="NetworkSelector_network_icon__1JJIc">
            {polygonIcon}
          </span>
        </div>
        <div
          className="NetworkSelector_network_entry__ftWrt"
          onClick={() => changeNetwork("0x66eee")}
        >
          Sepolia
          <span className="NetworkSelector_network_icon__1JJIc">
            {uytinIcon}
          </span>
        </div>
      </div>
    );
  };

  const headerLeft = () => {
    return (
      <NavLink className="Header_nav_link__0N70n " to="/">
        <span className="Header_icon__JM6UF">
          {uytinIcon}
        </span>
        <span className="Header_app_title__zwrNC">UYTIN.IO</span>
      </NavLink>
    );
  };

  const headerCenter = () => {
    if (!account) return <></>;
    else
      return (
        <div className="WalletBalance_container__V3qQ7">
          <button
            className="WalletBalance_header_container__7MK0_ "
          >
            <div className="WalletBalance_bet_token__nlJRd">{FormatBalance()}</div>
          </button>
        </div>
      );
  };

  const headerRight = () => {
    if (!account)
      return (
        <button
          className="ConnectButton_container__7wq8q"
          onClick={connectMetaMask}
        >
          Connect Wallet
        </button>
      );
    else if (chainId === 42161 || chainId === 137 || chainId === 421614)
      return (
        <>
          <button className="WalletDisplay_container__E_Nhn">
            <div className="WalletDisplay_username_address_container__OIryF">
              <div className="WalletDisplay_username__5wAdr">
                {account.slice(0, 8)}
              </div>
            </div>
          </button>
          <div
            className="NetworkSelector_container__9LwLt"
            onMouseEnter={showNetworkSelector}
            onMouseLeave={hiddenNetworkSelector}
          >
            <button
              className={
                "NetworkSelector_selected__eHWwN  " + classNetworkSelector
              }
            >
              <span className="NetworkSelector_selected_network_icon__thPUP">
                {networkIcon(chainId)}
              </span>
            </button>
            {NetworkSelector()}
          </div>
        </>
      );
    else
      return (
        <>
          <button className="WalletDisplay_container__E_Nhn">
            <div className="WalletDisplay_username_address_container__OIryF">
              <div className="WalletDisplay_username__5wAdr">
                {account.slice(0, 8)}
              </div>
            </div>
          </button>
          <div className="NetworkSelector_container__9LwLt">
            <button
              className={
                "NetworkSelector_selected__eHWwN  NetworkSelector_wrong_network__gy_kI " +
                classNetworkSelector
              }
              onMouseEnter={showNetworkSelector}
              onMouseLeave={hiddenNetworkSelector}
            >
              <span className="NetworkSelector_selected_network_icon__thPUP">
                <svg
                  height="1.25rem"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <circle cx="12" cy="17" r="1" fill="white"></circle>
                  <path
                    d="M12 10L12 14"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M3.44722 18.1056L10.2111 4.57771C10.9482 3.10361 13.0518 3.10362 13.7889 4.57771L20.5528 18.1056C21.2177 19.4354 20.2507 21 18.7639 21H5.23607C3.7493 21 2.78231 19.4354 3.44722 18.1056Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </span>
              <div className="NetworkSelector_wrong_network_text__09J_t">
                Wrong Network
              </div>
            </button>
            {NetworkSelector()}
          </div>
        </>
      );
  };

  useEffect(() => {
    if (!chainId) return;
    if (!rpcProviderUrl[chainId]) return;
    if (!erc20Address[chainId]) return;
    if (!window.ethereum) return;
    const provider = new ethers.JsonRpcProvider(rpcProviderUrl[chainId][9]);
    const erc20Contract = new ethers.Contract(erc20Address[chainId], erc20Abi, provider);
      const init = async () => {
        try {
          erc20Contract.on('Transfer', (from, to, value) => {
            if (account) if (account.toUpperCase() === from.toUpperCase() || account.toUpperCase() === to.toUpperCase())
            updateBalance();
            if (bankrollAddress[chainId].toUpperCase() === from.toUpperCase() || bankrollAddress[chainId].toUpperCase() === to.toUpperCase())
            updateBankroll();
          });
        }catch(e) {
          console.log(e);
        }
      };
    init();
    return () => {
      erc20Contract.off('Transfer',()=>{});
    };
  }, [chainId, account]);

  return (
    <header className="Header_header__TiXbT">
      <div className="Header_header_left__c7b3n">{headerLeft()}</div>
      <div className="Header_header_center__ydVxZ">{headerCenter()}</div>
      <div className="Header_header_right__okHmq">{headerRight()}</div>
    </header>
  );
}
