/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import { WalletContext } from "../hooks/WalletContext";

export function TaiXiuHistories({ results }) {
  useEffect(() => {}, [results]);

  const colorClass = (value) => {
    if (value > 10) return "violet";
    else return "blue";
  };

  const tableHeader = () => {
    if (results.length != 0) return (<tr>
      <th>Xúc Xắc 1</th>
      <th>Xúc Xắc 2</th>
      <th>Xúc Xắc 3</th>
      <th>Kết Quả</th>
    </tr>)
    else return;
  }

  const outcome = () => {
    if (results.length < 10)
      return results.map((result) => (
        <tr key={uuidv4()}>
          <td>{Number(result.xucxac1)}</td>
          <td>{Number(result.xucxac2)}</td>
          <td>{Number(result.xucxac3)}</td>
          <td>
            <span
              className="dotwhite"
              style={{
                color: colorClass(
                  result.xucxac1 + result.xucxac2 + result.xucxac3
                ),
              }}
            >
              {Number(result.xucxac1 + result.xucxac2 + result.xucxac3)}
            </span>
          </td>
        </tr>
      ));
    else
      return results
        .slice(results.length - 10, results.length)
        .map((result) => (
          <tr key={uuidv4()}>
            <td>{Number(result.xucxac1)}</td>
            <td>{Number(result.xucxac2)}</td>
            <td>{Number(result.xucxac3)}</td>
            <td>
              <span
                className="dotwhite"
                style={{
                  color: colorClass(
                    result.xucxac1 + result.xucxac2 + result.xucxac3
                  ),
                }}
              >
                {Number(result.xucxac1 + result.xucxac2 + result.xucxac3)}
              </span>
            </td>
          </tr>
        ));
  };

  return (
    <table className="taixiu_history center">
      <tbody>
        {tableHeader()}
        {outcome()}
      </tbody>
    </table>
  );
}
