/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid';
import { MobileView, BrowserView } from 'react-device-detect';

export function BauCuaOutcomesDisplay({ bauCuaOutCome }) {
  const numberToSvg = (number) => {
    if (number === 0) return "/static/media/bau.svg";
    else if (number === 1) return "/static/media/cua.svg";
    else if (number === 2) return "/static/media/ca.svg";
    else if (number === 3) return "/static/media/ga.svg";
    else if (number === 4) return "/static/media/tom.svg";
    else if (number === 5) return "/static/media/nai.svg";
  }
  const outcomeMobile = () => {
    if (bauCuaOutCome.length < 9)
    return bauCuaOutCome.map((dice) => ( <img key={uuidv4()} style={{height: "30px"}} src={numberToSvg(dice)} alt="UYTIN.IO Bau Cua Blockchain USDT"/>));
    else return bauCuaOutCome.slice((bauCuaOutCome.length - 9), bauCuaOutCome.length)
    .map((dice) => ( <img key={uuidv4()} style={{height: "30px"}} src={numberToSvg(dice)} alt="UYTIN.IO Bau Cua Blockchain USDT"/>));
  }
  const outcomeBrowser = () => {
    if (bauCuaOutCome.length < 12)
    return bauCuaOutCome.map((dice) => ( <img key={uuidv4()} style={{height: "30px"}} src={numberToSvg(dice)} alt="UYTIN.IO Bau Cua Blockchain USDT"/>));
    else return bauCuaOutCome.slice((bauCuaOutCome.length - 12), bauCuaOutCome.length)
    .map((dice) => ( <img key={uuidv4()} style={{height: "30px"}} src={numberToSvg(dice)} alt="UYTIN.IO Bau Cua Blockchain USDT"/>));
  }
  return (<>
  <BrowserView>
    <div className="Bau_Cua_Result" >{outcomeBrowser()}</div>
  </BrowserView>
  <MobileView>
    <div className="Bau_Cua_Result" >{outcomeMobile()}</div>
  </MobileView>
  </>);
}
