/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid';
import { useCallback } from 'react';

export function GameOutcomesDisplay({ histories }) {
  const classHistory = (value) => {
    if (value > 0) return "GameOutcomesDisplay_won__Zubik";
    else return "";
  };

  const outcome = useCallback(() => {
    if (histories.length < 10) 
    return histories.map((history) => (
      <a
        key={uuidv4()}
        className={
          "GameOutcomesDisplay_outcome__drfTx " + classHistory(history)
        }
        href="/"
        target="_blank"
        rel="noreferrer"
      >
        {history}×
      </a>
    ));
    else if (histories.length >= 10) 
      return histories.slice((histories.length - 10), histories.length).map((history) => (
        <a
          key={uuidv4()}
          className={
            "GameOutcomesDisplay_outcome__drfTx " + classHistory(history)
          }
          href="/"
          target="_blank"
          rel="noreferrer"
        >
          {history}×
        </a>
      ));
    else return "";
  },[histories]);

  return <div className="GameOutcomesDisplay_outcomes__eH0_l">{outcome()}</div>;
}
