const getRandomMilliseconds = () => Math.floor(Math.random() * 1000);

export const listenToEventWithExponentialBackoff = async (
  contract,
  eventName,
  retries,
  maxBackoff,
  callback
) => {
  let attempt = 0;
  let delay = 1000; // Initial delay in milliseconds

  const tryListening = async () => {
    try {
      contract.on(eventName, callback);
    } catch (error) {
      if (attempt >= retries) {
        throw new Error("Max retries reached for event: " + eventName);
      }
      const randomDelay = getRandomMilliseconds();
      const backoffTime = Math.min(delay + randomDelay, maxBackoff);
      // console.log(
      //   `Retrying to listen to event '${eventName}' in ${
      //     backoffTime / 1000
      //   } seconds... Attempt ${attempt + 1}`
      // );
      await new Promise((res) => setTimeout(res, backoffTime));
      delay = Math.min(delay * 2, maxBackoff);
      attempt += 1;
      await tryListening();
    }
  };

  await tryListening();
};

export const time = () => {
  const temp = new Date();
  const hours =
    temp.getHours() < 10 ? "0" + temp.getHours() : temp.getHours();
  const minutes =
    temp.getMinutes() < 10 ? "0" + temp.getMinutes() : temp.getMinutes();
  return hours + ":" + minutes;
};

export const timestamptoTime = (timestamp) => {
  const temp = new Date(timestamp * 1000);
  const hours =
    temp.getHours() < 10 ? "0" + temp.getHours() : temp.getHours();
  const minutes =
    temp.getMinutes() < 10 ? "0" + temp.getMinutes() : temp.getMinutes();
  return hours + ":" + minutes;
};