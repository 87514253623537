/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import { WalletContext } from "../hooks/WalletContext";

export function XocDiaHistories({ results }) {
  useEffect(() => {}, [results]);

  const colorClass = (value) => {
    if (value === 1) return "quantrang";
    else return "quanden";
  };

  const tableHeader = () => {
    if (results.length != 0) return (<tr>
      <th>Quân 1</th>
      <th>Quân 2</th>
      <th>Quân 3</th>
      <th>Quân 4</th>
    </tr>)
    else return;
  }

  const outcome = () => {
    if (results.length < 10)
      return results.map((result) => (
        <tr key={uuidv4()}>
          <td>
            <span
              style={{
                height: "16px",
                width: "16px",
                borderRadius: "50%",
                display: "inline-block",
              }}
              className={colorClass(Number(result.quan1))}
            ></span>
          </td>
          <td>
            <span
              style={{
                height: "16px",
                width: "16px",
                borderRadius: "50%",
                display: "inline-block",
              }}
              className={colorClass(Number(result.quan2))}
            ></span>
          </td>
          <td>
            <span
              style={{
                height: "16px",
                width: "16px",
                borderRadius: "50%",
                display: "inline-block",
              }}
              className={colorClass(Number(result.quan3))}
            ></span>
          </td>
          <td>
            <span
              style={{
                height: "16px",
                width: "16px",
                borderRadius: "50%",
                display: "inline-block",
              }}
              className={colorClass(Number(result.quan4))}
            ></span>
          </td>
        </tr>
      ));
    else
      return results
        .slice(results.length - 10, results.length)
        .map((result) => (
          <tr key={uuidv4()}>
            <td>
              <span
                style={{
                  height: "16px",
                  width: "16px",
                  borderRadius: "50%",
                  display: "inline-block",
                }}
                className={colorClass(Number(result.quan1))}
              ></span>
            </td>
            <td>
              <span
                style={{
                  height: "16px",
                  width: "16px",
                  borderRadius: "50%",
                  display: "inline-block",
                }}
                className={colorClass(Number(result.quan2))}
              ></span>
            </td>
            <td>
              <span
                style={{
                  height: "16px",
                  width: "16px",
                  borderRadius: "50%",
                  display: "inline-block",
                }}
                className={colorClass(Number(result.quan3))}
              ></span>
            </td>
            <td>
              <span
                style={{
                  height: "16px",
                  width: "16px",
                  borderRadius: "50%",
                  display: "inline-block",
                }}
                className={colorClass(Number(result.quan4))}
              ></span>
            </td>
          </tr>
        ));
  };

  return (
    <table className="taixiu_history center">
      <tbody>
        {tableHeader()}
        {outcome()}
      </tbody>
    </table>
  );
}
