/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState, useEffect } from "react";
import { ethers } from "ethers";
import axios from "axios";
import cheerio from "cheerio";

import { MobileView } from "react-device-detect";
import { MobileMenu } from "../components/MobileMenu";
import { BottomMenu } from "../components/BottomMenu";

import { WalletContext } from "../hooks/WalletContext";
import { InputContainer } from "../components/InputContainer";
import { BauCuaDisplay } from "../components/BauCuaDisplay";
import { BauCuaDescription } from "../components/static/BauCuaDescription";
import { GameFeed } from "../components/GameFeed";
import { MoreGame } from "../components/MoreGame";
import { Footer } from "../components/static/Footer";

import { listenToEventWithExponentialBackoff, timestamptoTime, time } from "../hooks/function";

import {
  bauCuaAddress,
  erc20Address,
} from "../components/static/contractAddress";
import { rpcProviderUrl } from "../components/static/rpcProviderUrl";

// import json from "../abi/buakeogiay.json";
import erc20 from "../abi/erc20.json";
import baucua from "../abi/baucua.json";

export function BauCua() {
  const {
    account,
    chainId,
    setNotificationType,
    leftSideClose,
    isWaitingVrf,
    setWaitingVrf,
    round,
    setRound,
    handleError,
  } = useContext(WalletContext);

  const bauCuaAbi = baucua.abi;
  const erc20Abi = erc20.abi;

  const multiplier = 4;
  const decimals = 10 ** 6;
  const [allowance, setAllowance] = useState(0);

  // actions selected: Bau (0), Cua (1), Ca (2), Ga (3), Tom (4), Nai (5)
  const [action, setAction] = useState(6);
  const [lastOutCome, setLastOutCome] = useState([0, 0, 0]);
  const [histories, setHistories] = useState([]);
  const [bauCuaOutCome, setBauCuaOutCome] = useState([]);
  const [gameFeedData, setGameFeedData] = useState([]);
  const fetchedDataFlag = React.useRef(false); 

  const closeStyle = () => {
    if (leftSideClose) return "50px";
    else return "";
  };

  const updateAllowance = async () => {
    const provider = chainId
      ? new ethers.JsonRpcProvider(rpcProviderUrl[chainId][5])
      : null;

    const erc20Contract = erc20Address[chainId]
      ? new ethers.Contract(erc20Address[chainId], erc20Abi, provider)
      : null;

    if (erc20Contract && bauCuaAddress[chainId]) {
      const allowanceAmount = await erc20Contract.allowance(
        account,
        bauCuaAddress[chainId]
      );
      setAllowance(Number(allowanceAmount));
    }
  };

  const approveErc20 = async (amount) => {
    setNotificationType("normal");
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const erc20Contract = erc20Address[chainId]
      ? new ethers.Contract(erc20Address[chainId], erc20Abi, signer)
      : null;

    if (erc20Contract) {
      const tx = erc20Contract
        .approve(bauCuaAddress[chainId], Math.floor(Number(amount * decimals)))
        .then(() => {
          updateAllowance();
          setNotificationType("");
        })
        .catch((e) => {
          handleError(e);
        });
    }
  };

  useEffect(() => {
    updateAllowance();
    window.scrollTo(0, 0);
    setRound(0);

    /*if (!fetchedDataFlag.current && gameFeedData.length === 0) {
      fetchedDataFlag.current = true;

      // Update Arbitrum First.
      const fetchData = async () => {
        const ArbitrumOneUrl =
        "https://arbitrumone.uytin.io/address/" + bauCuaAddress[42161] + "#tokentxns";
        try {
          const response = await axios.get(ArbitrumOneUrl);
          const htmlContent = response.data;

          const tbodyRegex =
            /<tbody class="align-middle text-nowrap">([\s\S]*?)<\/tbody>/g;
          const tbodyMatch = tbodyRegex.exec(htmlContent);

          if (tbodyMatch && tbodyMatch[1]) {
            const tbodyContent = tbodyMatch[1];
            const hrefRegex =
              /<a class='hash-tag text-truncate myFnExpandBox_searchVal'[^>]*href='([^']+)'/g;
            let matches;
            const urls = [];
            while (
              (matches = hrefRegex.exec(tbodyContent)) !== null &&
              urls.length < 5
            ) {
              urls.push("https://arbitrumone.uytin.io" + matches[1]);
            }
            for (let i = 0; i < urls.length; i++) {
              axios
                .get(urls[i])
                .then((response) => {
                  const htmlString = response.data;
                  const $ = cheerio.load(htmlString);

                  const spanElement = $("#showUtcLocalDate");
                  const dataTimestamp = spanElement.attr("data-timestamp");

                  const regexA =
                    /<a[^>]*data-highlight-target="([^"]*)"[^>]*>/g;
                  const matchesA = [];
                  let matchA;
                  while ((matchA = regexA.exec(htmlString)) !== null) {
                    matchesA.push(matchA[1]);
                  }

                  const regexSpan = /<span class='me-1'[^>]*>(.*?)<\/span>/g;
                  const matchesSpan = [];
                  let matchSpan;
                  while ((matchSpan = regexSpan.exec(htmlString)) !== null) {
                    matchesSpan.push(matchSpan[1]);
                  }
                  
                  if (matchesA.length > 1 && matchesSpan.length > 0) {
                    if (matchesA[0] !== matchesA[1]) {
                      const newFeed = {
                        chainId: 42161,
                        time: timestamptoTime(dataTimestamp),
                        game: "Bầu Cua",
                        slash: "/baucua",
                        player: matchesA[0],
                        wager: Number(matchesSpan[matchesSpan.length-1] * 1000000),
                        multiplier: "+-",
                        profit: "-",
                      };
                      setGameFeedData((gameFeedData) => [newFeed, ...gameFeedData]);
                    } // else console.log("DEPOSIT BANKROLL, DON'T ADD TO FEED");
                  }
                })
                .catch((error) => {
                  console.error("There was an error fetching the data!", error);
                });
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    } */

    const eventSepoliaListener = ( playerAddress, wager, payout, tokenAddress, randomActions, payouts, numGames ) => {
      if (account)
        if (account.toUpperCase() === playerAddress.toUpperCase()) {
          for (let i = 0; i < Number(numGames); i++) {
            setHistories((histories) => [
              ...histories,
              Number(payouts[i]) / Number(wager),
            ]);
          }
          for (let j = 0; j < 3 * Number(numGames); j++) {
            setBauCuaOutCome((bauCuaOutCome) => [
              ...bauCuaOutCome,
              Number(randomActions[j]),
            ]);
          }
          const lastRoll = Number(numGames) - 1;
          setLastOutCome([
            Number(randomActions[3 * lastRoll]),
            Number(randomActions[3 * lastRoll + 1]),
            Number(randomActions[3 * lastRoll + 2]),
          ]);
          setWaitingVrf(false);
          setNotificationType("");
          const roundNotBets = Number(payouts.length) - Number(numGames);
          setRound((round) => round - roundNotBets);
        }
      const wagers = Number(wager) * Number(payouts.length);
      const multiplierTemp = Number(payout) / wagers;
      const profit = Number(payout) - wagers;

      const newFeed = {
        chainId: 421614,
        time: time(),
        game: "Bầu Cua",
        slash: "/baucua",
        player: playerAddress,
        wager: wagers,
        multiplier: multiplierTemp.toFixed(2),
        profit: profit,
      };
      setGameFeedData((gameFeedData) => [newFeed, ...gameFeedData]);
    };
    const providerSepolia = new ethers.JsonRpcProvider( rpcProviderUrl[421614][5] );
    const contractSepolia = bauCuaAddress[421614]
      ? new ethers.Contract(bauCuaAddress[421614], bauCuaAbi, providerSepolia)
      : null;

    const initSepolia = async () => {
      try {
        await listenToEventWithExponentialBackoff(
          contractSepolia,
          "BauCua_Outcome_Event",
          5,
          16000,
          eventSepoliaListener
        );
      } catch (e) {
        handleError(e);
      }
    };
    if (contractSepolia) initSepolia();

    const eventArbitrumListener = ( playerAddress, wager, payout, tokenAddress, randomActions, payouts, numGames ) => {
      if (account)
        if (account.toUpperCase() === playerAddress.toUpperCase()) {
          for (let i = 0; i < Number(numGames); i++) {
            setHistories((histories) => [
              ...histories,
              Number(payouts[i]) / Number(wager),
            ]);
          }
          for (let j = 0; j < 3 * Number(numGames); j++) {
            setBauCuaOutCome((bauCuaOutCome) => [
              ...bauCuaOutCome,
              Number(randomActions[j]),
            ]);
          }
          const lastRoll = Number(numGames) - 1;
          setLastOutCome([
            Number(randomActions[3 * lastRoll]),
            Number(randomActions[3 * lastRoll + 1]),
            Number(randomActions[3 * lastRoll + 2]),
          ]);
          setWaitingVrf(false);
          setNotificationType("");
          const roundNotBets = Number(payouts.length) - Number(numGames);
          setRound((round) => round - roundNotBets);
        }
      const wagers = Number(wager) * Number(payouts.length);
      const multiplierTemp = Number(payout) / wagers;
      const profit = Number(payout) - wagers;

      const newFeed = {
        chainId: 42161,
        time: time(),
        game: "Bầu Cua",
        slash: "/baucua",
        player: playerAddress,
        wager: wagers,
        multiplier: multiplierTemp.toFixed(2),
        profit: profit,
      };
      setGameFeedData((gameFeedData) => [newFeed, ...gameFeedData]);
    };
    const providerArbitrum = new ethers.JsonRpcProvider( rpcProviderUrl[42161][5] );
    const contractArbitrum = bauCuaAddress[42161]
      ? new ethers.Contract(bauCuaAddress[42161], bauCuaAbi, providerArbitrum)
      : null;

    const initArbitrum = async () => {
      try {
        await listenToEventWithExponentialBackoff(
          contractArbitrum,
          "BauCua_Outcome_Event",
          5,
          16000,
          eventArbitrumListener
        );
      } catch (e) {
        handleError(e);
      }
    };
    if (contractArbitrum) initArbitrum();

    const eventPolygonListener = ( playerAddress, wager, payout, tokenAddress, randomActions, payouts, numGames ) => {
      if (account)
        if (account.toUpperCase() === playerAddress.toUpperCase()) {
          for (let i = 0; i < Number(numGames); i++) {
            setHistories((histories) => [
              ...histories,
              Number(payouts[i]) / Number(wager),
            ]);
          }
          for (let j = 0; j < 3 * Number(numGames); j++) {
            setBauCuaOutCome((bauCuaOutCome) => [
              ...bauCuaOutCome,
              Number(randomActions[j]),
            ]);
          }
          const lastRoll = Number(numGames) - 1;
          setLastOutCome([
            Number(randomActions[3 * lastRoll]),
            Number(randomActions[3 * lastRoll + 1]),
            Number(randomActions[3 * lastRoll + 2]),
          ]);
          setWaitingVrf(false);
          setNotificationType("");
          const roundNotBets = Number(payouts.length) - Number(numGames);
          setRound((round) => round - roundNotBets);
        }
      const wagers = Number(wager) * Number(payouts.length);
      const multiplierTemp = Number(payout) / wagers;
      const profit = Number(payout) - wagers;

      const newFeed = {
        chainId: 137,
        time: time(),
        game: "Bầu Cua",
        slash: "/baucua",
        player: playerAddress,
        wager: wagers,
        multiplier: multiplierTemp.toFixed(2),
        profit: profit,
      };
      setGameFeedData((gameFeedData) => [newFeed, ...gameFeedData]);
    };
    const providerPolygon = new ethers.JsonRpcProvider( rpcProviderUrl[137][5] );
    const contractPolygon = bauCuaAddress[137]
      ? new ethers.Contract(bauCuaAddress[137], bauCuaAbi, providerPolygon)
      : null;

    const initPolygon = async () => {
      try {
        await listenToEventWithExponentialBackoff(
          contractPolygon,
          "BauCua_Outcome_Event",
          5,
          16000,
          eventPolygonListener
        );
      } catch (e) {
        handleError(e);
      }
    };
    if (contractPolygon) initPolygon();

    const providerFix = chainId
      ? new ethers.JsonRpcProvider(rpcProviderUrl[chainId][5])
      : null;
    const contractFix = bauCuaAddress[chainId]
      ? new ethers.Contract(bauCuaAddress[chainId], bauCuaAbi, providerFix)
      : null;

    const intervalId = contractFix
      ? setInterval(() => {
          // Fix metamask send two or more transaction in one time use clicked.
          if (round < histories.length) setRound(histories.length);
          if (isWaitingVrf) {
            contractFix.BauCua_GetState(account).then((BauCuaGame) => {
              if (Number(BauCuaGame.wager) === 0) setWaitingVrf(false);
            });
          }
        }, 1000)
      : null;
    
    // get game State.
    if (contractFix) contractFix.BauCua_GetState(account).then((BauCuaGame) => {
      if (Number(BauCuaGame.wager) !== 0) setWaitingVrf(true);
    });

    return () => {
      if (contractFix !== null) {
        clearInterval(intervalId);
      }
      if (contractSepolia !== null) {
        contractSepolia.off("BauCua_Outcome_Event", eventSepoliaListener);
      }
      if (contractArbitrum !== null) {
        contractArbitrum.off("BauCua_Outcome_Event", eventArbitrumListener);
      }
      if (contractPolygon !== null) {
        contractPolygon.off("BauCua_Outcome_Event", eventPolygonListener);
      }
    };
  }, [chainId, account]);

  const sendTransaction = async (
    wagerToContract,
    bets,
    _stopLoss,
    _stopGain
  ) => {
    try {
      if (!bauCuaAddress[chainId]) return;
      setNotificationType("normal");
      setRound((round) => round + Math.floor(bets));
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const bauCuaContract =
        bauCuaAddress[chainId] && signer
          ? new ethers.Contract(bauCuaAddress[chainId], bauCuaAbi, signer)
          : null;

      const transaction = await bauCuaContract
        .BauCua_Play(
          Math.floor(wagerToContract),
          erc20Address[chainId],
          action,
          Math.floor(bets),
          _stopGain.toString(),
          _stopLoss.toString()
        )
        .then(() => {
          setNotificationType("");
          setWaitingVrf(true);
          updateAllowance();
        });
    } catch (error) {
      setWaitingVrf(false);
      setRound((round) => round - Math.floor(bets));
      handleError(error);
    }
  };

  return (
    <div
      className="Layout_middle_container__rQzvK"
      style={{ "--left_margin": closeStyle() }}
    >
      <main className="Layout_content__3KYZT">
        <div className="GameWrapper_game_container___djZh">
          <div className="Dice_container___ID90">
            <InputContainer
              multiplier={multiplier}
              sendTransaction={sendTransaction}
              allowance={allowance}
              approveErc20={approveErc20}
            />
            <BauCuaDisplay
              action={action}
              histories={histories}
              randomActions={lastOutCome}
              setAction={setAction}
              bauCuaOutCome={bauCuaOutCome}
            />
          </div>
          <BauCuaDescription />
        </div>
        <GameFeed gameFeedData={gameFeedData} />
        <MoreGame />
        <MobileView>
          <MobileMenu />
          <BottomMenu />
        </MobileView>
        <Footer />
      </main>
    </div>
  );
}
