/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState, useEffect } from "react";
import { WalletContext } from "../hooks/WalletContext";


import { GameOutcomesDisplay } from "./GameOutcomesDisplay";
import {Bankroll} from './Bankroll';


export function CoinFlipDisplay({ isHeads, setIsHeads, histories, coinOutcomes }) {
  const { isWaitingVrf, round } = useContext(WalletContext);

  const [classCoin, setClassCoin] = useState("");

  const choiceSide = (value) => {
    if (isHeads && value === false) {
      setClassCoin("Coin_heads_to_tails");
      setIsHeads(value);
    }
    if (!isHeads && value === true) {
      setClassCoin("Coin_tails_to_heads");
      setIsHeads(value);
    }
  };
  const classChoice = (value) => {
    if (value) return "HeadsOrTailsSelector_selected__KcD1G";
    else return "";
  };

  useEffect(()=>{
    // console.log(round, histories.length);
    if (round === 0 ) setClassCoin("");
    else if (round > histories.length || isWaitingVrf) setClassCoin("Coin_tossing_waitingvrf");
    else if (Number(coinOutcomes) === 0) {
      setClassCoin("Coin_heads_to_tails");
    } else if (Number(coinOutcomes) === 1){
      setClassCoin("Coin_tails_to_heads");
    } else setClassCoin("");
  },[coinOutcomes, round, histories, isWaitingVrf]);

  return (
    <>
      <div className="Display_container__qSXl5">
        <Bankroll />
        <GameOutcomesDisplay histories={histories} />
        <div className="Display_above__oCBz5">
          <div className="Coin_shadow__it9xJ"></div>
          <div
            style={{
              "--period": "750s",
              "--diameter": "240px",
              "--thickness": "20px",
            }}
          >
            <div className={"Coin_coin__bKUgo " + classCoin}>
              <img
                alt="Heads" 
                src={"/static/media/heads.svg"}
                decoding="async"
                data-nimg="fill"
                className="Coin_heads__VaTsW"
                loading="lazy"
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  inset: "0px",
                  color: "transparent",
                }}
              />
              <img
                alt="Tails"
                src={"/static/media/tails.svg"}
                decoding="async"
                data-nimg="fill"
                className="Coin_tails__1VoaY"
                loading="lazy"
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  inset: "0px",
                  color: "transparent",
                }}
              />
            </div>
          </div>
        </div>
        <div className="SpecificInputs_container__dEKm8">
          <div className="HeadsOrTailsSelector_container__JZbkR">
            <button
              className={classChoice(isHeads)}
              onClick={() => choiceSide(true)}
            >
              Heads
            </button>
            <button
              className={classChoice(!isHeads)}
              onClick={() => choiceSide(false)}
            >
              Tails
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
