/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState, useEffect } from "react";
import {ethers} from 'ethers';
import axios from "axios";
import cheerio from "cheerio";

import { MobileView } from 'react-device-detect';
import { MobileMenu } from "../components/MobileMenu";
import { BottomMenu } from "../components/BottomMenu";

import { WalletContext } from "../hooks/WalletContext";
import {InputContainer} from "../components/InputContainer";
import {DiceDisplay} from "../components/DiceDisplay";
import {DiceDescription} from "../components/static/DiceDescription";
import { diceAddress, erc20Address } from "../components/static/contractAddress";
import { rpcProviderUrl } from "../components/static/rpcProviderUrl";
import {GameFeed} from "../components/GameFeed";
import { MoreGame } from "../components/MoreGame";
import {Footer} from "../components/static/Footer";
import { listenToEventWithExponentialBackoff, timestamptoTime, time } from "../hooks/function";

import abi from "../abi/dice.json";
import erc20 from "../abi/erc20.json";

export function Dice() {

  const { account, chainId, leftSideClose, setNotificationType, isWaitingVrf, setWaitingVrf, round, setRound, handleError } =
    useContext(WalletContext);

  const decimals = 10 ** 6;
  const diceAbi = abi.abi;
  const erc20Abi = erc20.abi;
  
  const [allowance, setAllowance] = useState(0);
  const [multiplier, setMultiplier] = useState(1.98);
  const [isOver, setIsOver] = useState(true);
  const [diceOutcome, setLastOutCome] = useState("");
  const [histories, setHistories] = useState([]);
  const [gameFeedData, setGameFeedData] = useState([]);
  const fetchedDataFlag = React.useRef(false); 

  const closeStyle = () => {
    if (leftSideClose) return "50px";
    else return "";
  }

  const updateAllowance = async () => {
    const provider = chainId
      ? new ethers.JsonRpcProvider(rpcProviderUrl[chainId][7])
      : null;

    const erc20Contract = erc20Address[chainId]
      ? new ethers.Contract(erc20Address[chainId], erc20Abi, provider)
      : null;

    if (erc20Contract && diceAddress[chainId]) {
      const allowanceAmount = await erc20Contract.allowance(
        account,
        diceAddress[chainId]
      );
      setAllowance(Number(allowanceAmount));
    }
  }

  const approveErc20 = async (amount) => {
    setNotificationType("normal");
    const provider =  new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const erc20Contract = erc20Address[chainId]
      ? new ethers.Contract(erc20Address[chainId], erc20Abi, signer)
      : null;

    if (erc20Contract) {
      const tx = erc20Contract
      .approve(diceAddress[chainId], Math.floor(Number(amount * decimals)))
      .then(() => {
        updateAllowance();
        setNotificationType("");
      })
      .catch((e) => {
        handleError(e);
      });
    }
  }

  const sendTransaction = async (
    wagerToContract,
    bets,
    _stopLoss,
    _stopGain
  ) => {
    if (!diceAddress[chainId]) return;
    setNotificationType("normal");
    setRound((round) => round+Math.floor(bets));
    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract( diceAddress[chainId], diceAbi, signer);

      const transaction = await contract.Dice_Play(
        Math.floor(wagerToContract), 
        Math.floor(Number(multiplier*10000)),
        erc20Address[chainId],
        isOver,
        Math.floor(bets),
        _stopLoss.toString(),
        _stopGain.toString()
      ).then(() => {
        setNotificationType("");
        setWaitingVrf(true)
        updateAllowance();
      });
    } catch (error) {
      setWaitingVrf(false);
      setRound((round) => round-Math.floor(bets));
      handleError(error);
    }
  };

  useEffect(() => {
    updateAllowance();
    window.scrollTo(0, 0);
    setRound(0);

    /*if (!fetchedDataFlag.current && gameFeedData.length === 0) {
      fetchedDataFlag.current = true;

      // Update Arbitrum First.
      const fetchData = async () => {
        const ArbitrumOneUrl =
        "https://arbitrumone.uytin.io/address/" + diceAddress[42161] + "#tokentxns";
        try {
          const response = await axios.get(ArbitrumOneUrl);
          const htmlContent = response.data;

          const tbodyRegex =
            /<tbody class="align-middle text-nowrap">([\s\S]*?)<\/tbody>/g;
          const tbodyMatch = tbodyRegex.exec(htmlContent);

          if (tbodyMatch && tbodyMatch[1]) {
            const tbodyContent = tbodyMatch[1];
            const hrefRegex =
              /<a class='hash-tag text-truncate myFnExpandBox_searchVal'[^>]*href='([^']+)'/g;
            let matches;
            const urls = [];
            while (
              (matches = hrefRegex.exec(tbodyContent)) !== null &&
              urls.length < 5
            ) {
              urls.push("https://arbitrumone.uytin.io" + matches[1]);
            }
            for (let i = 0; i < urls.length; i++) {
              axios
                .get(urls[i])
                .then((response) => {
                  const htmlString = response.data;
                  const $ = cheerio.load(htmlString);

                  const spanElement = $("#showUtcLocalDate");
                  const dataTimestamp = spanElement.attr("data-timestamp");

                  const regexA =
                    /<a[^>]*data-highlight-target="([^"]*)"[^>]*>/g;
                  const matchesA = [];
                  let matchA;
                  while ((matchA = regexA.exec(htmlString)) !== null) {
                    matchesA.push(matchA[1]);
                  }

                  const regexSpan = /<span class='me-1'[^>]*>(.*?)<\/span>/g;
                  const matchesSpan = [];
                  let matchSpan;
                  while ((matchSpan = regexSpan.exec(htmlString)) !== null) {
                    matchesSpan.push(matchSpan[1]);
                  }
                  // console.log(matchesSpan[matchesSpan.length-1]);

                  if (matchesA.length > 1 && matchesSpan.length > 0) {
                    // console.log(gameFeedData.length);
                    if (matchesA[0] !== matchesA[1]) {
                      const newFeed = {
                        chainId: 42161,
                        time: timestamptoTime(dataTimestamp),
                        game: "Xúc Xắc",
                        slash: "/xucxac",
                        player: matchesA[0],
                        wager: Number(matchesSpan[matchesSpan.length-1] * 1000000),
                        multiplier: "+-",
                        profit: "-",
                      };
                      setGameFeedData((gameFeedData) => [newFeed, ...gameFeedData]);
                    } // else console.log("DEPOSIT BANKROLL, DON'T ADD TO FEED");
                  }
                })
                .catch((error) => {
                  console.error("There was an error fetching the data!", error);
                });
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    } */
    
    const eventSepoliaListener = (playerAddress, wager, payout, tokenAddress, multiplier, isOver, diceOutcomes, payouts, numGames) => {
      if (account) if (account.toUpperCase() === playerAddress.toUpperCase()) {
        for (let i = 0; i < Number(numGames); i++) {
          if (Number(payouts[i]) === 0) setHistories(histories => [...histories, 0]);
          else setHistories(histories => [...histories, Number(multiplier)/10000]);
        }
        setLastOutCome((Number(diceOutcomes[Number(numGames) - 1])/100000).toFixed(0));
        setWaitingVrf(false);
        setNotificationType("");
        const roundNotBets = Number(payouts.length) - Number(numGames);
        setRound((round) => round-roundNotBets);
      }

      const wagers = Number(wager) * Number(payouts.length);
      const multiplierTemp = Number(payout)/wagers;
      const profit = Number(payout) - wagers;

      const newFeed = {
        'chainId': 421614, 'time': time(), 'game': "Xúc Xắc", 'slash': '/xucxac',
        'player': playerAddress, 'wager': wagers, 'multiplier': multiplierTemp.toFixed(2), 'profit': profit
      };
      setGameFeedData(gameFeedData => [newFeed, ...gameFeedData]);
    }
    const providerSepolia = new ethers.JsonRpcProvider(rpcProviderUrl[421614][7]);
    const contractSepolia = diceAddress[421614] 
                    ? new ethers.Contract(diceAddress[421614], diceAbi, providerSepolia)
                    : null;

    const initSepolia = async () => {
      try {
        await listenToEventWithExponentialBackoff(
          contractSepolia,
          "Dice_Outcome_Event",
          5,
          16000,
          eventSepoliaListener
        );
      }catch(e) {
        handleError(e);
      }
    };
    if (contractSepolia) initSepolia();

    const eventPolygonListener = (playerAddress, wager, payout, tokenAddress, multiplier, isOver, diceOutcomes, payouts, numGames) => {
      if (account) if (account.toUpperCase() === playerAddress.toUpperCase()) {
        for (let i = 0; i < Number(numGames); i++) {
          if (Number(payouts[i]) === 0) setHistories(histories => [...histories, 0]);
          else setHistories(histories => [...histories, Number(multiplier)/10000]);
        }
        setLastOutCome((Number(diceOutcomes[Number(numGames) - 1])/100000).toFixed(0));
        setWaitingVrf(false);
        setNotificationType("");
        const roundNotBets = Number(payouts.length) - Number(numGames);
        setRound((round) => round-roundNotBets);
      }

      const wagers = Number(wager) * Number(payouts.length);
      const multiplierTemp = Number(payout)/wagers;
      const profit = Number(payout) - wagers;

      const newFeed = {
        'chainId': 137, 'time': time(), 'game': "Xúc Xắc", 'slash': '/xucxac',
        'player': playerAddress, 'wager': wagers, 'multiplier': multiplierTemp.toFixed(2), 'profit': profit
      };
      setGameFeedData(gameFeedData => [newFeed, ...gameFeedData]);
    }
    const providerPolygon = new ethers.JsonRpcProvider(rpcProviderUrl[137][7]);
    const contractPolygon = diceAddress[137] 
                    ? new ethers.Contract(diceAddress[137], diceAbi, providerPolygon)
                    : null;

    const initPolygon = async () => {
      try {
        await listenToEventWithExponentialBackoff(
          contractPolygon,
          "Dice_Outcome_Event",
          5,
          16000,
          eventPolygonListener
        );
      }catch(e) {
        handleError(e);
      }
    };
    if (contractPolygon) initPolygon();

    const eventArbitrumListener = (playerAddress, wager, payout, tokenAddress, multiplier, isOver, diceOutcomes, payouts, numGames) => {
      if (account) if (account.toUpperCase() === playerAddress.toUpperCase()) {
        for (let i = 0; i < Number(numGames); i++) {
          if (Number(payouts[i]) === 0) setHistories(histories => [...histories, 0]);
          else setHistories(histories => [...histories, Number(multiplier)/10000]);
        }
        setLastOutCome((Number(diceOutcomes[Number(numGames) - 1])/100000).toFixed(0));
        setWaitingVrf(false);
        setNotificationType("");
        const roundNotBets = Number(payouts.length) - Number(numGames);
        setRound((round) => round-roundNotBets);
      }

      const wagers = Number(wager) * Number(payouts.length);
      const multiplierTemp = Number(payout)/wagers;
      const profit = Number(payout) - wagers;

      const newFeed = {
        'chainId': 42161, 'time': time(), 'game': "Xúc Xắc", 'slash': '/xucxac',
        'player': playerAddress, 'wager': wagers, 'multiplier': multiplierTemp.toFixed(2), 'profit': profit
      };
      setGameFeedData(gameFeedData => [newFeed, ...gameFeedData]);
    }
    const providerArbitrum = new ethers.JsonRpcProvider(rpcProviderUrl[42161][7]);
    const contractArbitrum = diceAddress[42161] 
                    ? new ethers.Contract(diceAddress[42161], diceAbi, providerArbitrum)
                    : null;

    const initArbitrum = async () => {
      try {
        await listenToEventWithExponentialBackoff(
          contractArbitrum,
          "Dice_Outcome_Event",
          5,
          16000,
          eventArbitrumListener
        );
      }catch(e) {
        handleError(e);
      }
    };
    if (contractArbitrum) initArbitrum();

    // Fix Loop waiting vrf status.
    const providerFix = chainId
      ? new ethers.JsonRpcProvider(rpcProviderUrl[chainId][7])
      : null;
    const contractFix = diceAddress[chainId] && providerFix
      ? new ethers.Contract(diceAddress[chainId], diceAbi, providerFix)
      : null;
    const intervalId = contractFix
      ? setInterval(() => {
        // Fix metamask send two or more transaction in one time use clicked.
        if (round < histories.length) setRound(histories.length);
        if (isWaitingVrf) {
          contractFix.Dice_GetState(account).then((DiceGame) => {
            if (Number(DiceGame.wager) === 0) setWaitingVrf(false);
          });
        }
        }, 1000)
      : null;

    // get game status if on Waiting Vrf.
    if (contractFix) {
      contractFix.Dice_GetState(account).then((DiceGame) => {
        if (Number(DiceGame.wager) !== 0) setWaitingVrf(true);
      });
    }

    return () => {
      if (contractFix !== null) clearInterval(intervalId);
      if (contractSepolia !== null) {
        contractSepolia.off('Dice_Outcome_Event', eventSepoliaListener);
      }
      if (contractPolygon !== null) {
        contractPolygon.off('Dice_Outcome_Event', eventPolygonListener);
      }
      if (contractArbitrum !== null) {
        contractArbitrum.off('Dice_Outcome_Event', eventArbitrumListener);
      }
    };
  }, [chainId, account]);

  return (
    <div className="Layout_middle_container__rQzvK" style={{"--left_margin": closeStyle()}}>
      <main className="Layout_content__3KYZT">
        <div className="GameWrapper_game_container___djZh">
          <div className="Dice_container___ID90">
            <InputContainer
              multiplier={multiplier}
              sendTransaction={sendTransaction}
              allowance={allowance}
              approveErc20={approveErc20}
            />
            <DiceDisplay 
              multiplier={multiplier} 
              setMultiplier={setMultiplier} 
              isOver={isOver} 
              setIsOver={setIsOver} 
              histories={histories} 
              diceOutcome={diceOutcome}
            />
          </div>
          <DiceDescription/>
        </div>
        <GameFeed gameFeedData={gameFeedData}/>
        <MoreGame />
        <MobileView>
          <MobileMenu/>
          <BottomMenu/>
        </MobileView>
        <Footer/>
      </main>
    </div>
  );
}
