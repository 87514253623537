/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState, useEffect } from "react";
import { MobileView } from 'react-device-detect';
import { MobileMenu } from "../components/MobileMenu";
import { BottomMenu } from "../components/BottomMenu";

import { WalletContext } from "../hooks/WalletContext";
import { MoreGame } from "../components/MoreGame";
import {HomeFeed} from "../components/HomeFeed";
import { Benefits } from "../components/static/Benefits";
import { Footer } from "../components/static/Footer";

export function Home() {
  const { leftSideClose } = useContext(WalletContext);
  const closeStyle = () => {
    if (leftSideClose) return "50px";
    else return "";
  };
  return (
    <div
      className="Layout_middle_container__rQzvK"
      style={{ "--left_margin": closeStyle() }}
    >
      <main className="Layout_content__3KYZT">
        <MoreGame />
        <HomeFeed />
        <Benefits/>
        <MobileView>
          <MobileMenu/>
          <BottomMenu/>
        </MobileView>
        <Footer />
      </main>
    </div>
  );
}


/*

<MobileView>
<BottomMenu/>
</MobileView>


 */