/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState, useEffect } from "react";
import { formatUnits } from "ethers";

import { WalletContext } from "../hooks/WalletContext";
import { usdtIcon } from "./static/svg";

const FormatBalance = (_balance) => {
  return parseFloat(formatUnits(_balance, 6)).toLocaleString("en", {
    minimumFractionDigits: 2,
  });
};

export function MultiPlayerInputContainer({
  approveErc20,
  playTransaction,
  depositTransaction,
  withdrawTransaction,
  allowance,
  balance,
}) {
  const {
    account,
    chainId,
    erc20Balance,
    connectMetaMask,
  } = useContext(WalletContext);

  const decimals = 10 ** 6;
  const [wager, setWager] = useState(0);
  const [depositAmount, setDepositAmount] = useState(0);
  const [advanced, SetAdvanced] = useState(true);

  const handleWager = (event) => {
    setWager(event.target.value);
  };
  const maxWager = () => {
    return Math.floor(balance / decimals);
  };
  const handleDeposit = (event) => {
    setDepositAmount(event.target.value);
  };
  const maxDeposit = () => {
    return Math.floor(erc20Balance / decimals);
  };
  const blurWager = (event) => {
    if (event.target.value < 4) setWager(4);
    else setWager(event.target.value);
  };
  const blurDeposit = (event) => {
    if (event.target.value < 0) setDepositAmount(0);
    else if (event.target.value > erc20Balance)
      setDepositAmount(erc20Balance / decimals);
    else setDepositAmount(event.target.value);
  };

  const toogleAdvanced = () => {
    if (advanced) SetAdvanced(false);
    else SetAdvanced(true);
  };

  const classButton = () => {
    if (advanced) return "MultibetsInput_shown__WTZb1";
    else return "";
  };

  const napRutClass = () => {
    if (advanced) return "";
    else return " hidden";
  };

  const validChain = () => {
    if (chainId === 42161) return true;
    else if (chainId === 137) return true;
    else if (chainId === 421614) return true;
    else return false;
  };

  const playBtn = () => {
    if (!account)
      return (
        <button
          className="Inputs_connect_button__CHJzC Multiplayer_play_button"
          onClick={connectMetaMask}
        >
          Connect Wallet
        </button>
      );
    else if (!validChain())
      return (
        <button
          className="Inputs_play_button__fdpyi Multiplayer_play_button"
          disabled={true}
        >
          Change Network
        </button>
      );
    else
      return (
        <button
          className="Inputs_play_button__fdpyi Multiplayer_play_button"
          onClick={() => {
            // setWager(0);
            playTransaction(wager);
          }}
        >
          Play
        </button>
      );
  };
  const depositBtn = () => {
    if (allowance >= depositAmount) {
      return (
        <button
          className="Inputs_play_button__fdpyi Multiplayer_play_button"
          onClick={() => {
            if (depositAmount > 0)
            depositTransaction(depositAmount);
            else depositTransaction(allowance);

            setDepositAmount(0);
          }}
        >
          Nạp
        </button>
      );
    } else {
      return (
        <button
          className="Inputs_util_button__HL2Vc Multiplayer_play_button"
          onClick={() => {
            approveErc20(depositAmount);
          }}
        >
          Approve USDT
        </button>
      );
    }
  };

  return (
    <div className="Inputs_container__JmLHD">
      <div className="WagerInput_container__fnLjZ ">
        <div className="WagerInput_title__BpTQv">
          Số Dư: {FormatBalance(balance)} USDT
        </div>
        <div className="WagerInput_inputs__70u4x">
          <div className="WagerInput_inputs_top__w_nKi">
            <input
              className="WagerInput_input__WL9np"
              type="number"
              inputMode="decimal"
              placeholder="Chọn một mức cược ..."
              onChange={handleWager}
              onBlur={blurWager}
              value={wager || ""}
            />
            <div className="WagerInput_token_icon__QwSuK">{usdtIcon}</div>
          </div>
          <input
            className="WagerInput_slider__RhOmz"
            type="range"
            min="4"
            max={maxWager()}
            onChange={handleWager}
            value={wager}
            step="0.0001"
            style={{ "--percent": (wager * 100) / maxWager() + "%" }}
          />
          <div className="WagerInput_buttons__MkH8c">
            <button
              onClick={() => {
                if (wager >= 8) setWager(wager / 2);
                else setWager(4);
              }}
            >
              ½
            </button>
            <button
              onClick={() => {
                if (wager * 2 <= maxWager()) setWager(wager * 2);
                else setWager(maxWager());
              }}
            >
              2×
            </button>
            <button
              onClick={() => {
                setWager(maxWager());
              }}
            >
              Max
            </button>
          </div>
        </div>
        {playBtn()}
        <div className="MultibetsInput_advanced__q7dd5">
          <button
            className={"MultibetsInput_advanced_button__8aXqH " + classButton()}
            onClick={toogleAdvanced}
          >
            <span
              className={
                "MultibetsInput_advanced_button__8aXqH MultiPlayer_Nap_Rut" +
                classButton()
              }
            >
              Nạp rút
              <svg
                viewBox="6 6 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 10L12 15L17 10"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </span>
            <hr className="MultibetsInput_advanced_hr__oFc25" />
          </button>
          <div className={"WagerInput_inputs__70u4x " + napRutClass()}>
            <div className="WagerInput_inputs_top__w_nKi">
              <input
                className="WagerInput_input__WL9np"
                type="number"
                inputMode="decimal"
                placeholder="Nhập số tiền nạp ..."
                onChange={handleDeposit}
                onBlur={blurDeposit}
                value={depositAmount || ""}
              />
              <button
                className="WagerInput_buttons__MkH8c"
                style={{'backgroundColor': 'var(--button_bg)',
                  'color': '#fff'}}
                onClick={() => {
                  setDepositAmount(maxDeposit());
                }}
              >
                Max
              </button>
            </div>
            <input
              className="WagerInput_slider__RhOmz"
              type="range"
              min="1"
              max={maxDeposit()}
              onChange={handleDeposit}
              value={depositAmount}
              step="0.0001"
              style={{
                "--percent": (depositAmount * 100) / maxDeposit() + "%",
              }}
            />
            {depositBtn()}
            <button
              className="Inputs_connect_button__CHJzC Multiplayer_play_button"
              onClick={() => {
                withdrawTransaction();
              }}
            >
              Rút Toàn Bộ
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
