import { useState } from "react";
import { GameExternalLinks } from "./GameExternalLinks";
import { diceIcon } from "./svg";

export function BauCuaDescription() {
  const [isOpen, setIsOpen] = useState("");

  const toogleOpen = () => {
    if (isOpen === "") setIsOpen("GameDescription_open__Fwx_r");
    else setIsOpen("");
  };

  return (
    <div className="GameDescription_container__kgwkr">
      <div className="GameDescription_header__emfaR">
        <div className="GameDescription_game_info__AA1oL">
          <div className="GameDescription_game_title__4Rcft">
            <div className="GameDescription_game_icon__Lzy_J">
              {diceIcon}
            </div>
            <h1 className="GameDescription_game_name__5fLMz">Lắc Bầu Cua</h1>
            <div className="GameDescription_house_edge_header__Vjdkf">
              {/* Phí nhà cái: 1% */}
            </div>
          </div>
        </div>
        <div className="GameDescription_buttons__O_XMu">
          <button className={isOpen} onClick={toogleOpen}>
            <svg
              height="1em"
              viewBox="6 6 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 10L12 15L17 10"
                stroke="#fff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <div className={"GameDescription_content__piQVl " + isOpen}>
        <div className="GameDescription_description__6W8KV">Một trò chơi xúc xắc cờ bạc phổ biến ở Việt Nam.</div>
        <div className="GameDescription_tutorial__pU2cG">Chọn Bầu, Cua, Cá, Gà, Tôm, Nai và đặt cược của bạn. Nếu xúc xắc về 1 lần trùng kết quả bạn cược đặt 1 ăn 2, trùng 2 lần đặt 1 ăn 3, trùng 3 lần đặt 1 ăn 4</div>
        <GameExternalLinks />
      </div>
    </div>
  );
}

